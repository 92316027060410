<template lang="pug">
.activation
    template(v-if="loading")
        w-loading(:loading="loading")
    template(v-else)
        .login.wl-dialog(v-if="checkActivationData.activeFlag === 0")
            .wl-dialog-content
                span.wl-dialog-title {{ $t('userCenter.active.title') }}
            .wl-dialog-body.px-10
                w-form(ref="formRef" :formState="formState2" :footer="null")
            .px-20.mb-10.mt-5
                w-button(type="primary" @click="activationFn" @touch="activationFn").w-full {{ $t('common.activation') }}
        .login.wl-dialog(v-if="checkActivationData.activeFlag === 1")
            .wl-dialog-body_success
                img.success_img(src="@/assets/svg/49e12c8c-8777-4672-a20f-fc39598eb346.png")
                span {{ $t('userCenter.active.success') }}
            .wl-dialog-footer.w-full
                w-button(type="primary" @click="toLgin" @touch="toLgin") {{ $t('userCenter.login.go') }}
        .login.wl-dialog(v-if="checkActivationData.activeFlag === 2")
            .wl-dialog-body_success
                img.success_img(src="@/assets/svg/49e12c8c-8777-4672-a20f-fc39598eb346.png")
                span {{ $t('userCenter.active.fail') }}
</template>
<script lang="ts" setup>
import { isForeign, VITE_ENV, VITE_WEBSITE_URL } from "@/common/constants/env";
import { activation, checkActivation } from "@/service/activation"
import { clearLoginInfo, getLocale } from "@/utils/sso";
import { regPassword, regUserName } from "@/utils/validate";
import { useI18n } from 'vue-i18n'
import { getAllParamsString } from '@/utils'
const { t } = useI18n()

const route = useRoute()
const router = useRouter()
const { error, run } = activation()
const { data: checkActivationData, run: checkRun, loading } = checkActivation({ activationCode: route.params.id })
const formRef = ref()
const formState2 = ref<any>({
    username: { label: t('userCenter.accountManage.username'), value: '', hideLabel: true, rules: { required: t('common.not_empty'), regex: regUserName }, allowClear: true, placeholder: t('userCenter.resetPassword.userNamePlaceholder') },
    password: { label: t('userCenter.accountManage.password'), type: "password", hideLabel: true, value: '', rules: { required: t('common.not_empty'), regex: regPassword }, allowClear: true, placeholder: t('userCenter.resetPassword.passwordPlaceholder') },
    confirmPassword: { label: t('userCenter.basicInpormation.confirmPassword'), hideLabel: true, type: "password", value: '', rules: { required: t('common.not_empty'), regex: regPassword }, allowClear: true, placeholder: t('userCenter.active.confirm') },

})
const activationFn = (e: any) => {
    // e.stopPropagation();
    e.preventDefault();

    formRef.value.validate().then(async (valid: any) => {
        console.log(formRef.value.values, valid)
        if (valid.valid) {
            await run({ ...formRef.value.values, activeCode: checkActivationData.value.activeCode, email: checkActivationData.value.email })
            checkRun({ activationCode: route.params.id })
            // window.location.reload()
        }

    })
    // run()
}
const toLgin = () => {
    // router.push("/user_center/login")
    if (isForeign) {
        let currentUrl = window.location.href
        if(currentUrl.includes("wl_sso_token")) {
            const paramString = getAllParamsString("wl_sso_token")
            currentUrl = `${window.location.origin}${paramString ? ('?' + paramString) : ''}`
        }
        if (getLocale()) {
            if (getLocale() !== 'en') {
                window.location.href = `${VITE_WEBSITE_URL}/${getLocale()}/login.html?redirect_url=${encodeURIComponent(currentUrl)}`
                clearLoginInfo()
            } else {
                window.location.href = `${VITE_WEBSITE_URL}/login.html?redirect_url=${encodeURIComponent(currentUrl)}`
                clearLoginInfo()
            }

        } else {
            window.location.href = `${VITE_WEBSITE_URL}/login.html?redirect_url=${encodeURIComponent(currentUrl)}`
            clearLoginInfo()
        }
    }
}
</script>
<style lang="sass" scoped>
.activation
    overflow: hidden
    position: fixed
    width: 100%
    height: 100%
    left: 0
    top: 0
    background: url("../../assets/svg/bgc.png")
    // opacity: 0.1
    backdrop-filter: blur(50px)
    color: blue
    position: fixed
    width: 100%
    height: 100%
    background: url(/src/assets/svg/bgc.png)
    -webkit-backdrop-filter: blur(50px)
    backdrop-filter: blur(50px)
    background-size: 100% 100%
    color: blue
    z-index: 99

.login
    position: fixed
    width: 600px
    padding: 50px 20px 100px
    background: #fff
    box-shadow: 0px 6px 16px 0px rgba(88,88,88,0.3)
    border-radius: 16px
    z-index: 999
    color: blur
    left: 50%
    top:50%
    transform: translate3d(-50%,-50%,0)
    z-index: 9999
    .wl-dialog
        position: absolute
        left: 50%
        top:0
        transform: translateX(-50%)
        width: 492px
        height: 394px
    background: #FFFFFF
    border-radius: 4px
    // margin-top: 333px
    opacity: 1
    z-index: 9999 !important
    display: flex
    flex-direction: column
    animation: div_animate 0.5s
    .wl-dialog-content
        flex:1
        display: flex
        flex-direction: column
    .wl-dialog-body
        flex:1
        text-align: center
        :deep(.wl-form_label)
            min-width: auto !important
            padding-top: 2px !important
            padding-right: 4px !important
    .wl-dialog-body_success
        color:#666666

    .wl-dialog-title
        box-sizing: border-box
        display: block
        height: 28px
        font-size: 20px
        font-weight: 500
        color: #4779F6
        line-height: 28px
        margin-top: 36px
        margin-bottom: 20px
        // margin: 0 auto
.wl-dialog
    text-align: center
    .wl-dialog-footer
        text-align: center
        margin-bottom: 50px
        justify-content: center
        margin-top: 30px
.success_img
    margin:0 auto
    margin-bottom: 26px

@media screen and (max-width: 500px)
    body
        width:100%
        height: 100%
        overflow: hidden
        background-color: lightblue
    .wl-dialog
        width: 80%
        // background: #000


</style>