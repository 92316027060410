export function getElementPostotion(el: string) {
    return document.querySelector(el)?.getBoundingClientRect()
}

export function getShowName(str?: string | null | undefined, length = 2) {
    if (str) {
        const len = str.length
        return str.substring(len - length, len)
    }

}
export const formatData = (time: any) => {
    const date: any = new Date(time);
    const y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    let d = date.getDate();
    d = d < 10 ? "0" + d : d;
    let h = date.getHours();
    h = h < 10 ? "0" + h : h;
    let minute = date.getMinutes();
    minute = minute < 10 ? "0" + minute : minute;
    let s = date.getSeconds();
    s = s < 10 ? "0" + s : s;
    return y + "-" + m + "-" + d;
}

export function validateEmail(s: any) {
    const patrn = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return Boolean(patrn.exec(s))
}

export const  doHandMonth = (month: any) => {
    let m = month;
    if (month.toString().length === 1) {
        m = "0" + month;
    }
    return m;
}

export const  getDataFn = (newTime: any, day: any) => {
    const today = new Date(newTime);
    const targetday = today.getTime() + 1000 * 60 * 60 * 24 * day;
    today.setTime(targetday);
    const tYear = today.getFullYear();
    let tMonth = today.getMonth();
    let tDate = today.getDate();
    tMonth = doHandMonth(tMonth + 1);
    tDate = doHandMonth(tDate);
    return tYear + "-" + tMonth + "-" + tDate;
}
export const getAllParamsString = (withoutKey = '') => {
    let params = '';
    const seenParams = new Set()
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    for (const [key, value] of urlParams.entries()) {
        if ((!withoutKey || key !== withoutKey) && !seenParams.has(key)) {
            seenParams.add(key)
            params += `${key}=${value}&`
        }
    }
    return params.length > 0 ? params.slice(0,params.length - 1) : ''
}