<template lang="pug">
nav.page-header(class="fixed z-30 w-full h-16")
    div(class="px-8 py-4 h-full")
        div(class="flex items-center justify-between h-full")
            template(v-if="isForeign")
                a.logo(class="flex w-[117px]" href="/")
                    img(src="@/assets/images/common/header-logo-foreign.png")
            template(v-else)
                a.logo(class="flex ml-4 md:mr-24 w-[120px]" href="/")
                    svg-icon(name="logo", width="120" height="30")
            .opts.flex.items-center(class="md:order-2")
                //- span.login-hide.menu(@click="openWorkOrderUrl('home')")
                //-     a-tooltip(:title="$t('pageHeader.console')")
                //-         svg-icon(name="console" size="16")
                //- span.login-hide.menu(@click="openUrl('cost')")
                //-     a-tooltip(:title="$t('pageHeader.costMenu')")
                //-         svg-icon(name="cost")
                a-dropdown(:trigger="['click']" placement="bottom" arrow :overlayStyle="{zIndex: 10000}")
                    a-tooltip(:title="$t('title')" placement="bottom" overlayClassName="header-tooltip")
                        span.menu(v-if="isForeign")
                            svg-icon(name="earth" size="16" class="rounded-full ")
                    template(#overlay)
                        ul.p-2.font-medium.w-34.bg-white.rounded-lg.shadow
                            li(
                                v-for="l in localMenu", :key="l.url"
                                :class="{ 'text-[#4E5969] rounded-lg px-8 h-10 flex justify-center items-center hover:bg-[#F7F8FA] cursor-pointer mb-2 last:mb-0': true, 'text-main-color bg-[#F6F9FF]': getLocal() === l.key }"
                                @click="setLocaleMenu(l.key)"
                            ).block.px-4.py-1.text-base
                                | {{ l.label }}
                a-tooltip(:title="$t('workOrder.title')" placement="bottom" overlayClassName="header-tooltip")
                    span.login-hide.menu(@click="openWorkOrderUrl('myWorkOrderList')")
                        svg-icon(name="work-order-icon" size="16")
                span.login-hide.ml-2
                    a-dropdown(placement="bottomRight" :arrow="false")
                        span(class="bg-white w-8 h-8 rounded-full flex items-center justify-center color-[#1339C1] cursor-pointer uppercase text-sm shadow") {{ userName.slice(0,1) }}
                        template(#overlay)
                            ul(class="z-10 p-2 bg-white shadow rounded-lg w-42 text-[#4E5969] text-sm")
                                li(
                                    class="px-4 py-2 dark:text-white account-info last:mb-0 cursor-pointer"
                                    hover="bg-[#F7F8FA] rounded-lg"
                                )
                                    div.text-sm.mb-1.truncate
                                        | {{ userName }}
                                    div(class="font-medium")
                                        span.bg-blue-600.pri-color.rounded-lg.text-xs.text-white.px-2.py-1
                                            span {{ userInfo.userType === 0 ? t('userInfo.masterAccount') : t('userInfo.subAccount') }}
                                        //- | {{ userInfo.id }}
                                        //- span.copy(class="ml-1 font-medium text-xs text-block inline-block")
                                        //-     svg-icon.copy-icon(
                                        //-         name="copy", class="-mt-1" width="12" height="12" @click="copyFn")
                                li(class="px-4 h-10 cursor-pointer leading-10" hover="bg-[#F7F8FA] rounded-lg" @click="toUserCenter") {{ $t('v2.pageHeader.user')}}
                                li(class="px-4 h-10 cursor-pointer leading-10" hover="bg-[#F7F8FA] rounded-lg" @click="openUrl('cost')") {{ $t('v2.pageHeader.bill')}}
                                li(class="px-4 h-10 cursor-pointer leading-10" hover="bg-[#F7F8FA] rounded-lg" @click="logout" )
                                    svg-icon(name="logout" :size="17" class="-mt-0.5 mr-1")
                                    | {{ $t('v2.pageHeader.logout')}}
</template>

<script lang="ts" setup>
import API from '@/common/api'
import { i18n } from '@/i18n'
import { setLocale, getUserInfo, clearLoginInfo, getLocale } from '@/utils/sso'
import { copyText } from '@/utils/copyText'
import { isForeign } from '@/common/constants/env'
import { useI18n } from 'vue-i18n'
import { VITE_ENV, getLocal, VITE_WEBSITE_URL } from "@/common/constants/env"
import { getAllParamsString } from '@/utils'

const { t } = useI18n()
const userInfo = ref<any>(getUserInfo())
const userName = getUserInfo('username') || ''
const localMenu = [
    { label: 'English', url: '/', key: 'en' },
    { label: '日本語', url: '/', key: 'ja', },
    // { label: '한국어', url: '/', key: 'ko', },
    // { label: '中文', url: '/', key: 'zh' },
]


const route = useRoute()
const router = useRouter()
const toUserCenter = () => {
    const data = router.resolve({ name: 'userInfo' })
    window.open(data.href)
}
const toLogin = () => {
    router.push({ name: 'userCenterLogin' })
}

const setLocaleMenu = (key: string) => {
    setLocale(key)
    const { origin, pathname, hash } = location
    window.location.href = `${origin}${pathname}${hash}`
    setTimeout(() => {
        window.location.reload()
    }, 100)
}
const logout = async () => {
    await API.userCenter.logout()

    if (!isForeign) {
        toLogin()
    } else {
        let currentUrl = window.location.href
        if(currentUrl.includes("wl_sso_token")) {
            const paramString = getAllParamsString("wl_sso_token")
            currentUrl = `${window.location.origin}${paramString ? ('?' + paramString) : ''}`
        }
        if (getLocale()) {
            if (getLocale() !== 'en') {
                window.location.href = `${VITE_WEBSITE_URL}/${getLocale()}/login.html?redirect_url=${encodeURIComponent(currentUrl)}`
                clearLoginInfo()
            } else {
                window.location.href = `${VITE_WEBSITE_URL}/login.html?redirect_url=${encodeURIComponent(currentUrl)}`
                clearLoginInfo()
            }

        } else {
            window.location.href = `${VITE_WEBSITE_URL}/login.html?redirect_url=${encodeURIComponent(currentUrl)}`
        }
    }

}
const openUrl = (name: any) => {
    const data = router.resolve({ name: name })
    window.open(data.href)
}
const openWorkOrderUrl = (name: any) => {
    if (name === 'home') {
        router.push({ name: name })
    } else {
        const data = router.resolve({ name: name })
        window.open(data.href)
    }

}
const copyFn = () => {
    copyText(`${t('pageHeader.userName')}:${userInfo.value.username},${t('pageHeader.id')}:${userInfo.value.id}`)
}

watch(route, (newVal) => {
    if (newVal.name === 'home') {
        userInfo.value = getUserInfo()
    }

})
</script>

<style lang="sass" scoped>
.users
    .userinfo
        display: flex
        flex-direction: column
    .username
        width: 80px
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
    .copy
        display: flex
        justify-content: space-between
.opts
    .menu
        @apply mr-3 inline-flex items-center font-medium justify-center w-9 h-9 cursor-pointer rounded-lg hover:bg-[#E5E6EB]
.page-header
    transition: .2s all
    &.active-bg
        background-color: #fff
        box-shadow: 0 0 5px rgba(0,0,0,.1)
</style>

<style lang="sass">
.ant-dropdown
    .ant-dropdown-arrow::before
        clip-path: polygon(2.485281374238571px 100%, 50% 2.485281374238571px, 13.514718625761429px 100%, 2.485281374238571px 100%) !important
    .ant-dropdown-arrow::after
        border-radius: 0 !important
.header-tooltip
    .ant-tooltip-arrow
        &::before
            clip-path: polygon(1.6568542494923806px 100%, 50% 1.6568542494923806px, 14.34314575050762px 100%, 1.6568542494923806px 100%) !important
</style>