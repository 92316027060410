<template lang="pug">
    div.search-wrap.mb-6
        a-row
            a-col(:span="8")
                p Select Week
                a-date-picker( style="width:90%" @change="checkWeekFn" placeholder="Select the start date" :disabled-date="disabledDate" v-model:value="timeStart")
            a-col(:span="8")
                p Select Time Zone
                a-select(v-model:value="timeZoneDetail" mode="combobox"  :maxTagCount="1" style="width: 90%" placeholder="Tags Mode" :options="timeZone" @change="handleChange")
            a-col(:span="8")
                p Repeat Week
                a-input-number.text-center( style="width:50%"  @change="checkWeekFn2" v-model:value="repeatWeek" :controls="false" :min="0" )
                    template(#addonBefore)
                        span.action(@click="repeatWeek > 0 ? repeatWeek-- : 0") -
                    template(#addonAfter)
                        span.action(@click="repeatWeek++") +
    a-table(
        :columns="columns" :data-source="tableDate"
        :pagination="false"
        v-if="timeStart"
    ).rounded-lg
        //- &&props.pageModel
        template(#headerCell="{ column }")
                span {{column.title}}
        template(#emptyText) 
            div.text-center.pt-16
                img.w-36.h-36.empty-img.mb-6(src="@/assets/images/new/reserved.png")
                .font-bold.txt-color Select week to start your weekly plan
        template(#bodyCell="{ column, text, record, index }")
            template(v-if="column.key === 'startDate'")
                div.flex
                    a-select(:options="startTimeList" @change="startDateChange(index)" v-model:value="record.startDate")
                    div.select-div-hh -
                    a-select(:options="endTimeList" @change="endDateChange(index)" v-model:value="record.endDate")
                    div.select-div-hh.ml-2(v-if="index>0" @click="copyDataTimeFn(index)")
                      a-tooltip.icon-sty(title="Copy previous" :color="'#ffffff'" :overlayInnerStyle="{color:'#124EF4'}")
                        svg-icon(name="copy-img" size="18")
            template(v-if="column.key === 'ccu'")
                    a-input-number.text-center( v-model:value="record.ccu" placeholder="Please input CCU number"  :max="10000" :controls="false" :min="0" )
    div.flex.mt-10.justify-center
        a-button(class="!font-bold" :disabled="setBtnDisStr" @click="setSkipFn") Skip
        a-button.v2-btn.ml-6(type="primary" v-if="timeStart" @click="subNext" ) Next
        a-button.ml-6(type="primary" v-else disabled) Next
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { getToken, getUserId, getUserUid } from '@/utils/sso';
import { isForeign } from "@/common/constants/env"
import API from '@/common/api/console/v2/home'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { bytesToSize } from '@/utils/bytesConvert'
import { resourceStatusMap } from '@/views/console/v2/constants/index'
import { Toast } from '@welink/design';
import dayjs, { Dayjs } from 'dayjs';
import { formatData, doHandMonth, getDataFn } from "@/utils/index"
import { useGlobalStore } from '@/store/global'
// import { datasetModule } from 'snabbdom';
import { defineStore } from 'pinia';
const emit = defineEmits(['addOnlinetabelFn','sebmitAddFn', 'skipFnSet'])
// @addOnlinetabelFn="addOnlinetabelFn" @sebmitAddFn="sebmitAddFn" @skipFn="skipFn"

const  setSkipFn= () => {
    emit('skipFnSet')
}
const props = defineProps({
    btnFa:{
        type: Boolean,
        default: ''
    },
    gameInfo: {
        type: Object,
        default: ''
    },
    nodeId: {
        type: String,
        default: ''
    },
    resourceDetailId: {
        type: String,
        default: ''
    },
    pageModel:{
        type: String,
        default: ''
    }
})

const btnDisabled = ref(false)
const tableVal = (i)=>{
    // startDate
    // endDate
  if(tableDate.value[i].startDate >= tableDate.value[i].endDate){
    tableDate.value[i].endDate = 24
  }
}
const startDateChange = (i) => {
    tableVal(i)
}
const endDateChange = (i) => {
    tableVal(i)
}
const dateFormat = 'YYYY-MM-DD'
const timeFn = (e) => {
    return dayjs(e, dateFormat)
}
// alert(formatData(new Date()))
const timeStart = ref<any>(null)
// timeFn(formatData(new Date()))
const subStart = ref<any>({
    time: formatData(new Date())
})

const subEnd = ref<any>({
    time: getDataFn(subStart.time, 7)
})
const startDate = ref<any>(null)
const endDate = ref<any>(null)
const repeatWeek = ref<any>(0)
const copyDataTimeFn = (i) => {
  tableDate.value[i].startDate = tableDate.value[i-1].startDate
  tableDate.value[i].endDate = tableDate.value[i-1].endDate
}
const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
const getDayOfWeek = (date: any) => {
  return weekDays[date.getDay()];
};
const dataLsit = reactive<any>([]);
const newTimeFn = (time: any) => {
  //   console.log(time);
  let obj = {};
  weekDays.map((item, index) => {
    obj = {
      week: "",
      date: "",
      ccu: ""
    };
  });
};

// console.log("props:", props)
// console.log("gameInfo1::::", props.gameInfo)
// console.log("gameInfo2::::", props.gameInfo.applicationId)
const tableDate = ref<any>([])
const checkResource = ref<Number>(0)
const store = useGlobalStore()
const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const loading = ref(true)
const paginationParams = ref({
    current: 1,
    size: 10,
    total: 0
})
const dataList = (e) => {
    console.log(e)
    let listDate2 = [];
    let nextDay = null;
    let obj = {
        date: e,
        week: getDayOfWeek(new Date(e)),
        ccu: 0,
        startDate: 0,
        endDate: 24
    };

    listDate2.push(obj);

    [...Array(6)].map((item, index) => {
        nextDay = getDataFn(startDate.value, index + 1)
        obj = {
            date: nextDay,
            week: getDayOfWeek(new Date(nextDay)),
            ccu: 0,
            startDate:0,
            endDate:24
        }
        listDate2.push(obj)
    })
    tableDate.value = listDate2
}
const checkWeekFn = (e) => {
    if(!e) return
    startDate.value = formatData(e)
    const cha = repeatWeek.value + 1
    const endTime = cha * 7 - 1
    endDate.value = getDataFn(startDate.value, endTime)
    console.log(endDate.value)
    dataList(startDate.value)
}
const checkWeekFn2 = (e) => {
    const cha = repeatWeek.value + 1
    const endTime = cha * 7 - 1
    endDate.value = getDataFn(startDate.value, endTime)
    console.log(endDate.value)
}
const resetFn = () => {
    timeStart.value = ''
    tableDate.value = ''
}
const gameInfoList = ref<any>({
    resourceList: []
})

const onDemandListFn = async () => {
    console.log(subStart.value.time)
    console.log(props.resourceDetailId)
    const param = {
        packageId: route.params.gameId,
        nodeId: props.nodeId,
        resourceDetailId: props.resourceDetailId || '', 
        applicationId: props.applicationId,
        userId: getUserUid() || '',
        startDate: subStart.time || formatData(new Date()),
        endDate: subEnd.time || getDataFn(formatData(new Date()), 6)
    }
    const dateList:any = await API.getQueryOnDemandPlan(param)
    console.log(dateList)
}

const getGameDetailListFn = () => {
    loading.value = true
    API.getGameDetailList({packageId:route.params.gameId , id: getUserId(), environmentId: store.envId , ...paginationParams.value }).then((data: any) => {
    }).catch(() => {
        loading.value = false
    })
}
// getGameDetailListFn()
const changeParams = (params: any) => {
    paginationParams.value.current = params
    getGameDetailListFn()
}
const sizeChange = (size: any) => {
    paginationParams.value.current = 1
    paginationParams.value.size = size
    getGameDetailListFn()
}
// "date": "Date",
    // "weekday": "WeekDay",
    // "runningHours": "Running Hours"
const columns = reactive<any[]>([
    { title: t('onlineObj.weekday'), dataIndex: 'week', key: 'week' },
    { title: t('onlineObj.date'), dataIndex: 'date', key: 'date' },
    { title: t('onlineObj.runningHours'), prop: 'startDate', dataIndex: 'startDate', key: 'startDate' },
    { title: t('onlineObj.ccu'), prop: 'ccu', key: 'ccu', dataIndex: 'startDate'}
])

const disabledDate = (current: Dayjs) => {
    // getDataFn(subStart.time, 7)
  return current && current < dayjs(getDataFn(new Date, 6)).endOf('day');
};
const timeZoneDetail = ref<any>("UTC+0");
const timeZoneD = [...Array(12)].map((_, i) => ({
  value: `UTC+${i + 1}`,
  label: `UTC+${i + 1}`
}));
const timeZoneX = [...Array(12)].map((_, i) => ({
  value: `UTC-${i + 1}`,
  label: `UTC-${i + 1}`
}));
const timeUtc = [{
  value: `UTC+0`,
  label: `UTC+0`
}] 
const timeZone = ref<any>([...timeUtc, ...timeZoneD, ...timeZoneX]);
const timeListArr = [...Array(23)].map((_, i) => ({
  value: i + 1,
   label: i >= 9 ? String(i + 1) + ":00" : "0" + (i + 1) + ":00"
}));
const startTimeList = [
  {
    value: 0,
    label: "00:00"
  },
  ...timeListArr
];
const endTimeList = [
  ...timeListArr,
  {
    value: 24,
    label: "24:00"
  }
];
const submitFn = () => {
    emit('addOnlinetabelFn', tableDate.value, timeZoneDetail.value, repeatWeek.value)
}
const subNext = () => {
    // modelStep.value = "demand"
    emit('skipFn',2, "demand")
    emit('addOnlinetabelFn', tableDate.value,timeZoneDetail.value, repeatWeek.value)
}
const setBtnDisStr = ref(false)
const setBtnDisFn = (e) => {
    setBtnDisStr.value = e
    console.log(setBtnDisStr.value)
}
defineExpose({
  onDemandListFn,
  setBtnDisFn
})
</script>

<style lang="sass" scoped>
.cover
    border: 1px solid #E5E6EB
    width: 270px
    height: 164px
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.74) 0%, rgba(255, 255, 255, 0.74) 100%), linear-gradient(285deg, #124EF4 -55.98%, #FEDFFF 105.41%)
    &:hover
        img
            width: 100px
            height: 100px
.info-flex-2
        flex: 2
.empty-img
    display: inline-block
.col-txt
    color: transparent
.select-div-hh
    line-height: 40px
    padding: 0 6px
:deep(.ant-input-number .ant-input-number-input)
    text-align: center
.icon-sty
    cursor: pointer
    fill:#124EF4
    &:hover svg 
        fill:#124EF4
    &:path
        fill:#124EF4
    &:hover rect
        fill:#124EF4
rect
    fill:#124EF4
svg 
    fill:#124EF4
</style>