<template lang="pug">
    a-table(
        :columns="columns" :data-source="gameInfoList.resourceList" :pagination="paginationParams"
        :scroll="{ x: 900 }"
        @pagination-num-change="changeParams"
        @pagination-size-change="sizeChange"
    ).rounded-lg
        template(#headerCell="{ column }")
                span {{column.title}} 
                span(v-if="column.txt" style="color:#ccc") {{`(${column.txt})`}}
        template(#emptyText) 
            div.text-center.pt-16
                img.mb-6(class="w-45" style="margin:0 auto" src="@/assets/images/new/reserved.png")
                .txt-third-color You have 0 reserved plan
                //- {{ $t('v2.action.emptyData2') }}
                .font-bold.txt-color Start your first plan
                //- {{ $t('v2.action.startFirst') }}
                a-button.mt-6.mb-16(class="!font-bold" @click="openResoure") {{ $t('v2.gameList.add') }}
        template(#bodyCell="{ column, text, record }")
            //- planType
            template(v-if="column.key === 'planType'")
                span {{getPlanTypeFn(record)}}
            template(v-if="column.key === 'status'")
                a-tag(:color="resourceStatusMap[text].color") {{ resourceStatusMap[text].text }} 
            template(v-if="column.key === 'createTime'")
                span(v-if="text") {{ dayjs(text).format('YYYY-MM-DD HH:mm:ss') }}
    a-modal(v-model:open="addResoureVisible" :footer="null" width="60%" centered).text-center.pb-10
        h3.model-titlt.pt-5.text-center Add your reserved plan
        p.model-txt.text-center.pt-2.pb-4 Selecting your plan starting date and its cycle mode.
        a-row.pl-6
            a-col(:span="10" :class="{ 'error': formState.startDate }")
                p Start Date
                a-date-picker(v-model:value.trim="form.startDate" :disabled-date="disabledDate"  style="width:100%" placeholder="Select the start date")
                .msg-error(v-if="formState.startDate") {{ $t('userCenter.login.foreign.username.error') }}
            a-col.ml-6(:span="10" :class="{ 'error': formState.quantity }")
                p Cycle Mode
                a-input-group(compact style="width:100%")
                    a-select(v-model:value="form.planType" style="width:36%")
                        a-select-option(value="1") Monthly
                        a-select-option(value="0") Yearly
                    a-input-number(v-model:value.trim="form.cycleValue" :min="1" :max="12" placeholder="1-12" style="width:64%")
                .msg-error(v-if="formState.quantity") {{ $t('userCenter.login.foreign.username.error') }}
        a-row.mt-6.pl-6
            a-col(:span="10" :class="{ 'error': formState.cycleValue }")
                p CCU
                a-input-number.text-center( style="width:100%" v-model:value.trim="form.quantity" placeholder="Please input CCU number"  :max="10000" :controls="false" :min="1" )
                .msg-error(v-if="formState.cycleValue") {{ $t('userCenter.login.foreign.username.error') }}
        div.flex.mt-10.justify-center
            a-button(class="!font-bold" @click="cancelFn()") Cancel
            a-button.v2-btn.ml-6(type="primary" @click="sebmitAdd()" ) Done
        p.pt-1
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { getLocale, getToken, getUserId, getUserUid } from '@/utils/sso';
import { isForeign } from "@/common/constants/env"
import API from '@/common/api/console/v2/home'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { bytesToSize } from '@/utils/bytesConvert'
import { resourceStatusMap } from '@/views/console/v2/constants/index'
import { Toast } from '@welink/design';
import { formatData, doHandMonth, getDataFn } from "@/utils/index"
import dayjs from 'dayjs'
import { useGlobalStore } from '@/store/global'
// import { datasetModule } from 'snabbdom';
import { regPassword } from '../../../../../utils/validate';
import transformerDirectives from '@unocss/transformer-directives';
const emit = defineEmits(['addResourceFn','openAddFn'])

const props = defineProps({
    gameInfo: {
        type: Object,
        default: ''
    },
    nodeId: {
        type: String,
        default: ''
    },
    resourceDetailId: {
        type: String,
        default: ''
    },
})
const disabledDate = (current: Dayjs) => {
  return current && current < dayjs(getDataFn(new Date, 6)).endOf('day');
};
const planTypeArr = ref(['Yearly','Monthly','Demand'])
const getPlanTypeFn = (res) => {
    return planTypeArr.value[res.planType]
}
const addResoureVisible = ref(false)
const form = reactive({
    startDate: '',
    planType: '1',
    quantity:'',
    cycleValue:''
})
const openResoure = () => {
    emit('openAddFn')
}
const openResoureFn = () => {
    // emit('openAddFn')
    form.startDate = ''
    form.planType = '1'
    form.quantity =''
    form.cycleValue= ''
    addResoureVisible.value = true
}
const formState = ref({
    startDate: false,
    quantity:false,
    cycleValue:false
})
const cancelFn = () => {
    addResoureVisible.value = false
    formState.value.startDate = ''
    formState.value.quantity = ''
    formState.value.cycleValue = ''
}
const checkResource = ref<Number>(0)
const store = useGlobalStore()
const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const loading = ref(true)
const paginationParams = ref({
    current: 1,
    size: 10,
    total: 0
})

const planType = ref<any>("1")

const gameInfoList = ref<any>({
    resourceList: []
})

const onDemandListFn = async (res: any) => {
    const param = {
        packageId: res.gameId,
        nodeId: res.nodeId,
        resourceDetailId: res.resourceDetailId || '', 
        applicationId: res.applicationId,
        userId: getUserUid() || '',
        current: 1,
        size: 30
    }
    if(!param.resourceDetailId) return 
    try {
        const dateList: any = await API.getQueryReservedPlan(param)
        gameInfoList.value.resourceList = dateList.records
    } catch (error) {
        gameInfoList.value.resourceList = [];
    }
    
   
}

const getGameDetailListFn = () => {
    loading.value = true
    API.getGameDetailList({packageId:route.params.gameId , id: getUserId(), environmentId: store.envId , ...paginationParams.value }).then((data: any) => {
        loading.value = false
    }).catch(() => {
        loading.value = false
    })
}
// getGameDetailListFn()
const changeParams = (params: any) => {
    paginationParams.value.current = params
    getGameDetailListFn()
}
const sizeChange = (size: any) => {
    paginationParams.value.current = 1
    paginationParams.value.size = size
    getGameDetailListFn()
}
const sebmitAdd = () => {
    console.log(form)
    formState.value.startDate = Boolean(!form.startDate)
    formState.value.quantity = Boolean(!form.quantity)
    formState.value.cycleValue = Boolean(!form.cycleValue)
    if(formState.value.startDate || formState.value.quantity || formState.value.cycleValue){
        return
    }

    emit('addResourceFn', form)
}

const columns = reactive<any[]>([
    { title: t('onlineObj.startDate'), dataIndex: 'startDate', key: 'startDate', txt: t('onlineObj.plan') },
    {
        title: t('onlineObj.startDate'),
        dataIndex: 'realStartDate', key: 'realStartDate',
        txt: t('onlineObj.real')
    },
    { title: t('onlineObj.endDate'), dataIndex: 'endDate', key: 'endDate', txt: t('onlineObj.plan')},
    { title: t('onlineObj.endDate'), dataIndex: 'realEndDate', key: 'realEndDate', txt: t('onlineObj.real') },
    { title: t('onlineObj.cycle'), dataIndex: 'planType', key: 'planType' },
    // cycleQuantity
    { title: t('onlineObj.cycleQuantity'), dataIndex: 'cycleValue', key: 'cycleValue' },
    {
        title: t('onlineObj.ccu'),
        prop: 'extensionAmount',
        dataIndex: 'quantity',
        key: 'quantity',
        txt: t('onlineObj.plan')
    },
    { title: t('onlineObj.ccu'), dataIndex: 'realQuantity', key: 'realQuantity',txt: t('onlineObj.real') },
])
defineExpose({
  onDemandListFn,
  cancelFn,
  openResoureFn
});
</script>

<style lang="sass" scoped>
.cover
    border: 1px solid #E5E6EB
    width: 270px
    height: 164px
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.74) 0%, rgba(255, 255, 255, 0.74) 100%), linear-gradient(285deg, #124EF4 -55.98%, #FEDFFF 105.41%)
    &:hover
        img
            width: 100px
            height: 100px
.info-flex-2
        flex: 2
.model-titlt
    font-family: Roboto
    font-size: 24px
    font-weight: 500
    line-height: 33.6px
    text-align: center
    color: #1D2129
.model-txt
    font-family: Roboto
    font-size: 14px
    font-weight: 400
    line-height: 16.8px
    text-align: center
    color: #86909C
.model-img
    display: block
    margin: 0 auto
    width:265px
    height:221px
.model-btn
    display: block
    margin: 0 auto

.msg-error
    display: block
    margin-top: 6px
    color: rgb(255,0,0)
</style>