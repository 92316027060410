<template lang="pug">
div(v-if="item && !isDeleteItem" class="game-item relative")
    a-dropdown(placement="bottomLeft")
        svg-icon.opt.absolute(name="points" :size="20")
        template(#overlay)
            a-menu
                a-menu-item(@click="editHandleOpen") {{ $t('v2.action.edit') }}
                a-menu-item(@click="deleteHandleOpen") {{ $t('v2.gameList.delete') }}
    div(@click="goDetail")
        .name.txt-color.font-medium.text-sm.truncate.mb-2.truncate {{ item.packageName }}
        .folder.flex.items-center.justify-center
            img.cover.w-14.h-14(src="@/assets/images/console/game-cover.png")
        .info.flex.items-center.justify-between.my-2
            .nums.flex.items-center.leading-4
                span {{ item.resourceCount || 0 }} {{ $t('v2.action.sources') }}
                span.point.mx-1
                span {{ item.packageSize ? bytesToSize(item.packageSize) : '0 MB' }}
            .date(v-if="item.createTime") {{ dayjs(item.createTime).format('YYYY-MM-DD') }}
    span.pb-2.block.project-name.txt-color.text-xs.truncate(v-if="item.packageName") {{ item.packageName }}
    a-modal(
        v-model:open="editVisible" :width="432" :title="$t('v2.gameList.inputTitle')" wrapClassName="popup-edit-project" centered
    )
        a-input(v-model:value="currentItem.packageName" size="large" :placeholder="$t('v2.gameList.inputPlaceholder')")
        template(#footer)
            a-button(@click="editVisible = false") {{ $t('v2.action.cancel') }}
            a-button.v2-btn(
                type="primary" :loading="editLoading" :disabled="!currentItem.packageName.trim()"
                @click="editHandleOk"
            ) {{ $t('v2.action.confirm') }}
    a-modal(
        v-model:open="deleteVisible" :width="432" :closable="false" wrapClassName="popup-delete-project" centered
    )
        .flex.items-center.justify-center
            svg-icon(name="popup-trash" :size="80").block.mb-2
        div.text-lg.txt-color.text-center.mb-8 {{ $t('v2.projectList.deleteTitle') }}
        p.p-0.mb-8(class="text-[#86909C] text-sm px-10")
            | {{ $t('v2.projectList.deleteDesc') }}
            strong.txt-color {{ $t('v2.projectList.deleteDescPrefix') }}
        template(#footer)
            a-button.v2-btn(type="primary" @click="deleteVisible = false") {{ $t('v2.action.cancel') }}
            a-button( danger :loading="deleteLoading" @click="deleteHandleOk") {{ $t('v2.action.delete') }}
</template>

<script lang="ts" setup>
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import API from '@/common/api/console/v2/home'
import { Toast } from '@welink/design';
import { bytesToSize } from '@/utils/bytesConvert'
import { useI18n } from 'vue-i18n';

const props = defineProps<{
    item?: any,
    fromProject?: boolean
}>()

const emits = defineEmits(["onDelete"])

const { t } = useI18n()

dayjs.extend(LocalizedFormat)

const route = useRoute()
const router = useRouter()
const isDeleteItem = ref(false)
const editVisible = ref(false)
const editLoading = ref(false)
const currentItem = ref({
    id: '',
    packageName: ''
})

const goDetail = () => {
    const { packageName, packageId, packageVersion } = props.item
    console.log(props.item)
    router.push({
        name: 'projectGameDetail',
        params: { gameId: packageId },
    })
}
const editHandleOpen = () => {
    editVisible.value = true
    currentItem.value = { ...props.item }
}
const editHandleOk = () => {
    if (currentItem.value.packageName === props.item.packageName) {
        return
    }
    editLoading.value = true
    API.updateGameName(currentItem.value.id, currentItem.value.packageName).then(() => {
        editLoading.value = false
        editVisible.value = false
        Toast.success(t('v2.action.updateSuccess'))
        // eslint-disable-next-line vue/no-mutating-props
        props.item.packageName = currentItem.value.packageName
    }).catch(() => {
        editLoading.value = false
    })
}

const deleteVisible = ref(false)
const deleteLoading = ref(false)
const deleteHandleOpen = () => {
    deleteVisible.value = true
    currentItem.value = { ...props.item }
}
const deleteHandleOk = () => {
    deleteLoading.value = true
    API.deleteGame(currentItem.value.id).then(() => {
        editLoading.value = false
        deleteLoading.value = false
        Toast.success(t('v2.action.deleteSuccess'))
        isDeleteItem.value = true
        emits('onDelete')
    }).catch(() => {
        deleteLoading.value = false
        deleteVisible.value = false
        editLoading.value = false
    })
}

</script>
<style lang="sass" scoped>
.game-item
    width: 256px
    border: 1px solid #e1e2e3
    padding: 16px 16px 0
    background-color: #ffffff
    cursor: pointer
    @apply relative rounded-lg
    transition: .2s ease-in
    overflow: hidden
    &:hover
        box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.10)
        .info .date
            opacity: 1
    .opt
        top: 14px
        right: 8px
        border-radius: 50%
        cursor: pointer
        &:hover
            background-color: #F2F3F5
    .folder
        width: 222px
        height: 111px
        border-radius: 10px
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.74) 0%, rgba(255, 255, 255, 0.74) 100%), linear-gradient(285deg, #2053F3 -55.98%, #FEDFFF 105.41%)
    .info
        color: #86909C
        font-weight: 400
        line-height: 16px
        font-size: 12px
        .point
            width: 2px
            height: 2px
            display: inline-block
            border-radius: 1px
            background-color: currentColor
        .date
            color: #C9CDD4
            transition: .2s ease-in
    .project-name
        background: #fff url('@/assets/images/console/folder.png') left center no-repeat
        background-size: 16px
        background-position: 16px center
        margin: 0 -18px -8px
        padding: 12px 12px 12px 40px
        &:hover
            color: #124EF4
            text-decoration: underline

</style>