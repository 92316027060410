import { isForeign } from '@/common/constants/env'

const getCountPageList = '/user/subAccount/pageList'   // user/subAccount/pageList
const addCount = isForeign ? 'user/subAccount/abroadAddSubAccount' : 'user/subAccount/addSubAccount'
const resetPassword = isForeign ? 'user/subAccount/abroadPasswordReset' : 'user/subAccount/passwordReset'
const open_prohibitCount = 'user/subAccount/userStatusModify'
const copyPassword = '/user/subAccount/temporaryPasswordCopy'
const sendAccoutEmail = '/user/subAccount/abroadSendAccountEmail'

export default {
    getCountPageList,
    addCount,
    open_prohibitCount,
    resetPassword,
    copyPassword,
    sendAccoutEmail
}