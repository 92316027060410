<template lang="pug">
.console-main-process
    step(:current="1")
    .inner.flex.justify-center.py-12.px-4.pt-16
        step-back
        .flex.w-170
            span.order 2.
            .select-game.flex-1
                .mb-6
                    .label.txt-color
                        | {{ $t('v2.gameList.create') }}
                    .flex-1.mt-3
                        a-input(v-model:value="gameInfo.name" :disabled="saveGame" :placeholder="$t('v2.gameList.inputPlaceholder')")
                        div.p-t2
                        a-input(v-model:value="gameInfo.packageVersion" :disabled="saveGame" :placeholder="$t('v2.gameList.packageVersion')")
                        //- a-button.ml-4(class="!h-10" :loading="saveGameLoading" :disabled="!gameInfo.name.trim() || saveGame" @click="saveGameHandle") {{ $t('v2.action.save') }}
                game-upload-tip
                .flex.justify-end.mt-8
                    a-button.ml-4(v-if="!saveGame" :loading="saveGameLoading" :disabled="!gameInfo.name.trim() || saveGame" @click="saveGameHandle") {{ $t('v2.action.save') }}
                    a-button.v2-btn(v-else type="primary" :loading="createGameLoading" :disabled="!canSubmit"
@click="onNext") {{ $t('v2.action.next') }}
                    //- a-button(type="primary" :loading="createGameLoading" :disabled="!canSubmit" @click="onNext") {{ $t('v2.action.next') }}
</template>

<script lang="ts" setup>
import API from '@/common/api/console/v2/home'
import Step from './components/step.vue'
import StepBack from '@/views/console/v2/components/stepBack.vue'
import gameUploadTip from '@/views/console/v2/components/gameUploadTip.vue'
import { useGlobalStore } from "@/store/global";
import { getUserId, getUserUid} from "@/utils/sso"

const saveGameLoading = ref(false)
const saveGame = ref(false)
const createGameLoading = ref(false)
const router = useRouter()
const route = useRoute()
const projectId = computed(() => route.params.projectId)

const canSubmit = computed(() => gameInfo.name.trim() && saveGame.value && gameInfo.packageVersion.trim())

const gameInfo = reactive({
    name: '',
    id: '',
    packageVersion: ''
})

const saveGameHandle = () => {
    saveGameLoading.value = true
    API.createGame(gameInfo.packageVersion, gameInfo.name, projectId.value as string, getUserUid()).then((data: any) => {
        saveGameLoading.value = false
        saveGame.value = true
        gameInfo.id = data.id
    }).catch(() => {
        saveGameLoading.value = false
    })
}

const onNext = async () => {
    createGameLoading.value = true
    API.setMainStep(2, {
        lastProjectId: projectId.value,
        lastPackageId: gameInfo.id
    }).then(() => {
        console.log("下一步")
        createGameLoading.value = false
        router.push({ name: 'mainProcessStep03', params: { projectId: projectId.value, packageId: gameInfo.id } })
    }).catch((e: any) => {
        console.error("下一步",e)
        createGameLoading.value = false
    })
}
</script>
