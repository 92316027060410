import { LOCALE, isForeign } from '@/common/constants/env'

export const SSO_TOKEN = 'WL_SSO_TOKEN'
export const SSO_INFO = 'WL_SSO_INFO'
export const WL_ENV_ID = 'WL_ENV_ID'
export const WL_LOCALE = 'WL_LOCALE'
export const SSO_USER_ID = "WL_SSO_USER_ID";
export const SSO_USER_UID = "WL_SSO_USER_UID";
export const SSO_USER_TYPE = 'WL_SSO_USER_TYPE'

export const getUserInfo = (key = '') => {
    let userInfo: any = {}
    try {
        userInfo = JSON.parse(localStorage.getItem(SSO_INFO) || '')
    } catch (e) {

    }
    if (key) {
        return userInfo[key]
    }
    return userInfo
}

export const clearLoginInfo = () => {
    window.localStorage.setItem(SSO_TOKEN, '')
    window.localStorage.setItem(SSO_INFO, '')
    window.localStorage.setItem(WL_ENV_ID, '')
    window.localStorage.setItem(WL_LOCALE, '')
    window.localStorage.setItem(SSO_USER_ID, '')
    window.localStorage.setItem(SSO_USER_UID, '')
    window.localStorage.setItem(SSO_USER_TYPE, '')
}

export const setToken = (token?: string) => {
    if (token) {
        window.localStorage.setItem(SSO_TOKEN, token)
    }
}
export const getToken = () => window.localStorage.getItem(SSO_TOKEN)

export const getUserId = () => window.localStorage.getItem(SSO_USER_ID) as string;

export const getUserUid = () => window.localStorage.getItem(SSO_USER_UID) as string;

export const getUserType = () => window.localStorage.getItem(SSO_USER_TYPE)

export const setLocale = (key: string) => {
    window.localStorage.setItem('WL_LOCALE', key)
}
export const getLocale = () => window.localStorage.getItem('WL_LOCALE')

export const saveLoginInfo = (userInfo: any) => {
    setLocale(isForeign ? getLocale() || 'en' : 'zh')
    window.localStorage.setItem(SSO_TOKEN, userInfo.authorization)
    window.localStorage.setItem(SSO_INFO, JSON.stringify(userInfo))
    window.localStorage.setItem(SSO_USER_ID, userInfo.id);
    window.localStorage.setItem(SSO_USER_UID, userInfo.username);
    window.localStorage.setItem(SSO_USER_TYPE, userInfo.userType)
}