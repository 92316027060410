<template lang="pug">
.flex.mb-4.justify-between.items-center
    h1.page-title.mb-0.pt-0 {{ $t('v2.gameList.title') }}
    .search.flex.gap-5.pt-1.pr-1
        a-input(v-model:value="keyword" :placeholder="$t('v2.action.search')" allowClear @keyup.enter="searchBox" )
            template(#prefix)
                .mr-1(class="-mt-1")
                    svg-icon(name="search" :size="14")
        a-button(type="primary" @click="createGame")
            svg-icon(name="plus" :size="14" class="-mt-0.5 mr-1")
            | {{ $t('v2.action.create') }}
.bg-white.px-6.pt-3.pb-6.rounded-lg.h-full.common-console-list(class="mt-[14px]")
    .left-top
    .right-top
    .left-bottom
    .right-bottom
    game-list(ref="listRef" show-title).relative.z-2.h-full
</template>

<script lang="ts" setup>
import GameList from '@/views/console/v2/components/gameListNew.vue'

const listRef = ref()
const keyword = ref('')
const router = useRouter()

const searchBox = () => {
    listRef.value.fetchList({
        packageName: keyword.value,
        current: 1,
    })
}

watch(keyword, (v) => {
    if (!v.trim()) {
        listRef.value.fetchList({
            current: 1
        })
    }
})

const createGame = () => {
    router.push({ name: 'gameCreate' })
}
</script>