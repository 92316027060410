<template lang="pug">
section
    template(v-if="loading")
        .flex.items-center.justify-center.pt-40
            a-spin(size="large" :spinning="loading" :tip="$t('v2.action.loading') + '...'")
    template(v-else-if="!result?.records?.length || !total")
        .relative(class="top-1/2 -translate-y-1/2 -mt-9")
            div.text-center
                template(v-if="fromSearch")
                    svg-icon(name="empty" :size="100").pri-color
                    div(class="text-base text-[#4E5969] font-medium")  {{ $t('v2.projectList.searchDesc') }}
                template(v-else)
                    img(class="w-45 m-auto" src="@/assets/images/home/create-project.png")
                    div(class="text-base txt-second-color font-medium")  {{ $t('v2.gameList.emptyText') }}
    template(v-else-if="requestUrl === 'getProjectGames'")
        .flex.justify.mb-4(v-if="showTitle")
            span(v-if="result.total" class="text-[#86909C] text-xs") {{ total }} {{ $t('v2.gameList.include') }}
        .list-wrap
            game-item(v-for="i in result.records" :key="i.id" :item="i" :from-project="fromProject"
@on-delete="total--")
        .flex.justify-end
            a-pagination(
                v-if="!hidePagination && result.total > result.size"
                v-model:current="result.current"
                size="small"
                :total="result.total"
                :pageSize="result.size"
                @change="currentChange"
            )
    template(v-else)
        div.mb-6.pt-2(v-for="item in result.records" :key="item.applicationId" :item="item" )
            .flex.justify-between.project-name-wrap.mb-3
                div
                    span {{ $t('v2.action.project') }}:
                    span.point.mx-1
                    span {{item.applicationName}}
                .date {{ `(${item.gameList.length} ${$t('v2.action.games')})`}}
            .list-wrap
                game-item2( v-for="i in item.gameList" :key="i.id" :item="i" :from-project="item.gameList"
@on-delete="total--")
            .flex.justify-end
</template>

<script lang="ts" setup>
import API from '@/common/api/console/v2/home'
import GameItem from './gameItem.vue'
import GameItem2 from './gameItem2.vue'

const props = withDefaults(defineProps<{
    fromProject?: boolean,
    showTitle?: boolean,
    hidePagination?: boolean,
    params?: Record<string, any>
    item?: any
}>(), {
    params: () => ({ size: 20, current: 1 })
})

const total = ref(0)
const route = useRoute()
const router = useRouter()
const { current, size } = props.params || {}
const result = ref<any>({
    current,
    total: 0,
    size,
    records: []
})
const loading = ref(true)
const fromSearch = ref('')
const requestUrl = ref('')
requestUrl.value = props.fromProject ? 'getProjectGames' : 'getGames'
const fetchList = (params: any = {}) => {
    fromSearch.value = params.packageName
    const currentParams = { ...props.params,  ...params}
    if (props.fromProject) {
        currentParams.applicationId = route.params.projectId
    }
    loading.value = true
    API[requestUrl.value](currentParams).then((data: any) => {
        if(requestUrl.value === 'getGames'){
            // let arr = []
            // arr.push(data[0])
            // arr.push(data[0])
            result.value.records = data
            total.value = data.length
        } else {
            result.value = data
            total.value = data.total
        }
        loading.value = false
    }).catch(() => {
        loading.value = false
    })
}

const currentChange = (page: string) => {
    fetchList({ current: page, packageName: fromSearch.value })
}
const createGame = () => {
    router.push({ name: 'gameCreate', params: { projectId: route.params.projectId } })
}

defineExpose({
    fetchList
})

onBeforeMount(fetchList)
</script>
<style lang="sass" scoped>
.project-name-wrap
    font-size: 12px
    color: #86909C
    font-weight: 400
    width: 256px
    .date
        color: #C9CDD4
</style>

