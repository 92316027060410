<template lang="pug">
    a-modal(v-model:open="addResoureVisible" :footer="null" width="50%" centered).text-center.pb-10
        div(v-if="stpeNum == 0").stpe-num.step1
            h3.model-titlt.pt-5.text-center Step 1. Schedule your reserved plan
            p.model-txt.text-center.pt-2.pb-4 Selecting your plan starting date and its cycle mode.
            a-row.pl-6
                a-col(:span="10" :class="{ 'error': formState.startDate }")
                    p Start Date
                    a-date-picker(v-model:value.trim="form.startDate" :disabled-date="disabledDate" style="width:100%" placeholder="Select the start date")
                    //- .msg-error(v-if="formState.startDate") {{ $t('userCenter.login.foreign.username.error') }}
                a-col.ml-6(:span="10" :class="{ 'error': formState.cycleValue }")
                    p Cycle Mode
                    a-input-group(compact style="width:100%")
                        a-select(v-model:value="form.planType" style="width:36%")
                            a-select-option(value="1") Monthly
                            a-select-option(value="0") Yearly
                        a-input-number(v-model:value.trim="form.cycleValue" :min="1" :max="12" placeholder="1-12" style="width:64%")
                    //- .msg-error(v-if="formState.quantity") {{ $t('userCenter.login.foreign.username.error') }}
            a-row.mt-6.pl-6
                a-col(:span="10" :class="{ 'error': formState.quantity }")
                    p CCU
                    a-input-number.text-center( style="width:100%" v-model:value.trim="form.quantity" placeholder="Please input CCU number"  :max="10000" :controls="false" :min="1" )
                    //- .msg-error(v-if="formState.cycleValue") {{ $t('userCenter.login.foreign.username.error') }}
            div.flex.mt-10.justify-center
                a-button( class="!font-bold" @click="skipStepOneFn(true)") Skip
                a-button.v2-btn.ml-6( v-if="btnFa"  type="primary" @click="skipStepOneFn(false)" ) Next
                a-button.ml-6( v-else type="primary" disabled)
                            span.font-bold Next
        div(v-if="stpeNum == 1").stpe-num.step2
            h3.model-titlt.pt-5.text-center Step 2. Schedule your on demand plan
            p.model-txt.text-center.pt-2.pb-4 Set detailed weekly schedule.
            online-table( @addOnlinetabelFn="addOnlinetabelFn" :btnFa="btnFa" @sebmitAddFn="sebmitAddFn" @skipFnSet="skipFnSet" :pageModel="'skip'"  ref="onlineTableDom")
        div(v-if="stpeNum == 2").stpe-num.step3
            h3.model-titlt.pt-5.text-center Step 3. Confirm Test Resource Release Date
            p.model-txt.text-center.pt-2.pb-4 The test resources will be released at 5:00 PM (UTC+8) on the release date.
            a-row.pl-10.pr-10
                a-col(:span="12")
                    p Release Date
                    a-date-picker( style="width:90%" :disabled="step3Disabled" :disabled-date="disabledDate2" placeholder="Select the release date" @change="checkWeekFn" v-model:value="releaseVal")
                a-col(:span="12")
                    p Start Date
                    a-date-picker( style="width:90%" disabled  @change="checkWeekFn" v-model:value="recordStartTime")
            div.flex.mt-10.justify-center
                a-button.v2-btn(type="primary" v-if="releaseVal || step3Disabled" @click="sebmitAddFn" ) Next
                a-button(type="primary" v-else disabled ) Next
        div(v-if="stpeNum == 3").stpe-num.step3
            //- CheckCircleOutlined
            h3.model-titlt.pt-5.text-center Step 3. Confirm Test Resource Release Date
            p.model-txt.text-center.pt-2.pb-4 The test resources will be released at 5:00 PM (UTC+8) on the release date.
            a-button.v2-btn.ml-6(type="primary" @click="sebmitAdd()" ) Next
        p.pt-1
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { getLocale, getToken, getUserId, getUserUid } from '@/utils/sso';
import { isForeign } from "@/common/constants/env"
import API from '@/common/api/console/v2/home'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { bytesToSize } from '@/utils/bytesConvert'
import { resourceStatusMap } from '@/views/console/v2/constants/index'
import { Toast } from '@welink/design';
import { formatData, doHandMonth, getDataFn } from "@/utils/index"
import dayjs, { Dayjs } from 'dayjs';
import { useGlobalStore } from '@/store/global'
// import { datasetModule } from 'snabbdom';
import { regPassword } from '../../../../../utils/validate';
import transformerDirectives from '@unocss/transformer-directives';
import onlineTable from './onlineTable.vue'
import { router } from '@/routers';
const emit = defineEmits(['subFirstFnData','subFirstFnData2','subFirstFnData3'])

const releaseVal = ref(null)
const onlineTableDom = ref(null)
const btnFa = ref(false)
const modelStep = ref("reserved") // reserved 包年 ， demand 
const skipFn = (e, fa) => {
    stpeNum.value = e
    btnFa.value = false
}
const skipFnSet = (e, r) =>{
    if(form.quantity == 0 && !form.startDate){
        skipFn(0)
    }else{
        skipFn(2)
        recordStartTime.value = form.startDate
    }
}
const skipStepOneFn = (e) => {
    skipFn(1)
    if(onlineTableDom.value){
        onlineTableDom.value.setBtnDisFn(e)
    }else{
        setTimeout(() => {
            onlineTableDom.value.setBtnDisFn(e)
        },100)
    }
    if(e){
        form.quantity = 0
        form.startDate = ''
    }
}
const step3Disabled = ref(false)
const recordStartTime = ref(null)
const recObj = ref(null)
const sebmitAddFn = (res, key, cyc) => {
    const releaseValStr = releaseVal.value? formatData(releaseVal.value) : ''
    let param = {
            releaseDate: releaseValStr,
            cycleValue:form.cycleValue,
            planType:Number(form.planType),
            quantity:form.quantity,
            startDate: formatData(form.startDate),
        }
    try {
        recObj.value.releaseDate =  releaseValStr
        emit('subFirstFnData3', recObj.value, param)
    } catch (error) {
        let obj = {
            releaseDate:releaseValStr
        }
        emit('subFirstFnData3', obj, param, 'setp2' )
    }
}
const jecTimeStr = ref("")
const checkSkipFn = () => {
    stpeNum.value == stpeNum.value --
}
const stpeNum = ref(0)
const props = defineProps({
    gameInfo: {
        type: Object,
        default: ''
    },
    nodeId: {
        type: String,
        default: ''
    },
    resourceDetailId: {
        type: String,
        default: ''
    },
})
const addResoureVisible = ref(true)
const form = reactive({
    startDate: '',
    planType: '1',
    quantity:'',
    cycleValue:''
})
const timeStart = ref(null)
const formState = ref({
    startDate: false,
    quantity:false,
    cycleValue:false
})
const cancelFn = () => {
    addResoureVisible.value = false
    formState.value.startDate = ''
    formState.value.quantity = ''
    formState.value.cycleValue = ''
}
const openBoxFn = (num) => {
    if(num){
        stpeNum.value = 0
        btnFa.value = false
        form.startDate= ''
        form.planType =  '1'
        form.quantity = ''
        form.cycleValue = ''
    }
    formState.value.startDate = ''
    formState.value.quantity = ''
    formState.value.cycleValue = ''
    addResoureVisible.value = true
}
const checkResource = ref<Number>(0)
const store = useGlobalStore()
const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const loading = ref(true)

const planType = ref<any>("1")

const gameInfoList = ref<any>({
    resourceList: []
})

const onDemandListFn = async (res) => {
    const param = {
        packageId: res.gameId,
        nodeId: res.nodeId,
        resourceDetailId: res.resourceDetailId || '', 
        applicationId: res.applicationId,
        userId: getUserUid() || '',
        current: 1,
        size: 30
    }
    const dateList:any = await API.getQueryReservedPlan(param)
    // console.log(dateList)
}

const getGameDetailListFn = () => {
    loading.value = true
    API.getGameDetailList({packageId:route.params.gameId , id: getUserId(), environmentId: store.envId , ...paginationParams.value }).then((data: any) => {
    }).catch(() => {
        loading.value = false
    })
}

const addOnlinetabelFn = (e, key, cyc) => {
    let itemsData = [];
    let obj = {};
    e.map((item) => {
        obj = {
            endTime: item.endDate,
            startTime: item.startDate,
            startDate:item.date,
            quantity: item.ccu
        }
        itemsData.push(obj)
    })
    const param = {
        onDemand:{
            cycleValue:cyc,
            items: itemsData
        },
        timeZone: key
    }
    const timerDay = itemsData[0].startDate
    timeStart.value = dayjs(timerDay, 'YYYY-HH-DD')
    if(timeStart.value && form.startDate ){
        if(dayjs(timeStart.value).endOf('day') < dayjs(form.startDate).endOf('day')){
            recordStartTime.value = timeStart.value
        }else{
            recordStartTime.value = form.startDate
        }
    }else if (timeStart.value){
        recordStartTime.value = timeStart.value
    }else{
        recordStartTime.value = form.startDate
    }
    

    releaseDate.value = dayjs(getDataFn(timerDay, 6), 'YYYY-HH-DD')
    recObj.value = param
    skipFn(2)
    modelStep.value == "demand"
}

const getTestResourceNode = () => {
    API.getTestResourceNode(route.params.gameId ,props.nodeId).then((data: any)=>{
        console.log(!data)
        if(!data){
            // 没有可释放的测试节点
            step3Disabled.value = true
        }else{
            step3Disabled.value = false
        }
    }).catch((e) => {
        console.log(e)
    })
}
getTestResourceNode()
// getGameDetailListFn()
const changeParams = (params: any) => {
    paginationParams.value.current = params
    getGameDetailListFn()
}
const sizeChange = (size: any) => {
    paginationParams.value.current = 1
    paginationParams.value.size = size
    getGameDetailListFn()
}
const sebmitAdd = () => {
    console.log(form)
    formState.value.startDate = Boolean(!form.startDate)
    formState.value.quantity = Boolean(!form.quantity)
    formState.value.cycleValue = Boolean(!form.cycleValue)
    if(formState.value.startDate || formState.value.quantity || formState.value.cycleValue){
        return
    }
    emit('addResourceFn', form)
}

const columns = reactive<any[]>([
    { title: t('onlineObj.startDate'), dataIndex: 'nodeName', key: 'nodeName', txt: t('onlineObj.plan') },
    {
        title: t('onlineObj.startDate'),
        dataIndex: 'skuName', key: 'skuName',
        txt: t('onlineObj.real')
    },
    { title: t('onlineObj.endDate'), dataIndex: 'specfication', key: 'skuName', txt: t('onlineObj.plan')},
    { title: t('onlineObj.endDate'), dataIndex: 'createTime', key: 'createTime', txt: t('onlineObj.real') },
    { title: t('onlineObj.cycle'), dataIndex: 'quantity', key: 'quantity', txt: t('onlineObj.plan') },
    {
        title: t('onlineObj.ccu'),
        prop: 'extensionAmount',
        txt: t('onlineObj.plan')
    },
    { title: t('onlineObj.ccu'), dataIndex: 'status', key: 'status',txt: t('onlineObj.real') },
])
defineExpose({
  onDemandListFn,
  cancelFn,
  openBoxFn
});
const releaseDate = ref(null)
const disabledDate = (current: Dayjs) => {
  return current && current < dayjs(getDataFn(new Date, 6)).endOf('day');
};

const disabledDate2 = (current: Dayjs) => {
  return  current &&  (current > dayjs(getDataFn(recordStartTime.value, -1)).endOf('day') || current < dayjs(getDataFn(new Date, -1)).endOf('day'))
};
watch(() => stpeNum, (newValue) => {
    if(newValue == 0){
        if(newValue.startDate && newValue.quantity && newValue.cycleValue){
            btnFa.value = true
        }else{
            btnFa.value = false
        }
    }
},{deep: true})
watch(() => form,(newValue) => {
        if(newValue.startDate && newValue.quantity && newValue.cycleValue){
            btnFa.value = true
            timeStart.value = dayjs(formatData(newValue.startDate),'YYYY-HH-DD')
            releaseDate.value = dayjs(getDataFn(formatData(newValue.startDate),6),'YYYY-HH-DD')
        }else{
            btnFa.value = false
        }
    }
    ,{deep: true}
)
watch(() => props.nodeId,(newValue) => {
        getTestResourceNode()
    }
    ,{deep: true}
)
</script>

<style lang="sass" scoped>
.cover
    border: 1px solid #E5E6EB
    width: 270px
    height: 164px
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.74) 0%, rgba(255, 255, 255, 0.74) 100%), linear-gradient(285deg, #124EF4 -55.98%, #FEDFFF 105.41%)
    &:hover
        img
            width: 100px
            height: 100px
.info-flex-2
        flex: 2
.empty-img
    display: inline-block
.model-titlt
    font-family: Roboto
    font-size: 24px
    font-weight: 500
    line-height: 33.6px
    text-align: center
    color: #1D2129
.model-txt
    font-family: Roboto
    font-size: 14px
    font-weight: 400
    line-height: 16.8px
    text-align: center
    color: #86909C
.model-img
    display: block
    margin: 0 auto
    width:265px
    height:221px
.model-btn
    display: block
    margin: 0 auto

.msg-error
    display: block
    margin-top: 6px
    color: rgb(255,0,0)
</style>