<template lang="pug">
    h1(v-if="route.params.applicationId").console-title.pl-6.pt-4
        span(class="text-[#86909C]")
            router-link(:to="{ name: 'projectList' }") {{ $t('v2.projectList.title') }}
            span.mx-1 /
            span(@click="goBack").cursor-pointer {{ gameInfo.applicationName }}
            span.mx-1 / {{ gameInfo.packageName }}
            span.mx-1 / {{route.params.nodeName}}
    a-drawer(:width="route.name==goOnline ? '800' :'660'" placement="right" :closable="false" :open="open" @close="onClose")
        schedule(ref="scheduleDom" @addOnlinetabelFn="addOnlinetabelFn" :nodeIdCheckStr="nodeIdCheckStr" :resourceIdCheckStr="resourceIdCheckStr" @close="onClose" @submitFn="submitFn" :timeNew="timeNew")
        template(#footer)
            div.flex.mt-10.justify-center
                a-button.mr-20(v-if="dataListType==0" danger type="primary"  @click="delScheduleFN")  Delete Plan
                a-button( danger  @click="onClose") {{ $t('v2.action.cancel') }}
                a-button.v2-btn.ml-8.mb-10( v-if="dataListType==2" disabled type="primary") {{ $t('v2.action.save') }}
                a-button.v2-btn.ml-8.mb-10( v-else   @click="getScheduleFn" type="primary" ) {{ $t('v2.action.save') }}
    .pt-2.min-h-full(class="mt-[14px] !pr-10")
        a-alert(message="Scheduling Rules" class="mb-6 alert-mb" type="warning" show-icon closable)
            template(#description)
                section 1. Displays On Demand schedules only. For Reserved, use 
                    span.alink-txt(@click="goScheduleFn") Schedule page.
                section 2. 7 days lead time required for creating/modifying schedules.
        .flex.powertime-wrap.min-h-full
            .powertime.bg-white.rounded-lg.flex-1.min-h-full.p-2.mr-5
                a-calendar(:value="calendarData" @select="selectDayFn" :disabled-date="disabledDate" @change="onPanelChange" @panelChange="onPanelChange" :weekFirstDate="1" )
                    template(#headerRender="{ value: current, type, onChange, onTypeChange }")
                        div.timers-wrap.flex.justify-between.align-center
                            div.flex.align-center
                                span(@click="monthDelFn()").cur-text.pr-3.pt-1.show-yh-btn < 
                                span.font-time.show-yh {{monthList[timerObj.month]}},
                                span.font-time.show-yh {{timerObj.year}} 
                                span(@click="monthAddFn()").cur-text.pl-3.pt-1.show-yh-btn >
                            div.cur-text.flex.align-center.go-today(@click="goTodayFn")
                                <svg-icon name="aimOutlined"></svg-icon>
                                span.ml-2 {{ $t('v2.action.today') }}
                    template.pt-5(#dateFullCellRender="{ current }")
                        section(:class="setDateFn(current).cla").h-31.border-calendar
                            p.today-p(v-if="setDateFn(current).today") Today
                            p.title-calendar.text-left(:class="setDateFn(current).blod") {{ setDateFn(current).current }}
                            p.mt-6.content-calendar(v-if="setDateFn(current).content" :class="setDateFn(current).cal") {{ setDateFn(current).content }}
            .game-detail-wrap-r.align-between.flex
                .bg-white.rounded-lg.game-detail-wrap-t
                    .thumb(class="mr-[50px]")
                        span.text-sm.txt-second-color.block.mb-1.font-bold {{ $t('v2.action.game') }}
                        .text-base.txt-color.block.mb-2.font-medium {{ gameInfo?.packageName || '-' }}
                        .flex.items-center.justify-center.rounded-lg.game-img-wrap.cover
                            img.game-img(src="@/assets/images/console/game-cover.png")
                    .info-wrap.pt-4
                            span.text-sm.txt-second-color.block.my-1 {{ $t('v2.action.project') }}
                            .text-base.txt-color.block.mb-3.font-medium {{ gameInfo?.applicationName || '-' }}
                            span.text-sm.txt-second-color.block.my-1 {{ $t('common.version') }}
                            .text-base.txt-color.block.mb-3.font-medium {{ gameInfo?.packageVersion || '-' }}
                            span.text-sm.txt-second-color.block.mb-1 {{ $t('v2.gameList.fileSize') }}
                            .text-base.txt-color.block.mb-2.font-medium {{ bytesToSize(gameInfo?.packageSize) }}
                            span.text-sm.txt-second-color.block.my-1 {{ $t('v2.console.sidebar.resource') }}
                            .text-base.txt-color.block.mb-3.font-medium {{ gameInfo?.skuName || '-' }}
                            span.text-sm.txt-second-color.block.mb-1 {{ $t('console.appInfo.resourceList.skuName') }}
                            .text-base.txt-color.block.mb-3.font-medium {{ gameInfo?.specfication  || '-' }}
                .bg-white.rounded-lg.game-detail-wrap-b.mt-5
                    span.text-sm.txt-second-color.block.my-1 {{ $t('v2.gameList.otherRegion') }}
                    .cur-text.text-base.txt-color-1.block.mb-2(v-for="item in otherNodeList.list" :key="item.nodeId" @click="checkViewFn(item)") {{ item?.nodeName }}
    a-modal(v-model:open="delOpenFa"  :footer="null" :closable="false")
        a-result(title="Are you sure?" sub-title="Deleting will permanently remove this plan. This cannot be undone!")
            template(#icon)
                img( style="width:80px;margin:0 auto" src="@/assets/images/new/delete.png")
            template(#extra)
                a-button.v2-btn.mr-6(key="console" @click="delOpenFa = false" type="primary") Cancel
                a-button(key="buy" @click="delFn") Delete
    a-modal(v-model:open="successModel" :footer="null" :closable="false")
        a-result(status="success" title="CCU Schedule Confirmed" sub-title="Your submitted CCU schedule will be executed as planned.")
            template( #extra)
                a-button.v2-btn.mt-8(@click="nextDataFn") Confirm
    a-modal(v-model:open="warningModel" :footer="null" :closable="false")
        a-result(status="warning" title="Requested CCU Exceeds Quota" sub-title="Requested CCU exceeds your flexible quota. We are applying for additional quota. Detailed updates will be communicated via email.")
            template( #extra)
                a-button.v2-btn.mt-8(@click="warningModel = false") Confirm
</template>

<script lang="ts" setup>
import API from '@/common/api/console/v2/home'
import { bytesToSize } from '@/utils/bytesConvert'
import dayjs, { Dayjs } from 'dayjs';
import { formatData, getDataFn } from "@/utils/index"
import { getUserId, getUserUid} from "@/utils/sso"
import Schedule from './components/schedule.vue'
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { message } from '@welink/design-v2';

const emit = defineEmits(['checkNodeFn'])

dayjs.extend(weekday)
dayjs.extend(localeData)
const route = useRoute()
const warningModel = ref(false)
const router = useRouter()
const delOpenFa = ref(false)
const calendarData = ref<any>("")
const loading = ref<boolean>(false)
const timeNew = ref<any>("")
const scheduleDom = ref(null);
const gameInfo = ref<any>({
    applicationId: null,
    applicationName: null,
    packageName: null,
    packageVersion: null,
    packageSize: 0,
    skuName: null,
    specfication: null,
    resourceDetailId: null
})
const resourceDetailIdStr = ref("")
const nodeIdCheckStr = ref("")
const props = defineProps({
    propsData: {
        type: Object,
        default: ''
    },
    resourceDetailId:{
        type: String,
        default: ''
    }
})
const timerObj = reactive<any>({
    year: new Date().getFullYear() ,
    month: new Date().getMonth()
})

const delScheduleFN = () => {
    delOpenFa.value = true
}

const otherNodeList = reactive<any>({
    list:[]
})
const goBack = () => {
  router.go(-1)
}
const onPanelChange = (value: Dayjs, mode: string) => {
    console.log(value, mode);
}

const disabledDate = (current: Dayjs) => {
  return  current &&  (current >= dayjs(endTimeStr.value).endOf('day') || current < dayjs(startTimeStr.value).subtract(1, 'day').endOf('day'))
};
const dataListType = ref(2)
const delResourceDetailId = ref(null)
const selectDayFn = (res: any) => {
    timeNew.value = formatData(res)
    const timeObj = {
      new: dayjs(getDataFn(new Date(), 6)).unix(), // new: dayjs(new Date()).unix(),
      start: dayjs(timeNew.value).unix(),
      future: dayjs(getDataFn(timeNew.value, 6)).unix()
    }
    if(timeObj.start > timeObj.new ){
      let recordTime = null;
      dataListType.value = 1
      timeDelList.value.map((item)=>{
          recordTime = dayjs(item.startDate).unix()
          if(recordTime <= timeObj.future && recordTime >= timeObj.start){
            dataListType.value = 0
            delResourceDetailId.value = item.resourceDetailId
          }
      })
    }else if (timeObj.start <= timeObj.new && timeObj.future > timeObj.new){
      dataListType.value = 1
    }else{
      dataListType.value = 2
    }
    open.value = true
    if(scheduleDom.value){
        scheduleDom.value.newTimeFn(timeNew.value,res)
    } else {
        setTimeout(()=>{
            scheduleDom.value.newTimeFn(timeNew.value,res)
        },50)
    }
}
const getStartOfDayTimestamp = (date) => {
  // 使用 dayjs 获取指定日期的年月日 00 点时间
  const startOfDay = dayjs(date).startOf('day');
  // 将 day.js 对象转换为 Unix 时间戳（秒）
  return startOfDay.unix();
}
const nextDay = getStartOfDayTimestamp(dayjs(getDataFn(new Date, 7)))
const toDayStr = getStartOfDayTimestamp(new Date());
const resourceIdCheckStr = ref("")
const dayStr = ref(20)
const timeDelList = ref([])
const claArr = ['txt-ccc','txt-red','txt-grn']
const setDateFn = ( e ) => {
    let obj = {}
    // 时间是否是今天
    obj.today = e.startOf('day').unix() == toDayStr ? true : false
    // 时间是否是1号
    obj.current = e.date()==1 ? `${monthList.value[e.get('month')]},${e.date()}` : e.date()
    // 1号 和 今天字体加重
    if(e.date()==1 || obj.today) obj.blod = 'fontbold'
    e.startOf('day').unix()
    if(e.startOf('day').unix() > dayjs(endTimeStr.value).unix() || e.startOf('day').unix() < dayjs(startTimeStr.value).unix()){
        obj.cla = "txt-ccc"
    }
    timeDelList.value.map((item) => {
        if(e.startOf('day').unix() == getStartOfDayTimestamp(item.startDate) ){
            obj.cla = claArr[item.editStatus]
            obj.editStatus = item.editStatus
            const startTime = item.startTime < 10 ? '0' + item.startTime :item.startTime
            const endTime = item.endTime < 10 ? '0' + item.endTime :item.endTime
            const timeZone = item.timeZone ? item.timeZone : ''
            obj.content = `${startTime}:00-${endTime}:00 ${timeZone} | CCU:${item.quantity}`
        }
    })
    return obj
}



const monthNumList = ['01','02','03','04','05','06','07','08','09','10','11','12']
const startTimeStr = ref<String>('')
const endTimeStr = ref<String>('')
const startTime = (year,month) => {
    startTimeStr.value = `${year}-${monthNumList[month]}-01`
}
const endTime = (year,month) => {
    const dayArr1 = [3,5,8,10]
    let endDay = '31'
    if(dayArr1.indexOf(month) > -1){
        endDay = '30'
    } else if (month == 1){
        endDay = year % 4 == 0 ? '29' : '28'
    }
    endTimeStr.value = `${year}-${monthNumList[month]}-${endDay}`
}
const getNodeListFn = async () => {
    const nodeList: any = await API.queryOnlineResourceNode(route.params.gameId)
    otherNodeList.list = []
    const nodeIdStrArr = [] 
    if (nodeList && nodeList.length > 0){
      if(nodeIdCheckStr.value){
          nodeList.map((item) => {
              if(item.nodeId != nodeIdCheckStr.value){
                  nodeIdStrArr.push(item)
              }
          })
      }else {
          nodeList.map((item,index) => {
              if(index > 0){
                  nodeIdStrArr.push(item)
              }
          })
      }
    }
    otherNodeList.list = [...nodeIdStrArr]
}
const dataInit = async () => {
    let resourceId = route.params.resourceDetailId == 0 ? '' : route.params.resourceDetailId
    if(resourceIdCheckStr.value){
        resourceId = resourceIdCheckStr.value
    }
    const param = {
        packageId: route.params.gameId,
        startDate: startTimeStr.value,
        endDate: endTimeStr.value,
        nodeId:  nodeIdCheckStr.value || paramProps?.nodeId,
        resourceDetailId: resourceId || paramProps?.resourceDetailId, 
        applicationId: route.params.applicationId || route.params.projectId,
        id: getUserId(),
        userId: getUserUid(),
        current: 1,
        size: 200
    }
    if(!param.resourceDetailId) return ;
    const dateList:any = await API.getQueryOnDemandPlan(param)
    timeDelList.value = dateList
    const setTimer = `${timerObj.year}-${timerObj.month+1}-01`
    calendarData.value = dayjs(setTimer)
    if(route.name=="goOnline"){
        getNodeListFn()
    }
}

const monthAddFn = () => {
    if(timerObj.month == 11){
        timerObj.month = 0
        timerObj.year++
    }else{
        timerObj.month++
    }
    const setTimer = `${timerObj.year}-${timerObj.month+1}-01`
    calendarData.value = dayjs(setTimer)
    startTime(timerObj.year,timerObj.month)
    endTime(timerObj.year,timerObj.month)
    dataInit()
}

const monthDelFn = () => {
    if(timerObj.month == 0){
        timerObj.month = 11
        timerObj.year--
    }else{
        timerObj.month--
    }
    const setTimer = `${timerObj.year}-${timerObj.month+1}-01`
    calendarData.value = dayjs(setTimer)
    startTime(timerObj.year,timerObj.month)
    endTime(timerObj.year,timerObj.month)
    dataInit()
}
const monthList = ref(['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sept','Oct','Nov','Dec'])

const getGameInfoFn = () => {
    API.getGameInfo(route.params.gameId as string).then((data: any) => {
        gameInfo.value = data
        loading.value = false
    }).catch(() => {
        loading.value = false
    })
}
const paramProps = inject('paramProps')
const paramPropsNodeId = inject('paramPropsNodeId')
const paramPropsResourceDetailId = inject('paramPropsResourceDetailId')

const resetView = () => {
    getGameInfoFn()
    getNodeListFn()
    dataInit()
}
const goScheduleFn = () => {
    router.push({ name: 'goOnline', params: {
        gameId: route.params.gameId,
        projectId: route.params.applicationId,
        resourceDetailId:route.params.resourceDetailId,
    } })
}
const initFn = (e) => {
    if(e){
        resourceIdCheckStr.value = e.resourceDetailId
        nodeIdCheckStr.value = e.nodeId
    }
    startTime(timerObj.year, timerObj.month)
    endTime(timerObj.year, timerObj.month)
    resetView()
}
const alertFn = () => {
    console.log('--------------------')
    // console.log(notification)
    // import { Notification } from 'ant-design-vue';

}
onBeforeMount(() => {
    initFn()
    alertFn()
})
const setTimeInitFn = (year,month) => {
    timerObj.year = year
    timerObj.month = month
    initFn()
}
const checkViewFn = (item) => {
    resourceIdCheckStr.value = item.resourceDetailId
    nodeIdCheckStr.value = item.nodeId
    if(route.name=="goOnline"){
        emit('checkNodeFn',null ,item)
    }else{
        resetView()
    }
}
const submitFn = () =>{
    
}

const addOnDemandPlanFn = (e) => {
    API.addOnDemandPlan(e).then((data)=>{
        message.success("Resource added successfully")
        const params = {
            applicationId: e.applicationId,
            nodeId: e.nodeId || '',
            packageId: e.packageId,
            userId: getUserUid(),
            resourceDetailId: e.resourceDetailId || '',
        }
        rescourceTableDom.value.onDemandListFn(e)
    })
}
const addOnlinetabelFn = (e, key,cyc) => {
    let itemsData = [];
    let obj = {};
    e.map((item) => {
        obj = {
            endTime: item.startDate,
            startTime: item.endDate,
            startDate:item.date,
            quantity: item.ccu
        }
        itemsData.push(obj)
    })
    let resourceId = route.params.resourceDetailId == 0 ? '' : route.params.resourceDetailId
    //  const resourceId = route.params.resourceDetailId == 0 ? '' : route.params.resourceDetailId
    // console.log(paramProps)
    // const param = {
    //     packageId: route.params.gameId,
    //     startDate: startTimeStr.value,
    //     endDate: endTimeStr.value,
    //     nodeId: route.params.nodeId || paramProps?.nodeId,
    //     resourceDetailId: resourceId || paramProps?.resourceDetailId, 
    if(!resourceId){
        resourceId = paramProps?.resourceDetailId
    }
    const param = {
        applicationId: gameInfo.value.applicationId,
        nodeId: gameInfo.value.nodeId || '',
        packageId: gameInfo.value.gameId,
        userId: getUserUid(),
        resourceDetailId: resourceId,
        onDemand:{
            cycleValue: cyc,
            resourceDetailId: gameInfo.value.resourceDetailId || resourceId,
            items: itemsData
        },
        timeZone: key
    }
    addOnDemandPlanFn(param)
}

const open = ref<boolean>(false);

const goTodayFn = () => {
    const dataObj = new Date()
    timerObj.year = dataObj.getFullYear()
    timerObj.month = dataObj.getMonth()
    calendarData.value = dayjs(dataObj)
    startTime(timerObj.year,timerObj.month)
    endTime(timerObj.year,timerObj.month)
    dataInit()
}
const delFn = () => {
    console.log(gameInfo.value)
    const resourceId = route.params.resourceDetailId == 0 ? '' : route.params.resourceDetailId
    const param = {
        endDate: scheduleDom.value.timeEnd,
        resourceDetailId: resourceIdCheckStr.value || resourceId,
        startDate:scheduleDom.value.tableDate[0].startDate
    }
    API.deleteOnDemandPlan(param).then((data)=>{
        dataInit()
        delOpenFa.value = false
        open.value = false
    })
    // deleteOnDemandPlan 删除
}
const getScheduleFn = () => {
    const arrList = []
    let obj = {}
    const resourceId = route.params.resourceDetailId == 0 ? '' : route.params.resourceDetailId
    scheduleDom.value.tableDate.map((item) => {
         obj = {
             endTime:item.endTime,
             quantity:item.quantity,
             startDate:item.startDate,
             startTime:item.startTime
         }
         arrList.push(obj)
    })
    let resourceIdStr = route.params.resourceDetailId == 0 ? paramProps?.resourceDetailId : route.params.resourceDetailId
    let nodeStr = route.params.nodeId
     
    if(!nodeStr){
        nodeStr = paramProps?.nodeId
    }
    const param = {
        cycleValue: scheduleDom.value.repeatWeek,
        resourceDetailId: resourceIdCheckStr.value || resourceIdStr,
        items: arrList,
        applicationId: route.params.projectId,
        nodeId: nodeIdCheckStr.value || nodeStr,
        packageId: route.params.gameId,
        userId: getUserUid(),
        timeZone: scheduleDom.value.timeZoneDetail
    }
    API.addOnDemandPlan(param).then((data)=>{
        if(data == 1){
            warningModel.value = true
        }else{
            message.success("Resource added successfully")
        }
        dataInit()
        delOpenFa.value = false
        open.value = false
    })
}
const onClose = () => {
  open.value = false;
  dataListType.value = 1
  scheduleDom.value.tableDate = []
};
defineExpose({
  setTimeInitFn,
  initFn
})
</script>

<style lang="sass" scoped>
.cover
    border: 1px solid #E5E6EB
    width: 270px
    height: 164px
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.74) 0%, rgba(255, 255, 255, 0.74) 100%), linear-gradient(285deg, #2053F3 -55.98%, #FEDFFF 105.41%)
    &:hover
        img
            width: 100px
            height: 100px
.info-flex-2
        flex: 2
.powertime-wrap
    :deep(.ant-picker-panel .ant-picker-body th)
        text-align: center
        padding: 0px !important
    :deep(.ant-picker-calendar-date-value)
        text-align: left
    :deep(.ant-picker-calendar-date-content)
        display: flex
        flex-wrap: wrap
        align-content: center
.game-detail-wrap-r
    flex-wrap: wrap
    width: 252px
.game-detail-wrap-t
    width: 252px
    padding: 10px 15px 2px
    .game-img-wrap
        width: 222px !important
        height: 111px !important
    .game-img
        width: 56px
        height: 56px
.game-detail-wrap-b
    width: 252px
    padding: 10px 15px 2px
.font-time
    font-weight: 900
.border-calendar
    border-top:1px solid #cccccc
    border-left:1px solid #cccccc
    padding-top: 5px
    position: relative
    .title-calendar
        padding-left:7px
        color: rgb(62, 71, 86)
    .today-p
        position: absolute
        top: 6px
        right: 8px
        background: #000000
        border-radius: 20px
        color:#ffffff
        padding: 3px 8px
        font-size: 12px
    .content-calendar
        margin-left: auto
        margin-right: auto
        text-align: center
        font-size: 12px
        border: 1px solid #ccc
        border-radius: 6px
        padding: 2px
        width: 95%
        background-color: #F2F3F5
        color: rgb(132, 143, 153)
    .fontbold
        font-weight: 900
        color: #000000
.txt-ccc.border-calendar
    background-color: rgb(245, 246, 248)
.txt-grn.border-calendar
    background-color: #ffffff
    .content-calendar
        border-color: #00B42A
        color: #00B42A
        background-color: #E8FFEA
.txt-red.border-calendar
    background-color: rgb(254, 245, 246)
    .content-calendar
        background-color: rgb(253, 222, 219)
        // #FFC2E9
        border-color: #CB2634
        color: #CB2634
:deep(.ant-picker-date-panel .ant-picker-content)
    tbody
        padding-top:30px
        tr
            border-right:1px solid #cccccc
        tr:last-child
            border-bottom:1px solid #cccccc
    thead
        tr
            th
                height: 40px !important
.show-yh
    font-weight: 900
    color:#000000
    font-size: 22px
.show-yh-btn
    font-size: 18px
.go-today:hover
    color: #124EF4
.alink-txt
    color: #124EF4
    cursor: pointer
.alert-mb
    margin-bottom: 10px!important
</style>