<template lang="pug">
    p(:style="[pStyle, pStyle2]") Schedule
    p(:style="pStyle") Schedule auto switch for your game
    section.pb-2
    a-row
        a-col(:span="16")
            p(:style="pStyle3") Select Week
            a-date-picker( placeholder="Select Week" v-model:value="timeStartDate"  disabled @change="changeFn" style="width:60%"  )
    section.pb-5
    a-row
        a-col(:span="16")
            p(:style="pStyle3") Select Time Zone
            a-select(v-model:value="timeZoneDetail" :disabled="dataListType==2" mode="combobox"  :maxTagCount="1" style="width:60%" placeholder="Tags Mode" :options="timeZone" @change="handleChange")
    section.pb-5 
    a-row
        a-col(:span="16")
            p(:style="pStyle3") Repeat Week
            a-input-number.text-center(v-model:value="repeatWeek" :disabled="dataListType==2" style="width:60%"  :controls="false" :min="0")
                template(#addonBefore)
                    span.action(v-if="dataListType == 2") -
                    span.action(v-else @click="repeatWeek > 0 ? repeatWeek-- : 1") -
                template(#addonAfter)
                    span.action(v-if="dataListType==2") +
                    span.action(v-else @click="repeatWeek++") +
            p.mt-3(:style="pStyle" v-if="timeStart") {{`${timeStart}`}} - {{`${timeEnd}`}}
    section.pb-5
    a-row
        a-col(:span="24")
            p.mb-1(:style="pStyle3") Detailed Weekly Plan
            a-table(
                :data-source="tableDate"
                :columns="columns"
                :pagination="false"
                :scroll="{ x: 460 }"
                v-if="route.params.resourceDetailId&&route.params.resourceDetailId!=0"
            )
              template(#emptyText) 
                  img(class="w-45" style="margin:0 auto" src="@/assets/images/new/reserved.png")
                  .py-10.txt-third-color No plan this week.
              template(#bodyCell="{ column, text, record, index }")
                template(v-if="column.key === 'week'")
                  p.pt-3 {{getWeekFn(record.startDate)}}
                template(v-if="column.key === 'startTime'")
                  div.flex
                    a-select(:options="startTimeList" :disabled="record.editStatus==0" @change="startDateChange(index)" v-model:value="record.startTime")
                    div.select-div-hh -
                    a-select(:options="endTimeList" :disabled="record.editStatus==0" @change="endDateChange(index)" v-model:value="record.endTime")
                    div.select-div-hh.ml-2(v-if="index>0 &&record.editStatus>0" @click="copyDataTimeFn(index)")
                      a-tooltip.icon-sty(title="Copy previous" :color="'#ffffff'" :overlayInnerStyle="{color:'#124EF4'}")
                        svg-icon(name="copy-img" size="18")
                template(v-if="column.key === 'quantity'")
                    a-input-number.text-center( v-model:value="record.quantity" :disabled="record.editStatus==0" style="width:50px" placeholder="Please input CCU number" :max="10000" :controls="false" :min="0" )
                template(v-if="column.key === 'timeZone'")
                    p.pt-3 {{record.timeZone ? record.timeZone : timeZoneDetail}}
            a-table(
                :data-source="tableDate"
                :columns="columns2"
                :pagination="false"
                v-else
            )
              template(#headerCell="{ column }")
                span {{column.title}} 
                span(style="color:#ccc" v-if="column.txt") {{`(${column.txt})`}}
              template(#emptyText) 
                  img(class="w-45" style="margin:0 auto" src="@/assets/images/new/reserved.png")
                  .py-10.txt-third-color No plan this week.
              template(#bodyCell="{ column, text, record, index }")
                template(v-if="column.key === 'week'")
                  p.pt-3 {{getWeekFn(record.startDate)}}
                template(v-if="column.key === 'startTime'")
                  div.flex
                    a-select(:options="startTimeList" :disabled="record.editStatus==0" @change="startDateChange(index)" v-model:value="record.startTime")
                    div.select-div-hh -
                    a-select(:options="endTimeList" :disabled="record.editStatus==0" @change="endDateChange(index)" v-model:value="record.endTime")
                template(v-if="column.key === 'realStartTime'")
                  div.flex(v-if="record.realStartTime")
                    a-select(:options="startTimeList" disabled v-model:value="record.realStartTime")
                    div.select-div-hh -
                    a-select(:options="endTimeList" disabled  v-model:value="record.realEndTime")
                template(v-if="column.key === 'quantity'")
                    a-input-number.text-center( v-model:value="record.quantity" :disabled="record.editStatus==0" style="width:50px" placeholder="Please input CCU number" :max="10000" :controls="false" :min="0" )
                template(v-if="column.key === 'realQuantity'")
                    div.select-div-hh( v-if="record.realQuantity") {{record.realQuantity}}
                template(v-if="column.key === 'timeZone'")
                    p.pt-3 {{record.timeZone ? record.timeZone : timeZoneDetail}}
    section.a-alert-wrap(v-if="alertFaStr")
      a-alert(message="" class="mb-6 alert-mb" type="warning" show-icon closable)
            template(#description)
                section Reasons for non-editable schedule:  
                    span.alink-txt( v-if="alinkFa" @click="checkAlinkFa(false)") See More
                section(v-if="!alinkFa")
                    section 1.The plans for the next seven days are already scheduled.
                    section 2.After evaluation, there is a discrepancy between the actual resources and the planned resources.
                    section.pt-2 If you need to make changes, please submit a ticket to us.
                    section
                        span.alink-txt(@click="checkAlinkFa(true)") Collapse
</template>
<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import API from "@/common/api/console/v2/home";
import { cloneDeep } from "lodash-es";
import { formatData } from "@/utils/index";
import dayjs, { Dayjs } from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { getUserInfo, getUserUid, getUserId } from '@/utils/sso'
import localeData from "dayjs/plugin/localeData";
const emit = defineEmits(['addOnlinetabelFn','close'])
const alinkFa = ref(true)
const checkAlinkFa = (e) => {
  alinkFa.value = e
}
// addOnlinetabelFn nodeIdCheckStr
const props = defineProps({
    nodeIdCheckStr: {
        type: String,
        default: ''
    },
    resourceIdCheckStr: {
        type: String,
        default: ''
    }
})
const { t } = useI18n();
dayjs.extend(weekday)
dayjs.extend(localeData)
const route = useRoute()
const open = ref<boolean>(false);
const tableDate = ref<any>([]);
const startDate = ref<any>(null);
const endDate = ref<any>(null);
const repeatWeek = ref<any>(0);
const timeStart = ref<any>("");
const timeEnd = ref<any>("");
const timeStartDate = ref<Dayjs>();
const dateFormat = "YYYY-MM-DD";
const timeFn = e => {
  return dayjs(e, dateFormat);
};
const tableVal = (i)=>{
  if(tableDate.value[i].startTime >= tableDate.value[i].endTime){
    tableDate.value[i].endTime = 24
  }
}
const startDateChange = i => {
  console.log(i);
  tableVal(i)
};
const endDateChange = i => {
  console.log(i);
  tableVal(i)
};
const copyDataTimeFn = (i) => {
  tableDate.value[i].startTime = tableDate.value[i-1].startTime
  tableDate.value[i].endTime = tableDate.value[i-1].endTime
}
const changeFn = (e) => {
  const cha = repeatWeek.value + 1;
  const endTime = cha * 7 - 1;
  timeEnd.value = getDataFn(formatData(timeStart.value), endTime)
}
const pStyle = {
  fontSize: "14px",
  color: "#86909C",
  lineHeight: "24px",
  display: "block",
  marginBottom: "16px"
};
const pStyle2 = {
  marginBottom: "8px",
  fontSize: "20px",
  fontWeight: "500",
  color: "#1D2129"
};

const pStyle3 = {
  fontSize: "14px",
  fontWeight: "600",
  color: "#4E5969"
};

const handleChange = (value: string) => {
  tableDate.value.map((item)=>{
    if(item.editStatus==2){
      item.timeZone = value
    }
  })
};

const showDrawer = () => {
  open.value = true;
};
const onClose = () => {
  open.value = false;
};
const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
const alertFaStr = ref(false)
const onCloseAlert = () => {
  alertFaStr.value = false
}
const getDayOfWeek = (date: any) => {
  return weekDays[date.getDay()];
};
const getWeekFn = (e) => {
  return weekDays[dayjs(e).day()]
}
const dataLsit = reactive<any>([]);

const doHandMonth = (month: any) => {
  var m = month;
  if (month.toString().length == 1) {
    m = "0" + month;
  }
  return m;
};
const getDataFn = (newTime: any, day: any) => {
  var today = new Date(newTime);
  var targetday = today.getTime() + 1000 * 60 * 60 * 24 * day;
  today.setTime(targetday);
  var tYear = today.getFullYear();
  var tMonth = today.getMonth();
  var tDate = today.getDate();
  tMonth = doHandMonth(tMonth + 1);
  tDate = doHandMonth(tDate);
  return tYear + "-" + tMonth + "-" + tDate;
};
const dataListType = ref(0)
const setTableList = (list, timeObj,e) => {
  let nextDay = null;
  let listDate2 = [];
  let obj = {
    startDate: e,
    quantity: 0,
    startTime: 0,
    endTime: 24,
    editStatus: 2
  };
  listDate2.push(obj);
  [...Array(6)].map((item, index) => {
    nextDay = getDataFn(startDate.value, index + 1);
    obj = {
      startDate: nextDay,
      quantity: 0,
      startTime: 0,
      endTime: 24,
      editStatus: 2
    };
    listDate2.push(obj);
  });

  listDate2.map((item) => {
    if(dayjs(item.startDate).unix() > timeObj.new ){
      item.editStatus = 2
      item.quantity = 0
    }else{
      item.editStatus = 0
    }
    if(list.length > 0){
      list.map((key)=>{
        if(key.startDate == item.startDate){
          item.startTime = key.startTime
          item.endTime = key.endTime
          item.quantity = key.quantity
          item.timeZone = key.timeZone
          item.status = key.status
          item.realQuantity = key.realQuantity
          item.realStartTime = key.realStartTime
          item.realEndTime = key.realEndTime
          item.editStatus = key.editStatus
          if(key.realQuantity){
            item.editStatus = 0
            dataListType.value = 1
          }
        }
      })
    }
  })
  if(listDate2.length > 0&& dataListType.value>0){
    alertFaStr.value = true
  }
  tableDate.value = listDate2
}
const dataList = (e,list)=> {
  const timeObj = {
    new: dayjs(getDataFn(new Date(), 6)).unix(), 
    start: dayjs(e).unix(),
    future: dayjs(getDataFn(e, 6)).unix()
  }
  timeStart.value = e
  alertFaStr.value = false
  // 未来数据
  if(timeObj.start > timeObj.new ){
    dataListType.value = 0
    setTableList(list,timeObj,e)
  // 时间跨度在中间
  }else if (timeObj.start <= timeObj.new && timeObj.future > timeObj.new){
    dataListType.value = 1
    setTableList(list,timeObj,e)
  }else{
  // 不可编辑的时间
    dataListType.value = 2
    if(list.length > 0){
      alertFaStr.value = true
      list.map((item)=>{
        item.editStatus = 0
      })
    }
    tableDate.value = list
  }
};
// tableDate
const paramProps = inject('paramProps')
const checkWeekFn = async (e) => {
  if (!e) return;
  startDate.value = formatData(e);
  const cha = repeatWeek.value + 1;
  const endTime = cha * 7 - 1;
  timeEnd.value = getDataFn(startDate.value, endTime);
  const resourceId = route.params.resourceDetailId == 0 ? paramProps.resourceDetailId : route.params.resourceDetailId
  const param = {
    packageId: route.params.gameId,
    startDate: startDate.value,
    endDate: getDataFn(e, 6),
    nodeId: props.nodeIdCheckStr || route.params.nodeId,
    resourceDetailId: props.resourceIdCheckStr|| resourceId, 
    applicationId:route.params.applicationId || route.params.projectId,
    id: getUserId(),
    userId: getUserUid(),
    current: 1,
    size: 200
  }
  const dateList:any = await API.getQueryOnDemandPlan(param)
  dataList(startDate.value, dateList);
  // inject('subParam')
}

const disabledDate = (current: Dayjs) => {
  return current && current < dayjs().endOf('day');
};
const newTimeFn = (time: any, res: any) => {
  checkWeekFn(time);
  timeStartDate.value = dayjs(time,'YYYY-HH-DD')
};
const getScheduleList = async () => {
    const param = {
        packageId: route.params.gameId,
        nodeId: props.nodeId,
        resourceDetailId: props.resourceDetailId || '', 
        applicationId: props.applicationId,
        userId: getUserUid() || '',
        startDate: subStart.time || formatData(new Date()),
        endDate: subEnd.time || getDataFn(formatData(new Date()), 6)
    }
    const dateList:any = await API.getQueryOnDemandPlan(param)
}
const timeZoneDetail = ref<any>("UTC+0");
defineExpose({
  newTimeFn,
  tableDate,
  timeZoneDetail,
  repeatWeek,
  timeStart,
  timeEnd
});
const timeZoneD = [...Array(12)].map((_, i) => ({
  value: `UTC+${i + 1}`,
  label: `UTC+${i + 1}`
}));
const timeZoneX = [...Array(12)].map((_, i) => ({
  value: `UTC-${i + 1}`,
  label: `UTC-${i + 1}`
}));
const timeUtc = [{
  value: `UTC+0`,
  label: `UTC+0`
}] 
const timeZone = ref<any>([...timeUtc, ...timeZoneD, ...timeZoneX]);
const timeListArr = [...Array(23)].map((_, i) => ({
  value: i + 1,
  label: i >= 9 ? String(i + 1) + ":00" : "0" + (i + 1) + ":00"
}));
const startTimeList = [
  {
    value: 0,
    label: "00:00"
  },
  ...timeListArr
];
const endTimeList = [
  ...timeListArr,
  {
    value: 24,
    label: "24:00"
  }
];

const columns = reactive<any[]>([
  { title: t("onlineObj.weekday"), dataIndex: "week", key: "week" },
  { title: t("onlineObj.date"), dataIndex: "startDate", key: "startDate" },
  {
    title: t("onlineObj.runningHours"),
    prop: "startTime",
    dataIndex: "startTime",
    key: "startTime"
  },
  { title: t("onlineObj.ccu"), prop: "quantity", key: "quantity", dataIndex: "quantity" , width:30 },
  { title: 'Time Zone', prop: "timeZone", key: "timeZone", dataIndex: "timeZone" }
]);

const columns2 = reactive<any[]>([
  { title: t("onlineObj.weekday"), dataIndex: "week", key: "week" },
  { title: t("onlineObj.date"), dataIndex: "startDate", key: "startDate" },
  {
    title: t("onlineObj.runningHours"),
    prop: "startTime",
    dataIndex: "startTime",
    key: "startTime",
    txt: t('onlineObj.plan')
  },
  {
    title: t("onlineObj.runningHours"),
    prop: "realStartTime",
    dataIndex: "realStartTime",
    key: "realStartTime",
    txt: t('onlineObj.real')
  },
  { title: t("onlineObj.ccu"), prop: "quantity", key: "quantity", dataIndex: "quantity" , width:30 ,txt: t('onlineObj.plan')},
  { title: t("onlineObj.ccu"), prop: "realQuantity", key: "realQuantity", dataIndex: "realQuantity" , width:30 ,txt: t('onlineObj.real')},
  { title: 'Time Zone', prop: "timeZone", key: "timeZone", dataIndex: "timeZone" }
]);
watch(()=>repeatWeek.value,()=>{
    changeFn()
})
</script>
<style lang="sass" scoped>
.select-div-hh
    line-height: 40px
    padding: 0 6px
:deep(.ant-input-number .ant-input-number-input)
    text-align: center
:deep(.ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody >tr:last-child>td)
    border-bottom: none !important
.icon-sty
    cursor: pointer
    fill:#124EF4
    &:hover svg 
        fill:#124EF4
        path
          fill:#124EF4
    &:hover rect
        fill:#124EF4
rect
    fill:#124EF4
svg 
    fill:#124EF4
.alink-txt
    cursor: pointer
    color: #FF7D00
</style>

