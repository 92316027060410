import { useRequest } from '@/hooks/useRequest'
import Api from "@/common/api"

export const getCountPageList = (params?: Partial<any>) => {
    return useRequest<Partial<any>, any>(Api.countMange.getCountPageList, 'post', params || {}, { manual: true })
}
export const addCount = (params?: Partial<any>) => {
    return useRequest<Partial<any>, any>(Api.countMange.addCount, 'post', params || {}, { canCustomHandle:true,manual: true })
}
export const open_prohibitCount = (params?: Partial<any>) => {
    return useRequest<Partial<any>, any>(Api.countMange.open_prohibitCount, 'post', params || {}, { manual: true })
}
export const resetPassword = (params?: Partial<any>) => {
    return useRequest<Partial<any>, any>(Api.countMange.resetPassword, 'post', params || {}, { canCustomHandle:true,manual: true })
}
export const copyPassword = (params?: Partial<any>) => {
    return useRequest<Partial<any>, any>(Api.countMange.copyPassword, 'post', params || {}, { manual: true })
}
export const sendAccoutEmail = (params?: Partial<any>) => {
  return useRequest<Partial<any>, any>(Api.countMange.sendAccoutEmail, 'post', params || {}, { canCustomHandle:true,manual: true })
}
