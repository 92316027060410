<template lang="pug">
div.account-manger(v-if="isReady")
    .flex.mb-5.items-center.searchBox.flex-wrap.justify-between
        div.flex.gap-4
            a-input(v-model:value="formStateParams.id" class="!w-40" :placeholder="formStateHeader.id.placeholder" allowClear)
            a-input(v-model:value="formStateParams.username" class="!w-40" :placeholder="formStateHeader.username.placeholder" allowClear)
            a-input(v-model:value="formStateParams.userTel" class="!w-40" :placeholder="formStateHeader.userTel.placeholder" allowClear)
            a-select(v-model:value="formStateParams.userStatus" class="!w-40" :placeholder="formStateHeader.userStatus.placeholder" :options="[ { label: t('userCenter.accountManage.normal'), value: 1 },{ label: t('userCenter.accountManage.forbidden'), value: 0 }]"
allowClear)
            a-button(type="primary" @click="submmit()").ml-2 {{ $t('common.search') }}
            a-button(type="default" @click="reset()" ).ml-2 {{ $t('common.reset') }}
        a-button(type="primary" @click="addBox").mr-4 {{ $t('userCenter.accountManage.creatAccount') }}
    w-biz-table(
        :data="data?.list"
        :loading="loading"
        :columns="columns"
        :scroll="{ x: 1000 }"
        :pagination="pagenationParams"
        @change="sizeChange"
    )
        template(#emptyText) 
            .py-10.txt-third-color {{ $t('v2.action.emptyData') }}
        template(#operate="{ record }")
            a-button(v-if="record.userStatus === 0" type="link" @click="openProhibitCount(record)").mr-1 {{ $t('userCenter.accountManage.open') }}
            a-button(v-if="record.userStatus === 1" type="link" @click="openProhibitCount(record)").mr-1 {{ $t('userCenter.accountManage.forbidden') }}
            a-button(type="link" @click="resetPasswordHandeler(record)").mr-1 {{ $t('userCenter.accountManage.resetPassword') }}
    w-modal(v-model="drawer" :title="`${t('userCenter.accountManage.creatAccount')}`" :footer="{ okTxt: t('common.confirm'), cancelTxt: t('common.cancel')}" @on-ok="confirm")
        .pr-12
            w-form(ref="form2" :formState="formState1" :footer="null").form2
    w-modal#addCountCopy(
        v-model="drawerAddCount"
        :title="title" width="492px" :footer="false"
        @on-ok="confirmAddSuccess"
        @on-cancel="drawerAddCount=false"
    )
        .textShow.text-base
            #reset-username.mb-1 {{ $t('userCenter.accountManage.username') }}:{{ usernameCopy }}
            #reset-password {{ $t('userCenter.accountManage.password') }}:{{ passwordCopy }}
        .reset-title.flex.justify-center
            a-button.requestEmail(@click="requestEmailFn()") {{ $t('userCenter.accountManage.requestEmail') }}
            a-button.copyButton(data-clipboard-target=".box" @click="copyHandler()") {{ $t('userCenter.accountManage.copy') }}
            input(class="box" :value="copyStr" type="hidden")
</template>
<script setup lang="ts">

import { Confirm, Toast } from '@welink/design';
import { useI18n } from 'vue-i18n'
import { getCountPageList, addCount, open_prohibitCount, resetPassword, copyPassword, sendAccoutEmail } from "@/service/userCenter/countManage"
import { router } from '@/routers'
import wlTips from "@/components/tips.vue"
import Clipboard from 'clipboard'
import { ref } from 'vue'
import { onMounted } from 'vue'
import { isForeign } from '@/common/constants/env'
import { regUserName, regEmail } from '@/utils/validate';

const { t } = useI18n()
const pageList = ref()
const formHeader = ref<any>()
const isReady = ref(false)
const title = ref<any>()
const addUserId = ref('')
// 分页列表
const pagenationParams = ref({
    pageNo: 1,
    pageSize: 10,
    current: 1,
    size: 10,
    total:0
})
const fillPagination = () => {
    pagenationParams.value.total = data.value.total
    pagenationParams.value.current = data.value.current
    pagenationParams.value.size = data.value.size
}
// 定义添加账户的form
const formState1 = isForeign ? {
    username: { label: t('userCenter.accountManage.username'), value: '', rules: { required: t('common.not_empty'), regex: regUserName }, placeholder: t('userCenter.accountManage.usernameplacehoulder') },
    userEmail: { label: t('userCenter.accountManage.email'), value: '', rules: { required: t('common.not_empty'), regex: regEmail }, placeholder: t('userCenter.accountManage.emailplacehoulder') },
} : {
    username: { label: '账号', value: '', rules: { required: t('common.not_empty'), regex: regUserName }, },
}

// 定义页面查询条件的form
const formStateParams = ref({
    id: '',
    username: "",
    userTel: "",
    userStatus: ''

})
const formStateHeader = reactive<any>({
    id: { label: t('userCenter.accountManage.userID'), value: '', hideLabel: true, rules: { required: false }, placeholder: t('userCenter.accountManage.inputId') },
    username: { label: t('userCenter.accountManage.username'), value: '', hideLabel: true, rules: { required: false }, placeholder: t('userCenter.accountManage.inputUsername') },
    userTel: { label: t('userCenter.accountManage.phone'), value: '', hideLabel: true, rules: { required: false }, placeholder: t('userCenter.accountManage.inputPhone') },
    userStatus: {
        label: t('userCenter.accountManage.accountStatus'), value: '', hideLabel: true, allowClear: true, placeholder: t('userCenter.accountManage.inputStatus'), type: 'select',
        rules: { required: false },
        selectOption: {
            allowSearch: true,
            allowClear: true,
            selectedLabel: 'nameDisplay',
            options: [
                { label: t('userCenter.accountManage.normal'), value: 1 },
                { label: t('userCenter.accountManage.forbidden'), value: 0 }
            ],

        }
    },

})

// 点击查询按钮
const submmit = async () => {
    pagenationParams.value.pageNo = 1
    pagenationParams.value.current = 1
    await run({ ...pagenationParams.value, ...formStateParams.value })
    fillPagination()
}
const reset = async () => {
    formStateParams.value.id = ''
    formStateParams.value.userTel = ''
    formStateParams.value.username = ''
    formStateParams.value.userStatus = ''
    pagenationParams.value.pageNo = 1
    pagenationParams.value.pageSize = 10
    await run({ ...pagenationParams.value,...formStateParams.value })
    fillPagination()
}

// 获取列表
const { data, run, loading } = getCountPageList({})
const form2 = ref<any>()



// 启用禁用账号
const openProhibitCount = (row: any) => {
    Confirm({
        title: row.userStatus === 0 ? t('userCenter.accountManage.isOpen') : t('userCenter.accountManage.isForbidden'),
        okTxt: t('common.confirm'),
        cancelTxt: t('common.cancel'),
        onOk() {
            const { run: runChangeStatus } = open_prohibitCount({})
            const userStatus = row.userStatus === 0 ? 1 : 0
            const parames = {
                userStatus: userStatus,
                userEmail: row.userEmail,
                username: row.username,
                businessNo: '',
                password: ''
            }
            runChangeStatus(parames).then(res => {
                // row.userStatus = 0
                run()
            })
        },
    })
}

// 发送邮件
const requestEmailFn = () => {
    const { data, run: runSave } = sendAccoutEmail({})
    runSave({ password: passwordCopy.value, userName: addUserId.value}).then(async (res: any) => {
        if (data.value.data.code === 0){
            Toast.success(t('userCenter.accountManage.operateSucess'))
            drawerAddCount.value = false
        }
    })
}

//  重置密码
const resetPasswordHandeler = (row: any) => {
    Confirm({
        title: t('userCenter.accountManage.isResetPassword'),
        okTxt: t('common.confirm'),
        cancelTxt: t('common.cancel'),
        async onOk() {
            const { data: resetData, run } = resetPassword({})
            const { data, run: runCopy } = copyPassword({})

            const parames = {
                userStatus: row.userStatus,
                userEmail: row.userEmail,
                username: row.username,
                businessNo: '',
                password: ''
            }
            run(parames).then(async res => {
                // addResetBox()
                if (resetData.value.data.code === 0) {
                    await runCopy({ ...row })
                    usernameCopy.value = data.value.username
                    passwordCopy.value = data.value.password
                    drawerAddCount.value = true
                    title.value = t('userCenter.accountManage.resetPasswordSucess')
                    Toast.success(t('userCenter.accountManage.operateSucess'))
                }
                console.log(111)
            })
        },
    })
}
// 复制密码
const copyBox = async (row: any) => {
    const a = document.createElement("input")
    const { data, run } = copyPassword({})
    await run({ ...row })
    console.log(data.value)
    const values = {
        '账号': data.value.username,
        '密码': data.value.password
    }
    a.value = JSON.stringify(values)
    document.body.appendChild(a);
    a.select(); //
    document.execCommand("copy");

    Toast.success(t('userCenter.accountManage.copySucess'))
    // 复制成功后再将构造的标签 移除
    document.body.removeChild(a);
}
// 确认添加账号
const usernameCopy = ref('')
const passwordCopy = ref('')
const confirm = () => {
    const { data, run: runSave } = addCount({})
    form2.value?.validate().then((valid: any) => {
        console.log(valid)
        if (valid.valid) {
            runSave({ ...form2.value.values }).then(async (res: any) => {
                if (data.value.data.code === 0) {
                    usernameCopy.value = data.value.data.data.username
                    passwordCopy.value = data.value.data.data.password
                    addUserId.value = data.value.data.data.username
                    drawer.value = false
                    drawerAddCount.value = true
                    title.value = t('userCenter.accountManage.createSucess')
                    await run()
                    fillPagination()
                } else {
                    Toast.error(data.value.data.msg)
                }
            })
        }

    })

}

// 确认添加账号成功
const confirmAddSuccess = () => {
    drawerAddCount.value = false
}

// 点击复制新增账号的内容
const copyStr = ref('')
const copyHandler = () => {
    const usernameCopyValue = document.getElementById("reset-username")?.innerText
    const passwordCopyValue = document.getElementById("reset-password")?.innerText
    copyStr.value = usernameCopyValue + ' ' + passwordCopyValue
    const clipboard = new Clipboard(".copyButton")
    clipboard.on('success', e => {
        // e.clearSelection()
        console.log('copyStr11', copyStr.value)
        clipboard.destroy()
        Toast.success(t('userCenter.accountManage.copySucess'));
        drawerAddCount.value = false
    })
    // 复制失败
    clipboard.on('error', () => {
        // 释放内存
        console.log('copyStr22', copyStr.value)
        clipboard.destroy()
        Toast.error(t('userCenter.accountManage.copyFail'));
    })

}




const searchParams = ref({
    activeStatus: 0,
    authStatus: 0,
    authType: 1,
    id: '',
    userStatus: null,
    userTel: null,
    username: null,
})



const drawer = ref(false)
const drawerResetPassword = ref(false)
const drawerAddCount = ref(false)
const timmer = ref()
const changeParams = (current: number) => {
    pagenationParams.value.pageNo = current
    run({ ...pagenationParams.value, ...searchParams.value, ...formHeader.value.values })
}

const sizeChange = ({ current, pageSize}: any) => {
    pagenationParams.value.pageNo = current
    pagenationParams.value.pageSize = pageSize
    run({ ...pagenationParams.value, ...searchParams.value, ...formHeader.value.values })
}

const baseinfo = (params: any) => {
    router.push({ name: "baseinfo", params: { envid: params.environmentId, appId: params.id } })
}

//  定义表格
const columns = ref<any>([
    {
        title: t("countList.table.theader.userId"),
        prop: 'id',
        width: 130
    },
    {
        title: t("countList.table.theader.userName"),
        prop: 'username',
        width: 150
    },
    {
        title: t("countList.table.theader.email"),
        prop: 'userEmail',
        key: 'userEmail',
        width: 200
    },
    {
        title: t("countList.table.theader.telNumer"),
        prop: 'userTel',
        width: 150
    },
    {
        title: t("countList.table.theader.type"),
        prop: 'userTypeDesc',
        width: 200
    },
    {
        title: t("countList.table.theader.CountState"),
        prop: 'userStatusDesc',
        width: 130
    },
    {
        title: t("countList.table.theader.isActive"),
        prop: 'activeStatusDesc',
        width: 200
    },
    {
        title: t("countList.table.theader.updateTime"),
        prop: 'updateTime',
        width: 250
    },
    {
        title: t("countList.table.theader.updateUser"),
        prop: 'updateUser',
        width: 130
    },
    {
        title: t("countList.table.theader.registerTime"),
        prop: 'registerTime',
        width: 250
    },
    {
        title: t("countList.table.theader.lastLoginTime"),
        prop: 'lastLoginTime',
        width: 180
    },
    {
        title: t("countList.table.theader.operate"),
        prop: 'operate',
        width: 250,
        fixed: "right"
    },

])

// 点击新增账号的box
const addBox = function (data: any) {
    timmer.value = new Date().getTime()
    drawer.value = true

}


const addResetBox = function () {
    timmer.value = new Date().getTime()
    drawerResetPassword.value = true

}
onMounted(async () => {
    await run({ ...pagenationParams.value, ...searchParams.value }).then(res => {
        console.log('data.value.list', data.value)
        fillPagination()
    })

    isReady.value = true
})

</script>
<style lang="sass" scoped>
body
    overflow-y: scroll
:deep .searchBox 
    .wl-form_field_widget
        max-width:240px !important
.account-manger
    :deep(.w-select)
        width: 240px
    .wl-form
        margin-bottom: 0
.search-action
    display: flex
    align-items: center
.advanced
    box-sizing: border-box
    margin-right: 20px
    display: flex
    align-items: center
.advanced-active
    color: #4779F6 !important
.search-advanced
    padding-top: 20px
    padding-left: 20px
    min-height: 100px
    background: rgba(239,244,255,0.5)
    display: flex
    flex-wrap: wrap
    align-items: center
    border:1px solid #E7E7E7
    box-shadow: 2px 0px 0px 0px #E7E7E7
    display: none
    .search-item
        margin-bottom: 20px
.search-advanced-active
    padding-top: 20px
    padding-left: 20px
    min-height: 100px
    background: rgba(239,244,255,0.5)
    display: flex
    flex-wrap: wrap
    align-items: center
    border:1px solid #E7E7E7
    box-shadow: 2px 0px 0px 0px #E7E7E7
    .search-item
        margin-bottom: 20px
    position: relative
    margin-right: 20px

.search-button
    margin-left: 100px
.selectHeader
    display: inline-block
    display: flex
//     justify-content: space-between
.search-item
    display: flex
    align-items: center
    .search-label
        min-width: 76px
        margin-right: 10px
        text-align: right
.resetBox
    width: 100%
    display: flex
    // flex-direction: column
    padding: 20px
    justify-content: space-evenly
.reset-title
      display: flex
      justify-content: center
      padding-left: 30px
      padding-right: 30px
      width: 100%
:deep .wl-modal_footer
    justify-content: flex-end
.flex-box
    display: flex
    // align-items: center
.wl-link
    display: block
    min-width: 80px
    color: #4779F6
    cursor: pointer
.wl-link:hover
    opacity: 0.6
.form2
    label
        min-width: 120px
:deep .wl-form.wl-form_row .wl-form_field .wl-form_label
    min-width: 120px
.requestEmail
    color: #ffffff
    border-color: #1C64F2
    opacity: .8
    background-color: #1C64F2
    margin-right: 40px
// .content
//     display: flex
//     padding:20px
//     justify-content: start
//     // overflow: auto
</style>
