<template lang="pug">
div
    a-alert(
        :message="$t('v2.gameUploadTool.alertTitle')"
        type="info"
        show-icon
        :closable="closable"
        class="!p-4 !border-none text-base rounded-lg !mb-4"
    )
        template(#icon)
            div
                // eslint-disable-next-line vue-pug/no-parsing-error
                <svg class="mt-1 inline-block middle" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.33325 8.00004C1.33325 4.31814 4.31802 1.33337 7.99992 1.33337C11.6818 1.33337 14.6666 4.31814 14.6666 8.00004C14.6666 11.6819 11.6818 14.6667 7.99992 14.6667C4.31802 14.6667 1.33325 11.6819 1.33325 8.00004ZM8.66659 6.00004V4.66671H7.33325V6.00004H8.66659ZM7.33325 6.66671V11.3334H8.66659V6.66671H7.33325Z" fill="#165DFF"/></svg>
        template(#description)
            .txt-second-color
                | {{ $t('v2.gameUploadTool.intro1') }}
                span.text-main-color.mx-1.cursor-pointer(@click="download()") {{ $t('v2.gameUploadTool.download') }}
                | {{ $t('v2.gameUploadTool.intro2') }}
    a-collapse(ghost expandIconPosition="end"  :activeKey="activeKey").collapse-base-info
        a-collapse-panel(key="1" :showArrow="false")
            template(#header)
                .text-xl {{ $t('v2.gameUploadTool.baseTitle') }}
                .txt-second-color.mb-4.text-sm {{ $t('v2.gameUploadTool.baseDesc') }}
            div(class="bg-[#F7F8FA] rounded-lg p-4")
                .txt-second-color.mb-1.text-sm(v-if="projectInfo.appName") {{ $t('v2.gameUploadTool.projectName') }}
                .txt-color.text-base.mb-4(v-if="projectInfo.appName") {{ projectInfo.appName }}
                .txt-second-color.mb-1.text-sm {{ $t('v2.gameUploadTool.key') }}
                .txt-color.text-base.mb-4
                    .show.flex.items-center(
                        v-if="!keyShow"
                    )
                        | ************
                        <svg  @click="keyShow = true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ml-8 w-4 h-4"><path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" /><path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                        <svg @click="copyText(projectInfo.key)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-4 h-4 ml-2 cursor-pointer"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" /></svg>
                    .flex.items-center(v-if="keyShow" )
                        | {{ projectInfo.key }}
                        <svg @click="keyShow = false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ml-8  w-4 h-4  cursor-pointer"><path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" /></svg>
                        <svg @click="copyText(projectInfo.key)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-4 h-4 ml-2 cursor-pointer"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" /></svg>
                .txt-second-color.mb-1.text-sm {{ $t('v2.gameUploadTool.secret') }}
                .txt-color.text-base.mb-4
                    .show.flex.items-center(
                        v-if="!secretShow"
                    )
                        | ************
                        <svg @click="secretShow = true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ml-8  w-4 h-4 cursor-pointer"><path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" /><path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                        <svg @click="copyText(projectInfo.secret)"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-4 h-4 ml-2 cursor-pointer"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" /></svg>
                    .real.flex.items-center(v-if="secretShow")
                        span.truncate(class="w-115") {{ projectInfo.secret }}
                        <svg @click="secretShow = false"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ml-8 w-4 h-4 cursor-pointer"><path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" /></svg>
                        <svg @click="copyText(projectInfo.secret)"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-4 h-4 ml-2 cursor-pointer"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" /></svg>
</template>

<script lang="ts" setup>
import API from '@/common/api/console/v2/home'
import { UPLOAD_TOOLS_URL } from '@/common/constants/url'
import { isForeign, VITE_ENV  } from '@/common/constants/env';
import { getLocale } from '@/utils/sso'
import { copyText } from '@/utils/copyText'
import { useGlobalStore } from "@/store/global";

const props = withDefaults(defineProps<{
    closable?: boolean,
    openInfo?: boolean,
    projectId?: string,
}>(), {
    closable: true,
    openInfo: false,
    projectId: ''
})

const store = useGlobalStore()
const keyShow = ref(false)
const secretShow = ref(false)
const activeKey = ref('1')
const route = useRoute()

const projectInfo = reactive({
    appName: route.query.pname,
    key: '',
    secret: ''
})

const download = () => {
    const platform = navigator.userAgent.toLowerCase();
    const isMac = platform.includes('mac')
    const locale = getLocale()
    let downloadUrl = VITE_ENV ? UPLOAD_TOOLS_URL[VITE_ENV||'prod'].mac : UPLOAD_TOOLS_URL.mac
    if (!isMac) {
        downloadUrl = VITE_ENV ? UPLOAD_TOOLS_URL[VITE_ENV||'prod'].win : UPLOAD_TOOLS_URL.win
    }
    window.open(downloadUrl)
}

const getProjectInfo = async ({ label }: any = {}) => {
    try {
        if (store.envId) {
            const envRes: any = await API.getEnvInfo(store.envId as string)
            projectInfo.key = store.envId
            projectInfo.secret = envRes.accessSecret
        }
        projectInfo.appName = label
        if (route.params.projectId) {
            const projectRes: any = await API.getProjectInfo(route.params.projectId as string)
            projectInfo.appName = projectRes.applicationName
        }
    } catch(e) {
    }
}

defineExpose({
    getProjectInfo
})

onMounted(getProjectInfo)
</script>