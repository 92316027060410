<template lang="pug">
.console-main-process
    .inner.flex.justify-center.py-12.px-4.pt-16
        .w-170.flex
            .select-game.flex-1
                template(v-if="!projectId")
                    .label.txt-color.mb-3
                        span.mr-2.txt-second-color 1.
                        | {{ $t('v2.gameList.choose') }}
                    a-select.w-full.text-xs(
                        v-model:value="currentProjectId"
                        :placeholder="$t('v2.resourceList.selectProject')"
                        class="!pl-6 !mb-8"
                        :options="projectList"
                        allowClear
                    )
                .mb-6
                    .label.txt-color.mb-3
                        span.mr-2.txt-second-color(v-if="!projectId") 2.
                        | {{ $t('v2.gameList.create') }}
                    .flex-1(:class="{'!pl-6': !projectId}")
                        a-input(v-model:value="gameInfo.name" :placeholder="$t('v2.resourceList.selectPackage')")
                        div.p-t2
                        a-input(v-model:value="gameInfo.packageVersion" :disabled="saveGame" :placeholder="$t('v2.gameList.packageVersion')")
                div(:class="{'!pl-6': !projectId}")
                    game-upload-tip(ref="gameUploadTipRef")
                    .flex.justify-end.mt-8
                        a-button(:loading="createGameLoading" :disabled="!canSubmit" type="primary" @click="createGame" ) {{ $t('v2.action.save') }}
</template>

<script lang="ts" setup>
import API from '@/common/api/console/v2/home'
import gameUploadTip from '@/views/console/v2/components/gameUploadTip.vue'
import { Toast } from '@welink/design';
import { useI18n } from 'vue-i18n';
import { getUserId, getUserUid} from "@/utils/sso"

const { t } = useI18n()

const gameUploadTipRef = ref()
const createGameLoading = ref(false)
const route = useRoute()
const projectList = ref([])
const projectId = computed(() => route.params.projectId)
const currentProjectId = ref<any>(route.params.projectId || null)

const canSubmit = computed(() => {
    return currentProjectId.value && gameInfo.name.trim() && gameInfo.packageVersion.trim()
})

const gameInfo = reactive({
    name: '',
    id: '',
    packageVersion: ''
})

const createGame = async () => {
    createGameLoading.value = true
    API.createGame(gameInfo.packageVersion, gameInfo.name, currentProjectId.value, getUserUid()).then((data: any) => {
        createGameLoading.value = false
        Toast.success(t('v2.gameList.createSuccess'))
        window.history.back()
    }).catch(() => {
        createGameLoading.value = false
    })
}

const fetchProjectList = async () => {
    const res: any = await API.getProjectSelectList()
    if (res) {
        projectList.value = res.map((item: any) => ({ label: item.applicationName, value: item.id }))
    }
}

watch(currentProjectId, (v: string) => {
    const project = projectList.value.find((item: any) => item.value === v) || {}
    gameUploadTipRef.value.getProjectInfo(project)
})


onBeforeMount(() => {
    if (!projectId.value) {
        fetchProjectList()
    }
})

</script>