<!-- eslint-disable vue/eqeqeq -->
<!-- eslint-disable array-callback-return -->
<template lang="pug">
h1.console-title.pl-6.pt-2
    span(class="text-[#86909C]")
        router-link(:to="{ name: 'projectList' }") {{ $t('v2.projectList.title') }}
        span.mx-1 /
        span(@click="goBack").cursor-pointer {{ gameInfo.applicationName }}
        span.mx-1 /
        | {{ gameInfo.packageName }}
        span.mx-1 /
        span {{ $t('v2.gameList.plan') }}
h1.console-title.pl-6.pt-2
    span(class="text-[#86909C]")
        span.p-2.btn-tit {{ $t('consoleMenu.region') }} : 
        span(v-for="(item,i) in unpurchaseNode" :key="item.nodeId" :class="i === unpurchaseNodeNum? 'plan-act' : '' " @click="checkNodeFn(i)").btn-span.ml-3.cur-text {{ item.nodeName }}
        span.btn-span.add-icon.ml-3.cur-text( @click="addReginFn" ) +
.bg-white.p-6.rounded-lg.min-h-full(class="mt-[14px] !pr-10")
    a-spin(size="large" :spinning="loading" :tip="$t('v2.action.loading') + '...'")
        .resource
            .flex.justify-between
                div.flex
                    .text-base.txt-third-color.font-bold.mb-5.cur-text( :class="activePlan==1?'active-cla':''" @click="checkPlanFn(1)")  {{ $t('consoleMenu.resourceList') }}
                    .text-base.txt-third-color.font-bold.mb-5.ml-6.cur-text( :class="activePlan==0?'active-cla':''" @click="checkPlanFn(0)") {{ $t('consoleMenu.onDemand') }}
                a-button( v-if="activePlan === 1" class="!font-bold" size="small" @click="openAddFn") {{ $t('v2.gameList.add') }}
            section(v-show="activePlan == 1")
                rescource-table( ref="rescourceTableDom" :resourceDetailId="unpurchaseNode[unpurchaseNodeNum]?.resourceDetailId" :nodeId="unpurchaseNode[unpurchaseNodeNum]?.nodeId" :gameInfo="gameInfo"
@add-resource-fn="addResourceFn" @open-add-fn="openAddFn")
            section(v-show="activePlan == 0")
                schedule( ref="onlineTableDom" :unpurchaseNodeObj="unpurchaseNode[unpurchaseNodeNum]" :unpurchaseNodeNum="unpurchaseNodeNum" :resourceDetailId="unpurchaseNode[unpurchaseNodeNum]?.resourceDetailId"
:nodeId="unpurchaseNode[unpurchaseNodeNum]?.nodeId" :gameInfo="gameInfo" @add-onlinetabel-fn="addOnlinetabelFn" @check-node-fn="checkNodeFn" @open-first-fn="openFirstFn")
                //- online-table( @addOnlinetabelFn="addOnlinetabelFn" @checkNodeFn="checkNodeFn" @openFirstFn="openFirstFn" :unpurchaseNodeNum="unpurchaseNodeNum" :resourceDetailId="unpurchaseNode[unpurchaseNodeNum]?.resourceDetailId" :nodeId="unpurchaseNode[unpurchaseNodeNum]?.nodeId" :gameInfo="gameInfo" ref="onlineTableDom")
a-modal(v-model:open="nodeVisible" width="410px" centered title="Add region"
@cancel="checkModelFn(false)").text-center.pb-10
    .mb-5 Select region
    a-space.mb-6.text-center(direction="vertical" style="width:100%;")
        a-select.text-center( v-model:value="addNodeList" mode="multiple" style="width: 369px" placeholder="please Select region"
:options="nodeOptions" @change="changeFn" )
    template(#footer)
        a-button( danger @click="checkModelFn(false)") Cancel
        a-button.v2-btn(type="primary" @click="addNodeFn()") Confirm
modelBox( v-if="modelBoxFa" ref="modelBoxDom" :resourceDetailId="unpurchaseNode[unpurchaseNodeNum]?.resourceDetailId" :nodeId="unpurchaseNode[unpurchaseNodeNum]?.nodeId"
:gameInfo="gameInfo" @sub-first-fn-data2="subFirstFnData2" @sub-first-fn-data3="subFirstFnData3" @sub-first-fn-data="subFirstFnData" @add-resource-fn="addResourceFn" @add-onlinetabel-fn="addOnlinetabelFn")
a-modal(v-model:open="successModel" :footer="null" :closable="false")
    a-result(status="success" title="Success!" sub-title="The game will go online as scheduled.")
        template( #extra)
            a-button.v2-btn.mt-8(@click="nextDataFn") Done
a-modal(v-model:open="warningModel" :footer="null" :closable="false")
    a-result(status="warning" title="Requested CCU Exceeds Quota" sub-title="Requested CCU exceeds your flexible quota. We are applying for additional quota. Detailed updates will be communicated via email.")
        template( #extra)
            a-button.v2-btn.mt-8(@click="warningModel = false") Confirm
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { getLocale, getToken, getUserId, getUserUid } from '@/utils/sso';
import { isForeign } from "@/common/constants/env"
import API from '@/common/api/console/v2/home'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { bytesToSize } from '@/utils/bytesConvert'
import { resourceStatusMap } from '@/views/console/v2/constants/index'
import { Toast, Confirm } from '@welink/design';
import dayjs from 'dayjs'
import { useGlobalStore } from '@/store/global'
// import { datasetModule } from 'snabbdom';
import RescourceTable from './components/resourceTable.vue'
import onlineTable from './components/onlineTableNew.vue'
import schedule from './components/schedule.vue'
import modelBox from './components/modelBox.vue'
import { formatData } from "@/utils/index"
import { message } from '@welink/design-v2'

// openResoureFn
const modelBoxFa = ref(false)
const warningModel = ref(false)
const nodeListLen = ref(0)
const fieldNames = ref({
    value: "nodeId",
    label: "nodeName",
    options: "options"
})
const successModel = ref(false)
const successModelFirst = ref(false)
const nodeVisible = ref(false)
const checkResource = ref<number>(0)
const store = useGlobalStore()
const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const loading = ref(true)
const gameVisible = ref(false)
const activePlan = ref(1)
const unpurchaseNode = ref<any[]>([])
const addNodeList = ref([])
const unpurchaseNodeNum = ref(0)
const onlineTableDom = ref(null)
const rescourceTableDom = ref<any>(null)
const modelBoxDom = ref<any>(null)
const nodeOptions = ref<any[]>([])
const paginationParams = ref({
    current: 1,
    size: 10,
    total: 0
})

provide('paramPropsNodeId', unpurchaseNode.value[unpurchaseNodeNum.value]?.nodeId)
provide('paramPropsResourceDetailId', unpurchaseNode.value[unpurchaseNodeNum.value]?.resourceDetailId)
type GameInfoType = {
  applicationId: string
  applicationName: string
  packageName: string
  packageVersion: string
  packageId: string;
  packageSize: number
  skuName: string;
  specfication: string
}
const gameInfo = ref<GameInfoType>({
    applicationId: '',
    applicationName: "",
    packageName: "",
    packageVersion: '0.0.0',
    packageId: '',
    packageSize: 0,
    skuName: '',
    specfication: '',
})
const openFirstFn = () => {
    modelBoxFa.value = true
    if(modelBoxDom.value){
        modelBoxDom.value.openBoxFn(1)
    }else{
        setTimeout(() => {
            modelBoxDom.value.openBoxFn(1)
        },60)
    }
}
const openAddFn = () => {
    if(unpurchaseNode.value[unpurchaseNodeNum.value]?.resourceDetailId){
        rescourceTableDom.value.openResoureFn()
    }else{
        openFirstFn()
    }
}

const checkModelFn = (e: boolean) => {
    nodeVisible.value = e
    if(!nodeVisible.value) addNodeList.value = []
}

const addNodeFn = () => {
    let obj = {}; const arrList: any[] = []
    
    // eslint-disable-next-line array-callback-return
    nodeOptions.value.map((key) => {
        // eslint-disable-next-line array-callback-return
        addNodeList.value.map((item) => {
            if(key.value === item){
                obj = {
                    nodeName:key.label,
                    nodeId:key.value,
                    resourceDetailId:''
                }
                arrList.push(obj)
            }
        })
    })
    const lenNum = unpurchaseNode.value.length
    unpurchaseNode.value = [...unpurchaseNode.value, ...arrList]
    checkNodeFn(lenNum)
    checkModelFn(false)
}
// open addRegin
const addReginFn = async () => {
    const nodeList: any = await API.getResourceNodeConfig(route.params.gameId as string)
    const arrList: any[] = []
    let obj = {}
    const indexOfStr = JSON.stringify(unpurchaseNode.value)
    if (nodeList && nodeList.length > 0) {
        // eslint-disable-next-line array-callback-return
        nodeList.map((item: { nodeId: string; nodeName: any; }) => {
            if(indexOfStr.indexOf(item.nodeId) < 0){
                obj = {
                    label:item.nodeName,
                    value:item.nodeId
                }
                arrList.push(obj)
            }
        })
    }
    // modelBoxFa.value
    nodeOptions.value = arrList
    checkModelFn(true)
}
const changeFn = (e: any) => {
    console.log(e)
}

// 
const getResourceNodeConfig = async (e: number | undefined = undefined) => {
    loading.value = true
    // const nodeList: any = await API.getResourceNodeConfig(route.params.gameId)
    const nodeList: any = await API.queryOnlineResourceNode(route.params.gameId as string)
    const gameInfoData: any = await API.getGameInfo(route.params.gameId as string)
    if (gameInfoData) {
        gameInfo.value = gameInfoData
    }
    // modelBoxFa.value = nodeList.length > 0 ? false : true
    nodeListLen.value = nodeList.length
    if(nodeList.length > 0){
        const arrList: any[] = []
        try {
            const REGION_LIST = JSON.parse(window.localStorage.getItem('REGION_LIST') as string)
            const REGION_Str = JSON.stringify(nodeList)
            if(REGION_LIST){
                // eslint-disable-next-line array-callback-return
                REGION_LIST.map((item: { nodeId: string; }) => {
                    if(REGION_Str.indexOf(item.nodeId) < 0){
                        arrList.push(item)
                    }
                })
            }
        } catch (error) {
            console.log(error)
        }
        unpurchaseNode.value = [...arrList, ...nodeList]
    }else{
        unpurchaseNode.value = JSON.parse(window.localStorage.getItem('REGION_LIST') as string)
    }
    const resourceDetailIdStr = route.params.resourceDetailId === '0' ? '' : route.params.resourceDetailId
    if(resourceDetailIdStr && e === 1 && unpurchaseNode && unpurchaseNode.value){
        // eslint-disable-next-line array-callback-return
        unpurchaseNode.value.map((item: any, index) => {
            if(item.resourceDetailId === resourceDetailIdStr){
                unpurchaseNodeNum.value = index
            }
        })
    }
    if(unpurchaseNode && unpurchaseNode.value && unpurchaseNode.value[unpurchaseNodeNum.value]?.resourceDetailId){
        modelBoxFa.value  = false
    }else{
        modelBoxFa.value  = true
    }
   
    const param = {
        applicationId: gameInfo.value.applicationId,
        nodeId: unpurchaseNode && unpurchaseNode.value  && unpurchaseNode.value[unpurchaseNodeNum.value]?.nodeId || '',
        packageId: gameInfo.value.packageId,
        userId: getUserUid(),
        resourceDetailId: resourceDetailIdStr || unpurchaseNode.value[unpurchaseNodeNum.value]?.resourceDetailId ,
    }
    rescourceTableDom.value?.onDemandListFn(param || {})
    loading.value = false
}

onMounted(() => {
    getResourceNodeConfig(1)
})
onUnmounted(() => {
    window.localStorage.removeItem('REGION_LIST')
})

// queryTestUnpurchaseNode
const getQueryTestUnpurchaseNode = () => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    API.queryOnlineResourceNode(route.params.gameId as string).then((data: any) => {})
}
const checkShowFn = () => {
    if(!unpurchaseNode.value.length) return
    const param = {
        applicationId: gameInfo.value.applicationId,
        nodeId: unpurchaseNode.value[unpurchaseNodeNum.value]?.nodeId || '',
        packageId: gameInfo.value.packageId,
        userId: getUserUid(),
        resourceDetailId: unpurchaseNode.value[unpurchaseNodeNum.value]?.resourceDetailId || '',
    }
    if(activePlan.value === 1){
        rescourceTableDom.value.onDemandListFn ? rescourceTableDom.value.onDemandListFn(param) : setTimeout(() => {rescourceTableDom.value.onDemandListFn(param)}, 50)
    }
    // else{
    //     onlineTableDom.value.onDemandListFn ? onlineTableDom.value.onDemandListFn(param) : setTimeout(()=>{onlineTableDom.value.onDemandListFn(param)}, 50)
    // }
}
const checkPlanFn = (i: number) => {
    if(activePlan.value === i) return 
    activePlan.value = i
    checkShowFn()
}
const checkNodeFn = (i: number, item:any = null) => {
    if(item){
        // eslint-disable-next-line array-callback-return
        unpurchaseNode.value.map((key,k) => {
            if(item.resourceDetailId === key.resourceDetailId){
                // eslint-disable-next-line no-param-reassign
                i = k
            }
        })
    }
    if(unpurchaseNodeNum.value === i) return 
    unpurchaseNodeNum.value = i
    checkShowFn()
    if(unpurchaseNode.value[i].resourceDetailId){
        modelBoxFa.value  = false
    }else{
        modelBoxFa.value  = true
    }
    console.log("unpurchaseNode.value[i]:::::", unpurchaseNode.value[i].nodeId)
}
// const checkNodeFn2 = (item) =>{
//     // console.log(item)
//     // console.log(unpurchaseNode.value)
//     // unpurchaseNode.value.map((key,i) => {
//     //     if(item.resourceDetailId == item.resourceDetailId){
//     //         unpurchaseNodeNum.value = i
//     //     }
//     // })
// }
// getQueryTestUnpurchaseNode()
const gameInfoList = ref<any>({
    resourceList: []
})
const playTip = ref('')
const goBack = () => {
    console.log(99999)
    router.back()
}
const columns = reactive<any[]>([
    { title: t('onlineObj.startDate'), dataIndex: 'nodeName', key: 'nodeName', txt: t('onlineObj.plan') },
    {
        title: t('onlineObj.startDate'),
        dataIndex: 'skuName', key: 'skuName',
        txt: t('onlineObj.real')
    },
    { title: t('onlineObj.endDate'), dataIndex: 'specfication', key: 'skuName', txt: t('onlineObj.plan')},
    { title: t('onlineObj.endDate'), dataIndex: 'createTime', key: 'createTime', txt: t('onlineObj.real') },
    { title: t('onlineObj.cycle'), dataIndex: 'quantity', key: 'quantity', txt: t('onlineObj.plan') },
    {
        title: t('onlineObj.ccu'),
        prop: 'extensionAmount',
        txt: t('onlineObj.plan')
    },
    { title: t('onlineObj.ccu'), dataIndex: 'status', key: 'status',txt: t('onlineObj.real') },
])

const deleteVisible = ref(false)
const deleteLoading = ref(false)
const currentNodeId = ref('')

const deleteHandleOk = () => {
    deleteLoading.value = true
    API.deleteGame(route.params.gameId as string).then(() => {
        deleteLoading.value = false
        deleteVisible.value = false
        Toast.success(t('v2.action.deleteSuccess'))
        window.history.back()
    }).catch(() => {
        deleteVisible.value = false
        deleteLoading.value = false
    })
}

const play = (nodeId?:string) => {
    // router.push({ name: 'powerTime', params: {
    //     projectId: 23434, 
    //     gameId: 700005,
    //     nodeId: 777
    // } })
    // gameVisible.value = true
    // if (nodeId) {
    //     currentNodeId.value = nodeId
    // } else {
    //     currentNodeId.value = ''
    // }
}

const goWebPreview = () => {
    const { gameId } = route.params
    const canPlayItem = gameInfoList.value.resourceList.find((item: any) => [3,4].includes(item.status))
    const nodeId = currentNodeId.value || canPlayItem.nodeId
    console.log('nodeId:', nodeId)
    router.push({ name: 'projectGamePreview', params: { 
        projectId: gameInfo.value.applicationId, 
        gameId,
        nodeId
    } })
}
const qrCodeLink = computed(() => {
    const { gameId } = route.params
    const canPlayItem = gameInfoList.value.resourceList.find((item: any) => [3,4].includes(item.status))
    const nodeId = currentNodeId.value || canPlayItem.nodeId
    const host = `${window.location.origin}/#/console/${gameInfo.value.applicationId}/${gameId}/${nodeId}/preview`
    return `${host}?lang=${isForeign ? getLocale() || 'en' : 'zh'}&loginToken=${getToken()}&envId=${store.envId}&isH5=1`
})
const nextDataFn = () => {
    successModel.value = false
    rescourceTableDom.value.cancelFn()
    modelBoxFa.value = false
    getResourceNodeConfig()
}
const subFirstFn = async (param: { applicationId?: any; nodeId?: any; packageId?: string | string[]; userId?: string | null; onDemand?: { cycleValue: any; resourceDetailId: any; items: any[]; } | { cycleValue: any; resourceDetailId: any; items: any; timeZone: any; }; timeZone?: any; releaseDate?: any; reserved?: any; }, i: number | undefined = undefined) => {
    if(param.reserved.quantity === 0){
        delete param.reserved
    }
    API.addFirstPlan(param).then((res) => {
        console.log("res:::::",res)
        if(res === 1 ){
            warningModel.value = true
        }else{
            successModel.value = true
        }
        modelBoxFa.value = false
        unpurchaseNodeNum.value = 0
        getResourceNodeConfig()
        modelBoxDom.value.openBoxFn(0)
    })
}
const addReservedPlanFn = (e: { cycleValue: any; planType: number; quantity: any; resourceDetailId: any; startDate: string; applicationId: string | string[]; nodeId: any; packageId: string | string[]; userId: string | null; }) => {
    API.addReservedPlan(e).then((data) => {
        if(data === 1 ){
            warningModel.value = true
        }else{
            message.success("Resource added successfully")
        }
        try{
            rescourceTableDom.value.cancelFn()
        }catch(e){
            console.log(e)
        }
        getResourceNodeConfig()
    })
}
// 按需方式下-新增或修改节点执行计划明细[新增] addOnDemandPlan
const addOnDemandPlanFn = (e: { cycleValue: any; resourceDetailId: any; items: any[]; }) => {
    API.addOnDemandPlan(e).then((data) => {
        message.success("Resource added successfully")
        getResourceNodeConfig()
    })
}
const addOnlinetabelFn = (e: any[], key: any,cyc: any) => {
    const itemsData: any[] = [];
    let obj = {};
    // eslint-disable-next-line array-callback-return
    e.map((item: { endDate: any; startDate: any; date: any; ccu: any; }) => {
        obj = {
            endTime: item.endDate,
            startTime: item.startDate,
            startDate:item.date,
            quantity: item.ccu
        }
        itemsData.push(obj)
    })
    const resourceDetailIdStr = route.params.resourceDetailId === '0' ? '' : route.params.resourceDetailId
    const param = {
        applicationId: gameInfo.value.applicationId,
        nodeId: unpurchaseNode.value[unpurchaseNodeNum.value]?.nodeId || '',
        packageId: route.params.gameId,
        userId: getUserUid(),
        onDemand:{
            cycleValue: cyc,
            resourceDetailId: unpurchaseNode.value[unpurchaseNodeNum.value]?.resourceDetailId || '',
            items: itemsData
        },
        timeZone: key
    }
    const onDemand: any = param.onDemand
    onDemand.timeZone = param.timeZone
    if(unpurchaseNode.value[unpurchaseNodeNum.value]?.resourceDetailId){
        addOnDemandPlanFn(onDemand)
    }else{
        subFirstFn(param)
    }
}

const subFirstFnData = (e: { releaseDate: any; cycleValue: any; planType: any; quantity: any; startDate: any; }) => {
    const param = {
        applicationId: route.params.projectId,
        nodeId: unpurchaseNode.value[unpurchaseNodeNum.value]?.nodeId || '',
        packageId: route.params.gameId,
        userId: getUserUid(),
        releaseDate: e.releaseDate,
        reserved:{
            cycleValue:e.cycleValue,
            planType:e.planType,
            quantity:e.quantity,
            resourceDetailId: unpurchaseNode.value[unpurchaseNodeNum.value]?.resourceDetailId || '',
            startDate: e.startDate
        }
    }
    subFirstFn(param)
}
const subFirstFnData2 = (res: { releaseDate: any; onDemand: { cycleValue: any; items: any; }; timeZone: any; }) => {
    const param = {
        applicationId: route.params.projectId,
        nodeId: unpurchaseNode.value[unpurchaseNodeNum.value]?.nodeId || '',
        packageId: route.params.gameId,
        userId: getUserUid(),
        releaseDate: res.releaseDate,
        onDemand:{
            cycleValue: res.onDemand.cycleValue,
            resourceDetailId: unpurchaseNode.value[unpurchaseNodeNum.value]?.resourceDetailId || '',
            items: res.onDemand.items,
            timeZone: res.timeZone
        }
    }
    subFirstFn(param, 1)
}
const subFirstFnData3 = (res: { releaseDate: any; onDemand: { cycleValue: any; items: any; }; timeZone: any; }, key: { cycleValue: any; planType: any; quantity: any; startDate: any; }) => {
    const param: any = {
        applicationId: route.params.projectId,
        nodeId: unpurchaseNode.value[unpurchaseNodeNum.value]?.nodeId || '',
        packageId: route.params.gameId,
        userId: getUserUid(),
        releaseDate: res.releaseDate,
        reserved:{
            cycleValue:key.cycleValue,
            planType:key.planType,
            quantity:key.quantity,
            resourceDetailId: unpurchaseNode.value[unpurchaseNodeNum.value]?.resourceDetailId || '',
            startDate: key.startDate
        }
    }
    if(res.onDemand){
        param.onDemand = {
            cycleValue: res.onDemand.cycleValue,
            resourceDetailId: unpurchaseNode.value[unpurchaseNodeNum.value]?.resourceDetailId || '',
            items: res.onDemand.items,
            timeZone: res.timeZone
        }
    }

    subFirstFn(param)
}

const addResourceFn = (e: { cycleValue: any; planType: any; quantity: any; startDate: any; }) => {
    const param = {
        applicationId: route.params.projectId,
        nodeId: unpurchaseNode.value[unpurchaseNodeNum.value]?.nodeId || '',
        packageId: route.params.gameId,
        userId: getUserUid(),
        reserved:{
            cycleValue:e.cycleValue,
            planType:Number(e.planType),
            quantity:e.quantity,
            resourceDetailId: unpurchaseNode.value[unpurchaseNodeNum.value]?.resourceDetailId || '',
            startDate: formatData(e.startDate),
            applicationId: route.params.projectId,
            nodeId: unpurchaseNode.value[unpurchaseNodeNum.value]?.nodeId || '',
            packageId: route.params.gameId,
            userId: getUserUid()
        }
    }
    addReservedPlanFn(param.reserved)
}
</script>

<style lang="sass" scoped>
.cover
    border: 1px solid #E5E6EB
    width: 270px
    height: 164px
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.74) 0%, rgba(255, 255, 255, 0.74) 100%), linear-gradient(285deg, #124EF4 -55.98%, #FEDFFF 105.41%)
    &:hover
        img
            width: 100px
            height: 100px
.info-flex-2
        flex: 2
.btn-tit
    padding-left: 0
.btn-span
    background: #ffffff
    border: 1px solid #f1f1f1
    padding: 3px 12px
    border-radius: 20px
.add-icon
    padding: 0px 4px
.empty-img
    display: inline-block
.active-cla
    color: rgba(0,0,0,.88)
    border-bottom: 2px solid rgba(0,0,0,.88)
.plan-act
    color:#ffffff
    background: #124EF4
    border-color: #9F83E5
</style>