<template lang="pug">
.console-main-process
    step(v-if="!isFromGame" :current="2")
    .inner.flex.justify-center.py-12
        step-back(v-if="!isFromGame")
        .flex-col
          a-alert(
            v-if="isUploadGame === 1"
            :message="$t('v2.mainProcess.s3.alertTitle')"
            type="info"
            show-icon
            :closable="closable"
            class="!p-4 !border-none text-base rounded-lg !mb-4 "
            :style="{ marginLeft: !isFromGame ? '40px' : '0' }"
          )
            template(#icon)
                div
                    // eslint-disable-next-line vue-pug/no-parsing-error
                    <svg class="mt-1 inline-block middle" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.33325 8.00004C1.33325 4.31814 4.31802 1.33337 7.99992 1.33337C11.6818 1.33337 14.6666 4.31814 14.6666 8.00004C14.6666 11.6819 11.6818 14.6667 7.99992 14.6667C4.31802 14.6667 1.33325 11.6819 1.33325 8.00004ZM8.66659 6.00004V4.66671H7.33325V6.00004H8.66659ZM7.33325 6.66671V11.3334H8.66659V6.66671H7.33325Z" fill="#165DFF"/></svg>
            template(#description)
                .txt-second-color {{ $t('v2.mainProcess.s3.alertDesc') }}
          .w-170.flex
              span.order(v-if="!isFromGame") 3.
              .select-game.flex-1
                  .label.txt-color {{ $t('v2.mainProcess.s3.title') }}
                  .text-xs.txt-second-color.mb-6 {{ $t('v2.mainProcess.s3.desc') }}
                  template(v-if="isUploadGame === 0")
                    .label.txt-color.mb-3 {{ $t('v2.mainProcess.s4.no.title') }}
                        game-upload-tip(:closable="false" openInfo)
                        .flex.justify-center.pt-4
                            a-button(type="primary" @click="fetchStatus") {{ $t('v2.mainProcess.s4.no.btn') }}
                  template(v-else)
                      template(v-if="loading")
                          .pt-40.flex.justify-center
                              a-spin(size="large" :spinning="loading" :tip="$t('v2.action.loading') + '...'")
                      template(v-else)
                          .relative
                              .app.flex.py-4.items-center.border-b
                                  .text-sm.text-gray-500.w-26 {{ $t('v2.mainProcess.s3.project') }}
                                  | {{ projectInfo.applicationName }}
                              .app.flex.py-4.items-center.border-b
                                  .text-sm.text-gray-500.w-26 {{ $t('v2.action.game') }}
                                  | {{ projectInfo.packageName }}
                              .app.flex.py-4.border-b.items-start
                                  .text-sm.text-gray-500.w-26.shrink-0 {{ $t('v2.mainProcess.s3.resource.title') }}
                                  //- .goods-desc.flex.flex-col.border.border-gray-300.border-dashed.p-4.rounded
                                      //- template(v-if="skuLoading")
                                      //-         a-spin(:spinning="skuLoading")
                                      //- template(v-else)
                                      //-     .content.w-full
                                      //-         span.pb-2(v-for="item in skuList" :key="item.skuName" class="w-1/2" :span="12")
                                      //-             .txt-color.mb-4 {{ item.skuName }}
                                      //-             .text-xs.txt-second-color.mb-4 {{ item.remark }}
                                      //-             .flex.justify-between
                                      //-                 .border.py-2.px-1.rounded.w-28.flex-shrink-0
                                      //-                     .text-xs.txt-second-color.mb-1.text-center {{ $t('v2.mainProcess.s3.resource.server') }}
                                      //-                     .txt.text-sm.text-center {{ item.operatingSystem }}
                                      //-                 .border.py-2.px-1.rounded.w-28.flex-shrink-0
                                      //-                     .text-xs.txt-second-color.mb-1.text-center {{ $t('v2.mainProcess.s3.resource.resolution') }}
                                      //-                     .txt.text-sm.text-center {{ item.resolution }}
                                      //-                 .border.py-2.px-1.rounded.w-28.flex-shrink-0
                                      //-                     .text-xs.txt-second-color.mb-1.text-center {{ $t('v2.mainProcess.s3.resource.rate') }}
                                      //-                     .txt.text-sm.text-center {{ item.fps }}
                                      //-                 .border.py-2.px-1.rounded.w-28.flex-shrink-0
                                      //-                     .text-xs.txt-second-color.mb-1.text-center {{ $t('v2.mainProcess.s3.resource.bitrate') }}
                                      //-                     .txt.text-sm.text-center {{ item.bitRate }}
                                  .flex.w-full.justify-between
                                    template(v-for="item in radioOptions" :key="item.label")
                                        div(style="color: gray;font-weight:400;") {{ item.label }}
                                          .flex.mt-2
                                              a-radio-group(v-model:value="item.value")
                                                  template(v-for="option in item.options" :key="option.value")
                                                      a-radio(:value="option.value" :disabled="!canEdit" style="color: gray;font-weight:400;") {{ option.label }}
                              .app.flex.py-4.items-center.border-b
                                  .text-sm.text-gray-500.w-26.flex-shrink-0 {{ $t('v2.mainProcess.s3.region') }}
                                  .flex.gap-4.flex-wrap
                                      template(v-for="(node) in nodeOptions" :key="node.value")
                                          a-button(:type="node.type" @click="addNode(node)")  {{ node.label }}
                                  //- a-select(v-model:value="nodeIds" label-in-value mode="multiple" size="sm" :placeholder="$t('v2.mainProcess.s3.region-select')" :options="nodeOptions").w-60
                              .app.flex.py-4.items-start.border-b(v-if="nodeIds.length")
                                  .text-sm.text-gray-500.w-26.pt-2.flex-shrink-0 {{ $t('v2.mainProcess.s3.nums') }}
                                  .w-full
                                      template(v-for="(item, index) in nodeIds" :key="index")
                                          .border-b.border-dashed.mb-5.pb-5(:class="{'!border-none !pb-0': nodeIds.length === (index + 1)}")
                                              .flex.items-start.w-full.justify-between
                                                  div.flex
                                                      span.w-24.select-none.text-left.txt-color.text-sm(class="pt-2 mr-3") {{ item.label }}
                                                      div
                                                          a-input-number(
                                                              v-model:value="item.quantity" :controls="false" min="1" :precision="0" autofocus
                                                              maxlength="5"
                                                              :status="item.quantity > item.maxQuantity ? 'error' : ''"
                                                          ).w-34.text-center
                                                              template(#addonBefore)
                                                                  span.action(:class="{ 'disabled' : item.quantity <= 1}" @click="item.quantity > 1 ? item.quantity-- : null") -
                                                              template(#addonAfter)
                                                                  span.action(@click="item.quantity++") +
                                                  div.flex.gap-5(style="visibility: hidden;")
                                                      .price.text-left
                                                          .text-xs.txt-second-color.mb-1 {{ $t('v2.mainProcess.s3.resource.price-unit') }}
                                                          span.text-xs.mr-1.txt-second-color US{{ VITE_UNIT }}
                                                          span.txt-color.text-xs {{ item.price }}
                                                          span.text-xs.txt-second-color  /{{ $t('v2.mainProcess.s3.resource.hour') }}
                                                      .price.text-left.w-31.flex-shrink-0
                                                          .text-xs.txt-second-color.mb-1 {{ $t('v2.mainProcess.s3.resource.price') }}
                                                          span.text-xs.mr-1.txt-second-color US{{ VITE_UNIT }}
                                                          span.txt-color.text-xs {{ (item.quantity * item.price * 100/100).toFixed(2) }}
                                                          span.text-xs.txt-second-color  /{{ $t('v2.mainProcess.s3.resource.hour') }}
                                              .text-red-500.text-xs.pt-1.pl-27(v-if="item.quantity > item.maxQuantity")
                                                  | {{ $t('v2.mainProcess.s3.resource.quantityTip') }}: {{ item.maxQuantity }}.
                              .app.flex.py-4.items-center.border-b.justify-end.mb-6(style="display: none;")
                                  .flex.items-end.txt-color.font-bold
                                      .text-xs {{ $t('v2.mainProcess.s3.price') }} US{{ VITE_UNIT }}：
                                      .span.font-semibold
                                          .font-bold.txt-color {{ totalPrice || '-' }}
                                      span.ml-1.txt-second-color.text-xs  /{{ $t('v2.mainProcess.s3.resource.hour') }}
                              .app.flex.items-center.justify-end.mt-6
                                  a-button(type="primary" :loading="buyLoading" :disabled="!canSubmit" @click="onSubmit").font-medium
                                      | {{ $t('v2.action.buy') }}
</template>

<script lang="ts" setup>
import API from '@/common/api/console/v2/home';
import { VITE_UNIT } from "@/common/constants/env";
import Step from './components/step.vue'
import StepBack from '@/views/console/v2/components/stepBack.vue'
import { useI18n } from 'vue-i18n';
import gameUploadTip from '@/views/console/v2/components/gameUploadTip.vue';

const { t } = useI18n()
const skuList = ref<any[]>([])
const loading = ref(false)
const buyLoading = ref(false)
const skuLoading = ref(false)
const totalPrice = ref('')
const route = useRoute()
const router = useRouter()
const nodeIds = ref<any>([])
const nodeOptions = ref()
const unitName = ref('')
const serverOsValue = ref(t('v2.mainProcess.s3.resource.serverOsOption.windows'))
const resolutionValue = ref(t('v2.mainProcess.s4.form.item3.v1'))
const bitrateValue = ref(t('v2.mainProcess.s4.form.item4.v1'))
const canEdit = ref(true)
const isUploadGame = ref(0)

const isFromGame = computed(() => route.query.fromGame)
const projectInfo = ref({
    applicationName: '',
    packageName: ''
})
const canSubmit = computed(() => (nodeIds.value.length ? nodeIds.value.every((item: any) => item.quantity && item.quantity <= item.maxQuantity) : false) && resolutionValue.value.length > 0 &&  bitrateValue.value.length > 0)

const radioOptions = reactive([
    // {
    //     label: t('v2.mainProcess.s3.resource.serverOsOption.title', {index: 1}), 
    //     value: serverOsValue, 
    //     rules: [{ required: true, message: t('v2.mainProcess.s4.form.errorTip') }],
    //     options: [
    //         { label: t('v2.mainProcess.s3.resource.serverOsOption.windows'), value: t('v2.mainProcess.s3.resource.serverOsOption.windows'), disabled: true, },
    //         { label: t('v2.mainProcess.s3.resource.serverOsOption.android'), value: t('v2.mainProcess.s3.resource.serverOsOption.android'), disabled: true, },
    //     ]
    // },
    {
        label: t('v2.mainProcess.s4.form.item3.title', {index: ""}),
        value: resolutionValue, 
        options: [
            { label: t('v2.mainProcess.s4.form.item3.v1'), value: t('v2.mainProcess.s4.form.item3.v1') },
            { label: t('v2.mainProcess.s4.form.item3.v2'), value: t('v2.mainProcess.s4.form.item3.v2') },
        ]
    },
    {
        label: t('v2.mainProcess.s4.form.item4.title', {index: ""}),
        value: bitrateValue, 
        options: [
            { label: t('v2.mainProcess.s4.form.item4.v1'), value: t('v2.mainProcess.s4.form.item4.v1') },
            { label: t('v2.mainProcess.s4.form.item4.v2'), value: t('v2.mainProcess.s4.form.item4.v2') },
        ]
    }
])
const getQuestion = (index: number) => t(`v2.mainProcess.s4.form.item${index + 3}.title`, {index: `${index + 3}. `})
const getRequirementListParams = () => radioOptions.map((item: any, index: number) => ({ question: getQuestion(index), solution: item.value || "", orderId: index + 3 }))
const onSubmit = async () => {
    buyLoading.value = true
    const { projectId, packageId } = route.params
    const nodeList = nodeIds.value.map((item: any) => ({ nodeId: item.value, quantity: item.quantity }))
    const skuId = skuList.value[0].skuId
    try {
        const res = await API.orderPurchase({
            applicationId: projectId,
            nodeList,
            packageId,
            skuId,
            requirementList: getRequirementListParams() 
        })
        if (isFromGame.value) {
            window.history.back()
        } else {
            await API.setMainStep(3, { lastProjectId: projectId, lastPackageId: packageId})
            buyLoading.value = false
            if (res) {
                router.push({ name: 'mainProcessStep04', params: { projectId, packageId } })
            }
        }
    } catch (e) {
        buyLoading.value = false
    }
}

watch(nodeIds, (v) => {
    let total = 0;
    nodeIds.value.forEach((node: any) => {
        total += node.quantity * node.price
    })
    totalPrice.value = (total * 100 / 100).toFixed(2)
}, { deep: true })

const getPrice = async (node: any) => {
    const nodeId = node.value
    const skuId = skuList.value[0].skuId
    const data: any = await API.getSkuPrice({ nodeId, skuId })
    const quota: any = await API.getNodeQuota({ nodeId })
    node.maxQuantity = quota.quantity
    node.price = Number(data.skuPrice)
}

const addNode = async (node: any) => {
    console.log('node:', node)
    if(node.type === 'default') {
        node.type = 'primary'
        node.quantity = 1
        nodeIds.value.push(node)
        // await getPrice(node)
    } else {
        node.type = 'default'
        const index = nodeIds.value.findIndex((n: any) => n.value === node.value)
        nodeIds.value.splice(index,1)
    }
}

const fetchStatus = () => {
    const { packageId } = route.params
    API.checkGameUploaded(packageId as string).then((data: any) => {
        isUploadGame.value = data?.id ? 1 : 0
    }).catch((error: any) => {
        console.error("fetchStatus: error---", error)
    })
}

onBeforeMount(async () => {
    const { projectId, packageId } = route.params
    loading.value = true
    const skuParams: any = {
        applicationId: projectId,
        packageId
    }
    try {
        // const nodeList: any = await API.getResourceNodeConfig()
        const nodeList: any = await API.getQueryUnpurchaseNode(packageId as string)
        const gameInfo: any = await API.getGameInfo(packageId as string)
        isUploadGame.value = gameInfo.isUploadGame || 0 
        if (gameInfo && gameInfo.requirement && Object.keys(gameInfo.requirement).length > 0 ) {
            resolutionValue.value = gameInfo.requirement.resolution || ''
            bitrateValue.value = gameInfo.requirement.frameRate || ''
            canEdit.value = false
        }
        const skuConfigList: any = await API.getSkuAlreadyAdapterList(skuParams)
        skuList.value = skuConfigList.data.data

        if (skuConfigList.data.code === 200) {
            projectInfo.value = gameInfo
            nodeOptions.value = nodeList.map((item: any) => ({label: item.nodeName, value: item.nodeId, quantity: 1, type: 'default', price: 0, maxQuantity: 1 }))
            for (const node of nodeOptions.value) {
                await getPrice(node)
            }
            loading.value = false
        }

    } catch (e) {
        console.log('e:', e)
        loading.value = false
    }
})
</script>