<template lang="pug">
    section.pb-2
    a-row
        a-col(:span="8")
            p(:style="pStyle3") Select Week
            a-date-picker( placeholder="Select the start date" v-model:value="timeStartDate" :disabled="dataListType==1" @change="changeFn" style="width:90%"  )
        a-col(:span="8")
            p(:style="pStyle3") Select Time Zone
            a-select(v-model:value="timeZoneDetail" :disabled="dataListType==2" mode="combobox"  :maxTagCount="1" style="width:90%" placeholder="Tags Mode" :options="timeZone" @change="handleChange")
        a-col(:span="8")
            p(:style="pStyle3") Repeat Week
            a-input-number.text-center(v-model:value="repeatWeek" :disabled="dataListType==2" style="width:40%"  :controls="false" :min="0")
                template(#addonBefore)
                    span.action(v-if="dataListType == 2") -
                    span.action(v-else @click="repeatWeek > 0 ? repeatWeek-- : 1") -
                template(#addonAfter)
                    span.action(v-if="dataListType==2") +
                    span.action(v-else @click="repeatWeek <= 9 ? repeatWeek++ : 10") +
            //- p.mt-3(:style="pStyle" v-if="timeStart") {{`${timeStart}`}} - {{`${timeEnd}`}}
    section.pb-5
    a-row
        a-col(:span="24")
            a-table(
                :data-source="tableDate"
                :columns="columns2"
                :pagination="false"
            )
              template(#headerCell="{ column }")
                span {{column.title}} 
                span(style="color:#ccc" v-if="column.txt") {{`(${column.txt})`}}
              template(#emptyText)
                  div.text-center.pt-16
                    img.mb-6(class="w-45" style="margin:0 auto" src="@/assets/images/new/reserved.png")
                    .py-6.txt-third-color Select week to start your weekly plan
              template(#bodyCell="{ column, text, record, index }")
                template(v-if="column.key === 'week'")
                  p.pt-3 {{getWeekFn(record.startDate)}}
                template(v-if="column.key === 'startTime'")
                  div.flex
                    a-select(:options="startTimeList" :disabled="record.editStatus==0" @change="startDateChange(index)" v-model:value="record.startTime")
                    div.select-div-hh -
                    a-select(:options="endTimeList" :disabled="record.editStatus==0" @change="endDateChange(index)" v-model:value="record.endTime")
                    div.select-div-hh.ml-2.copy-img-wrap(v-if="index>0 &&record.editStatus>0" @click="copyDataTimeFn(index)")
                      svg-icon(name="copy-img" size="18")
                      span.pl-2 Copy previous
                template(v-if="column.key === 'realStartTime'")
                  div.flex(v-if="record.realStartTime != null ")
                    div.select-div-hh {{setRunTimeFn(record.realStartTime)}}
                    div.select-div-hh -
                    div.select-div-hh {{setRunTimeFn(record.realEndTime)}}
                template(v-if="column.key === 'quantity'")
                    a-input-number.text-center( v-model:value="record.quantity" :disabled="record.editStatus==0" style="width:50px" placeholder="Please input CCU number"  :max="10000" :controls="false" :min="0" )
                template(v-if="column.key === 'realQuantity'")
                    div.select-div-hh( v-if="record.realQuantity") {{record.realQuantity}}
                template(v-if="column.key === 'timeZone'")
                    p.pt-3 {{record.timeZone ? record.timeZone : timeZoneDetail}}
            div.flex.mt-10.justify-center(v-if="tableDate&& tableDate.length>0")
                a-button.mr-8(v-if="delBtnShow" danger type="primary" @click="delScheduleFN")  Delete Plan
                a-button( @click="resetFn") Reset
                a-button.v2-btn.ml-8.mb-10(v-if="dataListType>=2" disabled type="primary") {{ $t('v2.action.save') }}
                a-button.v2-btn.ml-8.mb-10(v-else type="primary" @click="getScheduleFn") {{ $t('v2.action.save') }}
    a-modal(v-model:open="successModel" :footer="null" :closable="false")
        a-result(status="success" title="CCU Schedule Confirmed" sub-title="Your submitted CCU schedule will be executed as planned.")
            template( #extra)
                a-button.v2-btn.mt-8(@click="nextDataFn") Confirm
    a-modal(v-model:open="warningModel" :footer="null" :closable="false")
        a-result(status="warning" title="Requested CCU Exceeds Quota" sub-title="Requested CCU exceeds your flexible quota. We are applying for additional quota. Detailed updates will be communicated via email.")
            template( #extra)
                a-button.v2-btn.mt-8(@click="nextDataFn") Confirm
    a-modal(v-model:open="delOpenFa"  :footer="null" :closable="false")
        a-result(title="Are you sure?" sub-title="Deleting will permanently remove this plan. This cannot be undone!")
            template(#icon)
                img( style="width:80px;margin:0 auto" src="@/assets/images/new/delete.png")
            template(#extra)
                a-button.v2-btn.mr-6(key="console" @click="delOpenFa = false" type="primary") Cancel
                a-button(key="buy" @click="delFn") Delete
    section.a-alert-wrap
      a-alert(message="Reasons for non-editable schedule" v-if="alertFaStr" @close="onCloseAlert" closable :banner="true"  description="1.The plans for the next seven days are already scheduled.\n2.After evaluation, there is a discrepancy between the actual resources and the planned resources.If you need to make changes, please submit a ticket to us." show-icon type="warning")
</template>
<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import API from "@/common/api/console/v2/home";
import { cloneDeep } from "lodash-es";
import { formatData } from "@/utils/index";
import dayjs, { Dayjs } from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { getUserInfo, getUserUid, getUserId } from '@/utils/sso'
import localeData from "dayjs/plugin/localeData";
import { message } from '@welink/design-v2'
const props = defineProps({
    unpurchaseNodeObj: {
        type: Object,
        default: ''
    }
})

const emit = defineEmits(['addOnlinetabelFn','close'])

const route = useRoute()
const router = useRouter()
const subModelFa = ref(false)
const btmListFa = ref(false)
const alertFaStr = ref(false)
const { t } = useI18n();
dayjs.extend(weekday)
dayjs.extend(localeData)
const successModel = ref(false)
const warningModel = ref(false)
const open = ref<boolean>(false);
const tableDate = ref<any>([]);
const startDate = ref<any>(null);
const endDate = ref<any>(null);
const repeatWeek = ref<any>(0);
const timeStart = ref<any>("");
const timeEnd = ref<any>("");
const timeStartDate = ref<Dayjs>();
const dateFormat = "YYYY-MM-DD";
const delOpenFa = ref(false)
const delBtnShow = ref(false)
const timeFn = (e: any) => {
    return dayjs(e, dateFormat);
};
const copyDataTimeFn = (i: any) => {
    tableDate.value[i].startTime = tableDate.value[i-1].startTime
    tableDate.value[i].endTime = tableDate.value[i-1].endTime
}
const tableVal = (i: any) => {
    if(tableDate.value[i].startTime >= tableDate.value[i].endTime){
        tableDate.value[i].endTime = 24
    }
}
const startDateChange = i => {
    tableVal(i)
};
const endDateChange = i => {
    tableVal(i)
};
const changeFn = (e) => {
    const cha = repeatWeek.value + 1;
    const endTime = cha * 7 - 1;
    timeEnd.value = getDataFn(formatData(timeStart.value), endTime)
    checkWeekFn(e)
}
const onCloseAlert = () => {
    alertFaStr.value = false
}
const nextDataFn = () => {
    message.success("Resource added successfully")
    successModel.value = false
    warningModel.value = false
    router.push({
        name: 'projectGameDetail',
        params: { gameId: route.params.gameId },
    })
}
const pStyle = {
    fontSize: "14px",
    color: "#86909C",
    lineHeight: "24px",
    display: "block",
    marginBottom: "16px"
};
const pStyle2 = {
    marginBottom: "8px",
    fontSize: "20px",
    fontWeight: "500",
    color: "#1D2129"
};

const pStyle3 = {
    fontSize: "14px",
    fontWeight: "600",
    color: "#4E5969",
    width: "100%"
};

const handleChange = (value: string) => {
    tableDate.value.map((item: any) => {
        if(item.editStatus === 2){
            item.timeZone = value
        }
    })
};

const showDrawer = () => {
    open.value = true;
};
const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
];
const getScheduleFn = () => {
    const arrList: any[] = []
    let obj = {}
    tableDate.value.map((item: any) => {
        obj = {
            endTime:item.endTime,
            quantity:item.quantity,
            startDate:item.startDate,
            startTime:item.startTime
        }
        arrList.push(obj)
    })
    const resourceIdStr = props.unpurchaseNodeObj.resourceDetailId
    const nodeStr = props.unpurchaseNodeObj.nodeId
     
    const param = {
        cycleValue: repeatWeek.value,
        resourceDetailId: resourceIdStr,
        items: arrList,
        applicationId: route.params.projectId,
        nodeId: nodeStr,
        packageId: route.params.gameId,
        userId: getUserUid(),
        timeZone: timeZoneDetail.value
    }
    API.addOnDemandPlan(param).then((data)=>{
        console.log(data)
        if(data === 0){
            // no
            successModel.value = true
        } else {
            // yes
            warningModel.value = true
        }
    })
}
const getDayOfWeek = (date: any) => {
    return weekDays[date.getDay()];
};
const getWeekFn = (e: any) => {
    return weekDays[dayjs(e).day()]
}
const dataLsit = reactive<any>([]);

const doHandMonth = (month: any) => {
    let m = month;
    if (month.toString().length === 1) {
        m = "0" + month;
    }
    return m;
};
const getDataFn = (newTime: any, day: any) => {
    const today = new Date(newTime);
    const targetday = today.getTime() + 1000 * 60 * 60 * 24 * day;
    today.setTime(targetday);
    const tYear = today.getFullYear();
    let tMonth = today.getMonth();
    let tDate = today.getDate();
    tMonth = doHandMonth(tMonth + 1);
    tDate = doHandMonth(tDate);
    return tYear + "-" + tMonth + "-" + tDate;
};
const dataListType = ref(-1)
const setTableList = (list: any[], timeObj: any,e: any) => {
    let nextDay = null;
    const listDate2 = [];
    let obj = {
        startDate: e,
        quantity: 0,
        startTime: 0,
        endTime: 24,
        editStatus: 2
    };
    listDate2.push(obj);
    [...Array(6)].map((item, index) => {
        nextDay = getDataFn(startDate.value, index + 1);
        obj = {
            startDate: nextDay,
            quantity: 0,
            startTime: 0,
            endTime: 24,
            editStatus: 2
        };
        listDate2.push(obj);
    });

    listDate2.map((item: any) => {
        if(dayjs(item.startDate).unix() > timeObj.new ){
            item.editStatus = 2
            item.quantity = 0
        }else{
            item.editStatus = 0
        }
        list.map((key) => {
            if(key.startDate === item.startDate){
                item.startTime = key.startTime
                item.endTime = key.endTime
                item.quantity = key.quantity
                item.timeZone = key.timeZone
                item.status = key.status
                item.realQuantity = key.realQuantity
                item.realStartTime = key.realStartTime
                item.realEndTime = key.realEndTime
                item.editStatus = key.editStatus
                if(key.realQuantity){
                    item.editStatus = 0
                    dataListType.value = 1
                }
            }
        })
    })
    if(listDate2.length > 0 && dataListType.value > 0){
        alertFaStr.value = true
    }
    tableDate.value = listDate2
}
const checkDelBtnShow = (e: any, timeDelList: any[]) => {
    const timeObj = {
        new: dayjs(getDataFn(new Date(), 6)).unix(), // new: dayjs(new Date()).unix(),
        start: dayjs(e).unix(),
        future: dayjs(getDataFn(e, 6)).unix()
    }
    if(timeObj.start > timeObj.new ){
        dataListType.value = 1
        delBtnShow.value = timeDelList && timeDelList.length > 0 &&  timeDelList.some((item: any) => {
            const recordTime = dayjs(item.startDate).unix()
            return recordTime <= timeObj.future && recordTime >= timeObj.start
        })
    }
}
const dataList = (e,list)=> {
    checkDelBtnShow(e,list)
    const timeObj = {
        new: dayjs(getDataFn(new Date(), 6)).unix(), // new: dayjs(new Date()).unix(),
        start: dayjs(e).unix(),
        future: dayjs(getDataFn(e, 6)).unix()
    }
    timeStart.value = e
    console.log("timeObj:::~~", timeObj)
    alertFaStr.value = false
    // 未来数据
    if(timeObj.start > timeObj.new ){
        dataListType.value = 0
        setTableList(list,timeObj,e)
    // 时间跨度在中间
    }else if (timeObj.start <= timeObj.new && timeObj.future > timeObj.new){
        dataListType.value = 1
        setTableList(list,timeObj,e)
    }else{
    // 不可编辑的时间
        dataListType.value = 3
        if(list.length > 0){
            dataListType.value = 2
            alertFaStr.value = true
            list.map((item: any) => {
                item.editStatus = 0
            })
        }
        tableDate.value = list
    }
};
const paramProps = inject('paramProps')
const checkWeekFn = async (e) => {
    if (!e) return;
    startDate.value = formatData(e);
    const cha = repeatWeek.value + 1;
    const endTime = cha * 7 - 1;
    timeEnd.value = getDataFn(startDate.value, endTime);
    if(!props.unpurchaseNodeObj.resourceDetailId){
        return
    }
    const param = {
        packageId: route.params.gameId,
        startDate: startDate.value,
        endDate: getDataFn(e, 6),
        nodeId: props.unpurchaseNodeObj.nodeId,
        resourceDetailId: props.unpurchaseNodeObj.resourceDetailId, 
        applicationId:route.params.applicationId || route.params.projectId,
        id: getUserId(),
        userId: getUserUid(),
        current: 1,
        size: 200
    }
    const dateList:any = await API.getQueryOnDemandPlan(param)
    dataList(startDate.value, dateList);
}

const disabledDate = (current: Dayjs) => {
    return current && current < dayjs().endOf('day');
};
const newTimeFn = (time: any, res: any) => {
    checkWeekFn(time);
    timeStartDate.value = dayjs(time,'YYYY-HH-DD')
};
const timeZoneDetail = ref<any>("UTC+0");
const onDemandListFn = async () => {
    const param = {
        packageId: route.params.gameId,
        nodeId: props.unpurchaseNodeObj.nodeId,
        resourceDetailId: props.unpurchaseNodeObj.resourceDetailId || '', 
        applicationId: props.unpurchaseNodeObj.applicationId,
        userId: getUserUid() || '',
        startDate: tableDate.value[0].startDate || formatData(new Date()),
        endDate: timeEnd.value || getDataFn(formatData(new Date()), 6)
    }
    const dateList:any = await API.getQueryOnDemandPlan(param)
}
const refreshData = async() => {
    const param = {
        packageId: props.unpurchaseNodeObj.packageId,
        startDate: tableDate.value[0].startDate,
        endDate:  timeEnd.value,
        nodeId: props.unpurchaseNodeObj.nodeId,
        resourceDetailId: props.unpurchaseNodeObj.resourceDetailId, 
        applicationId:route.params.applicationId || route.params.projectId,
        id: getUserId(),
        userId: getUserUid(),
        current: 1,
        size: 200
    }
    const dateList:any = await API.getQueryOnDemandPlan(param)
    dataList(startDate.value, dateList);
}
const delFn = () => {
    const param = {
        endDate: timeEnd.value,
        resourceDetailId: props.unpurchaseNodeObj?.resourceDetailId || '',
        startDate: tableDate.value[0].startDate
    }
    console.log("删除--params=",param)
    console.log("resourceDetailId=",props.unpurchaseNodeObj.resourceDetailId)
    console.log("props=",props)
    API.deleteOnDemandPlan(param).then((data) => {
        // refreshData()
        delOpenFa.value = false
        open.value = false
        resetFn()
    })
    // deleteOnDemandPlan 删除
}
const delScheduleFN = () => {
    delOpenFa.value = true
}
defineExpose({
    newTimeFn,
    tableDate,
    timeZoneDetail,
    repeatWeek,
    timeStart,
    timeEnd,
    onDemandListFn
});
const timeZoneD = [...Array(12)].map((_, i) => ({
    value: `UTC+${i + 1}`,
    label: `UTC+${i + 1}`
}));
const timeZoneX = [...Array(12)].map((_, i) => ({
    value: `UTC-${i + 1}`,
    label: `UTC-${i + 1}`
}));
const timeUtc = [{
    value: `UTC+0`,
    label: `UTC+0`
}] 
const timeZone = ref<any>([...timeUtc, ...timeZoneD, ...timeZoneX]);
const timeListArr = [...Array(23)].map((_, i) => ({
    value: i + 1,
    label: i >= 9 ? String(i + 1) + ":00" : "0" + (i + 1) + ":00"
}));
const startTimeList = [
    {
        value: 0,
        label: "00:00"
    },
    ...timeListArr
];
const endTimeList = [
    ...timeListArr,
    {
        value: 24,
        label: "24:00"
    }
];
const resetFn = () => {
    tableDate.value = []
    timeStartDate.value = undefined
    repeatWeek.value = 0
    timeZoneDetail.value = "UTC+0"
    dataListType.value = 0
    alertFaStr.value = false
}

const columns2 = reactive<any[]>([
    { title: t("onlineObj.weekday"), dataIndex: "week", key: "week" },
    { title: t("onlineObj.date"), dataIndex: "startDate", key: "startDate" },
    {
        title: t("onlineObj.runningHours"),
        prop: "startTime",
        dataIndex: "startTime",
        key: "startTime",
        txt: t('onlineObj.plan')
    },
    {
        title: t("onlineObj.runningHours"),
        prop: "realStartTime",
        dataIndex: "realStartTime",
        key: "realStartTime",
        txt: t('onlineObj.real')
    },
    { title: t("onlineObj.ccu"), prop: "quantity", key: "quantity", dataIndex: "quantity" , width:30 ,txt: t('onlineObj.plan')},
    { title: t("onlineObj.ccu"), prop: "realQuantity", key: "realQuantity", dataIndex: "realQuantity" , width:30 ,txt: t('onlineObj.real')},
    { title: 'Time Zone', prop: "timeZone", key: "timeZone", dataIndex: "timeZone" }
]);

const setRunTimeFn = (time:any) => {
    const obj = time < 10 ? '0' + time : time
    return `${obj}:00`
}
watch(() => props.unpurchaseNodeObj.resourceDetailId,() => {
    resetFn()
},{deep:true})
</script>
<style lang="sass" scoped>
.select-div-hh
    line-height: 40px
    padding: 0 6px
:deep(.ant-input-number .ant-input-number-input)
    text-align: center
:deep(.ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody >tr:last-child>td)
    border-bottom: none !important
.a-alert-wrap
    border-radius: 12px
    overflow: hidden
:deep(.ant-alert-with-description .ant-alert-description)
    white-space: pre-wrap !important
.copy-img-wrap
    cursor: pointer
    &:hover span
        color: #124EF4
</style>

