<template lang="pug">
div(class="bg-white relative flex-1 pt-3 pb-5 px-6 rounded-lg common-console-list min-h-xl")
    .left-top
    .right-top
    .left-bottom
    .right-bottom
    div(class="text-base txt-color font-medium pb-4 relative z-2") {{ $t('v2.console.recentProject.title') }}
    //- .absolute.top-3.right-6.z-2.text-xs.txt-color.cursor-pointer(hover="underline" @click="router.push({ name: 'projectList' })") {{ $t('v2.console.recentProject.more') }}
    project-list(hide-pagination type="recent")
</template>

<script lang="ts" setup>
import ProjectList from '../components/projectListNew.vue'
const router = useRouter()
</script>