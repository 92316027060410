<template lang="pug">
template(v-if="loading")
    .flex.items-center.justify-center.pt-50
        a-spin(size="large" :spinning="loading" :tip="$t('v2.action.loading') + '...'")
template(v-else-if="!(result.records && result.records.length) || !total")
    //- div.text-center.relative(class="top-1/2 -translate-y-1/2 -mt-9")
    div(class="rounded-lg bg-white relative flex-1")
    //- div(class="text-base txt-color font-medium py-3 px-6") {{ $t('home.homeProject.title') }}
    div(class="absolute text-center  left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2")
        img( style="display:inline-block" class="w-45" src="@/assets/images/home/create-project.png")
        div(class="mt-4 text-xs text-[#C9CDD4]") No games running today
        div(class="text-base text-[#4E5969] font-medium")  To schedule games, go to Game Management.
        a-button(class="mt-4" @click="createProject") Schedule
template(v-else)
    w-biz-table(
            :data="result?.records"
            :loading="loading"
            :columns="columns"
            :scroll="{ x: 1000 }"
            :pagination="{ current: pagenationParams.current, total: pagenationParams.total, pageSize: pagenationParams.size}"
            @change="sizeChange"
        )
            template(#emptyText) 
                .py-10.txt-third-color {{ $t('v2.action.emptyData') }}
            template(#customColumn="{ record, column}")
                //- template(#operate = "{ record }" )
                template(v-if="column.prop === 'startTime'")
                    span {{setRunTimeFn(record.startTime)}} - 
                    span {{setRunTimeFn(record.endTime)}}
                    span(v-if="record.timeZone") | {{record.timeZone}}
                template(v-if="column.prop === 'operate'")
                    span.pri-color.cursor-pointer.mr-2( v-if="record.testResourceFlowStatus === 3" hover="underline" @click="addQuantityOpen(record)") {{ $t('v2.resourceList.setQuantity') }}
                    span.pri-color.cursor-pointer.mr-2.text-red-500( v-if="record.testResourceFlowStatus === 3" hover="underline" @click="onRelease(record.testResourceDetailId)") {{ $t('v2.action.release') }}
                    span.pri-color.cursor-pointer.mr-2( v-if="record.onlineResourceDetailId" hover="underline" @click="toViewFn(record)") {{ $t('common.look') }}
                    span.pri-color.cursor-pointer.mr-2( v-if="record.onlineResourceDetailId" hover="underline" @click="goOnlineFn(record)") Schedule
                    span.pri-color.cursor-pointer.mr-2( v-if="record.testResourceFlowStatus===3" hover="underline" @click="play(record)") {{ $t('v2.action.play') }}
//- 扩容模块start
a-modal(
    v-model:open="addQuantityVisible" :width="432" centered wrapClassName="popup-edit-project" :title="$t('v2.resourceList.setQuantity')"
)
    .flex.items-center.justify-center.pt-2.pb-1
        | {{ $t('v2.resourceList.quantity') }}：
        a-input-number(
            v-model:value="addQuantityNum"
            :controls="false" :min="0" :precision="0" autofocus
            :status="disabledScale ? 'error' : ''"
            maxlength="5"
        )
            template(#addonBefore)
                span.action(:class="{ 'disabled' : addQuantityNum <= 1}" @click="addQuantityNum > 1 ? addQuantityNum-- : null") -
            template(#addonAfter)
                span.action(:class="{ 'disabled' : disabledScale }"  @click="disabledScale ? '' : addQuantityNum++") +
    .text-red-500.text-xs.pt-1.pl-27.mb-3(v-if="disabledScale")
        | {{ $t('v2.mainProcess.s3.resource.quantityTip') }}: {{ currentResource.maxQuantityNum }}.

    template(#footer)
        .flex.justify-end
            a-button(@click="addQuantityVisible = false").mr-2 {{ $t('v2.action.cancel') }}
            a-button.v2-btn(type="primary" :loading="addQuantityLoading" :disabled="disabledScale" @click="addQuantityHandle") {{ $t('v2.action.confirm') }}
//- 扩容模块end
a-modal(
    v-model:open="releaseVisible" :width="432" :closable="false" wrapClassName="popup-delete-project" centered
)
    .flex.items-center.justify-center
        svg-icon(name="popup-trash" :size="80").block.mb-2
    div.text-lg.txt-color.text-center.mb-8 {{ $t('v2.projectList.deleteTitle') }}
    p.p-0.mb-8(class="text-[#86909C] text-sm px-10")
        | {{ $t('v2.projectList.deleteDesc') }}
        strong.txt-color {{ $t('v2.projectList.deleteDescPrefix') }}
    template(#footer)
        a-button.v2-btn(type="primary" @click="releaseVisible = false") {{ $t('v2.action.cancel') }}
        a-button(:loading="releaseLoading" @click="releaseHandleOk") {{ $t('v2.action.release') }}
//- play
a-modal(
  v-model:open="gamePlayVisible" :footer="null" width="800" centered :title="$t('console.adapter.stepEdit.preview')"
)
    .flex.justify-center.m-auto.text-sm.py-5
        .filed.text-center
            .font-semibold.mb-2 {{ $t('console.adapter.stepEdit.phone') }}
            .text-gray-500.mb-7.px-4 {{ $t('console.adapter.stepEdit.qrcode') }}
            .border.rounded.p-2.inline-block
                .w-36.h-36.bg-slate-200autoFullPackage 
                    vue-qrcode(:value="qrCodeLink" tag="svg" :options="{ margin: 0, width: 144, height: 144 }").qrcode
        .border-r.mx-16
        .filed.text-center
            .font-semibold.mb-2 {{ $t('console.adapter.stepEdit.web') }}
            .text-gray-500.mb-7 {{ $t('console.adapter.stepEdit.webPreview') }}
            .block.mb-5
                svg-icon(name="web-demo" :size="100").ml-2
            a-button(type="primary" @click="goWebPreview") {{ $t('console.adapter.stepEdit.look') }}
</template>

<script lang="ts" setup>
import API from '@/common/api/console/v2/home'
import ProjectItem from './projectItem.vue'
import { getLocale, getToken, getUserInfo, getUserUid } from '@/utils/sso'
import { useI18n } from 'vue-i18n'
import { message } from '@welink/design-v2';
import { Toast } from '@welink/design'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { isForeign } from "@/common/constants/env"
import { useGlobalStore } from '@/store/global'

const { t } = useI18n()
const store = useGlobalStore()
const addQuantityVisible = ref(false)
const addQuantityNum = ref(1)
const currentResource = ref({ 
    id: 0, 
    nodeId: '', 
    originQuantity: 0,
    quantity: 0, 
    maxQuantityNum: 1,
    testResourceDetailId: ''
})
const addQuantityLoading = ref(false)
const releaseVisible = ref(false)
const releaseId = ref('')
const releaseLoading = ref(false)
const isMinScale = ref(false) // 缩容时候 不判断限额
const gamePlayVisible = ref(false)
const currentListItem = ref<any>(null)
const router = useRouter()

const disabledScale = computed(() => !isMinScale.value && (addQuantityNum.value > currentResource.value.maxQuantityNum))

// 分页列表
const pagenationParams = ref({
    // pageNo: 1,
    // pageSize: 10,
    current: 1,
    size: 10,
    total:0
})

// const props = withDefaults(defineProps<{
//     type?: string,
//     showTitle?: boolean,
//     hidePagination?: boolean,
//     params?: Record<string, any>
//     item?: any
// }>(), {
//     params: () => ({ size: 10, current: 1 }),
//     type: ''
// })

const toViewFn = (row: any) => {
    router.push({ name: 'powerTime', params: {
        resourceDetailId: row.onlineResourceDetailId || '', 
        applicationId: row.applicationId,
        gameId: row.packageId,
        nodeId: row.nodeId,
        nodeName: row.nodeName
    } })
}

const total = ref(0)
// const { current, size } = props.params || {}

const result = ref<any>({
    // current,
    // total: 0,
    // size,
    records: []
})
const loading = ref(false)
const fromSearch = ref('')
const fetchList = (params: any = {}) => {
    fromSearch.value = params.applicationName
    loading.value = true
    API.getQueryCurrentRunningGameByPage({ ...pagenationParams.value, ...params.value, userId: getUserUid() }).then((data: any) => {
        result.value = data
        pagenationParams.value.total = data.total
        total.value = data.total
        loading.value = false
    }).catch(() => {
        result.value.records = []
        pagenationParams.value.total = 0
        loading.value = false
    })
}

const releaseHandleOk = () => {
    releaseLoading.value = true
    API.releaseResource(releaseId.value).then(res => {
        releaseLoading.value = false
        releaseVisible.value = false
        Toast.success(t('v2.common.actionSuccess'))
        fetchList({ ...pagenationParams.value})
    }).catch(() => {
        releaseLoading.value = false
    })
}

const goOnlineFn = (e: any) => {
    router.push({ name: 'goOnline', params: {
        gameId: e.packageId,
        projectId: e.applicationId,
        resourceDetailId:e.onlineResourceDetailId,
    } })
}

const changeParams = (current: number) => {
    console.log(1111)
    pagenationParams.value.current = current
    fetchList({ ...pagenationParams.value})
}

const addQuantityOpen = (record: any) => {
    addQuantityNum.value = record.testQuantity
    addQuantityVisible.value = true
    API.getNodeQuota({ nodeId: record.nodeId }).then((data: any) => {
        record.maxQuantityNum = Math.floor(data.quantity + record.testQuantity)
        record.originQuantity = record.testQuantity
        record.quantity = record.testQuantity
        currentResource.value = record
    })
}

const sizeChange = ({ current, pageSize}: any) => {
    console.log(2222)
    pagenationParams.value.current = current
    pagenationParams.value.size = pageSize
    fetchList({ ...pagenationParams.value})
}

const addQuantityHandle = () => {
    const { nodeId, quantity, testResourceDetailId } = currentResource.value

    if (quantity === addQuantityNum.value) {
        addQuantityVisible.value = false
        return
    }

    let operationType = '';
    // 大于设置值是扩容，小于是缩容
    operationType = addQuantityNum.value > quantity ? 'K' : 'S'

    const params = {
        id: testResourceDetailId,
        nodeId,
        operationType, // K,S
        extensionAmount: addQuantityNum.value
    }
    addQuantityLoading.value = true
    API.setResourceQuantity(params).then((data: any) => {
        addQuantityLoading.value = false
        addQuantityVisible.value = false
        message.success("Resource modification submitted successfully")
        fetchList({ ...pagenationParams.value})
    }).catch(e => {
        addQuantityLoading.value = false
        addQuantityVisible.value = false
    })
}

const onRelease = (id: string) => {
    releaseVisible.value = true
    releaseId.value = id
}

//  定义表格
const columns = ref<any>([
    {
        title: t("adapterManage.table.theader.applicationName"),
        prop: 'applicationName',
        width: 130
    },
    {
        title: t("adapterManage.table.theader.packageName"),
        prop: 'packageName',
        width: 180
    },
    {
        title: t("adapterManage.table.theader.version"),
        prop: 'packageVersion',
        // key: 'email',
        width: 100
    },
    {
        title: t("adapterManage.table.theader.region"),
        prop: 'nodeName',
        ellipsis: true,
        width: 150
    },
    {
        title: t("adapterManage.table.theader.reserved"),
        prop: 'onlineResource',
        width: 180
    },
    {
        title: t("adapterManage.table.theader.demand"),
        prop: 'onDemandQuantity',
        width: 200
    },
    {
        title: t("adapterManage.table.theader.runTime"),
        prop: 'startTime',
        slot: 'startTime',
        width: 200
    },
    {
        title: t("adapterManage.table.theader.actions"),
        prop: 'operate',
        slot: 'operate',
        width: 290,
        fixed: "right"
    },

])

const createProject = () => {
    router.push({ name: 'projectCreate' })
}

const currentChange = (page: string) => {
    fetchList({ current: page, applicationName: fromSearch.value })
}
const goWebPreview = () => {
    const nodeId = currentListItem.value?.nodeId
    const applicationId = currentListItem.value?.applicationId || ''
    const packageId = currentListItem.value?.packageId || ''
    if (applicationId && packageId && nodeId) { 
        // router.push({ name: 'projectGamePreview', params: { 
        //     projectId: applicationId, 
        //     gameId: packageId,
        //     nodeId
        // } })
        const target = router.resolve({
            name: 'projectGamePreview',
            params: { 
                projectId: applicationId, 
                gameId: packageId,
                nodeId
            },
        })
        window.open(target.href, '_blank')
    }
}
const qrCodeLink = computed(() => {
    const nodeId = currentListItem.value?.nodeId
    const applicationId = currentListItem.value?.applicationId || ''
    const packageId = currentListItem.value?.packageId || ''
    if (applicationId && packageId && nodeId) { 
        const host = `${window.location.origin}/#/console/${applicationId}/${packageId}/${nodeId}/preview`
        return `${host}?lang=${isForeign ? getLocale() || 'en' : 'zh'}&loginToken=${getToken()}&envId=${store.envId}&isH5=1`
    }
})
const play = (item:any) => {
    gamePlayVisible.value = true
    currentListItem.value = item
}
defineExpose({
    fetchList
})
const setRunTimeFn = (time: number) => {
    const obj = time < 10 ? '0' + time : time
    return `${obj}:00`
}
onBeforeMount(() => {
    fetchList()
})
</script>