<template lang="pug">
h1.console-title.pl-6.pt-4
    span(class="text-[#86909C]")
        router-link(:to="{ name: 'projectList' }") {{ $t('v2.projectList.title') }}
        span.mx-1 /
        span(@click="goBack").cursor-pointer {{ gameInfo.applicationName }}
        span.mx-1 /
    | {{ gameInfo.packageName }}
.bg-white.p-6.rounded-lg.min-h-full(class="mt-[14px] !pr-10")
    a-spin(size="large" :spinning="loading" :tip="$t('v2.action.loading') + '...'")
        .flex.justify-between.mb-18
            .flex.flex-1
                .thumb(class="mr-[50px]")
                    span.text-sm.txt-second-color.block.mb-1.font-bold {{ $t('v2.gameList.thumb') }}
                    .cover.flex.items-center.justify-center.rounded-lg
                        img.w-20.h-20.transition-all(src="@/assets/images/console/game-cover.png")
                .info-wrap.flex.justify-between.flex-1.pt-6
                    .info.flex-1
                        span.text-sm.txt-second-color.block.my-1 {{ $t('v2.action.project') }}
                        .text-base.txt-color.block.mb-3.font-medium {{ gameInfo.applicationName || '-' }}
                        span.text-sm.txt-second-color.block.mb-1 {{ $t('v2.action.game') }}
                        .text-base.txt-color.block.mb-3.font-medium {{ gameInfo.packageName }}
                        span.text-sm.txt-second-color.block.mb-1 {{ $t('v2.action.status') }}
                        .text-base.txt-color.block.mb-3.font-medium {{ getStatusText(gameInfo.status) }}
                    .info.flex-1
                        span.text-sm.txt-second-color.block.my-1 {{ $t('common.version') }}
                        .text-base.txt-color.block.mb-3.font-medium {{ gameInfo.packageVersion || '-' }}
                        span.text-sm.txt-second-color.block.mb-1 {{ $t('v2.gameList.fileSize') }}
                        .text-base.txt-color.block.mb-2.font-medium {{ bytesToSize(gameInfo.packageSize) }}
                    .info.info-flex-2
                        span.text-sm.txt-second-color.block.my-1 {{ $t('v2.console.sidebar.resource') }}
                        .text-base.txt-color.block.mb-3.font-medium {{ gameInfo.skuName || '-' }}
                        span.text-sm.txt-second-color.block.mb-1 {{ $t('console.appInfo.resourceList.skuName') }}
                        .text-base.txt-color.block.mb-3.font-medium {{ gameInfo.status===7 ? gameInfo.specfication : '---' }}
            .pt-5.flex.flex-col
                template(v-if="gameInfo.status === 7 && gameInfo.inOperationTestNodeCount>0")
                    a-button.v2-btn(type="primary" @click="goOnlineVisibleFn").block.mb-5.w-full
                        span.font-bold {{ $t('v2.action.goOnline') }}
                template(v-else-if="gameInfo.status === 7 && gameInfo.onlineNodeCount > 0 && gameInfo.onlineNodeCount < gameInfo.totalNodeCount" )
                    a-button.v2-btn(type="primary" @click="goOnlineVisibleFn").block.mb-5.w-full
                        span.font-bold {{ $t('v2.action.goOnline') }}
                template(v-else)
                    a-tooltip(:title="playTip" overlayClassName="header-tooltip")
                        a-button(type="primary" disabled).block.mb-5.w-full
                            span.font-bold {{ $t('v2.action.goOnline') }}
                a-button.font-bold(danger type="primary" @click="deleteVisible = true")
                    svg-icon(name="trash" :size="16" class="-mt-1 mr-1")
                    span.font-bold {{ $t('v2.action.delete') }}
                    //- cloudRequest
                template(v-if="gameInfo.purchaseTestNodeCount>0 && (gameInfo.status === 1 || gameInfo.status === 6)")
                    a-button.v2-btn(ghost type="primary" @click="cloudRequestClick").block.mt-5.w-full
                        span.font-bold {{ $t('v2.action.cloudRequest') }}
        .resource
            .flex.justify-between
                .text-base.font-bold.txt-color.mb-5 {{ $t('v2.gameList.resourceList') }}
                //-  v-if="gameInfo.unPurchaseTestNodeCount>0"
                a-button(class="!font-bold" :disabled="gameInfo.unPurchaseNodeCount === 0" size="small" @click="goPurchaseFn") {{ $t('v2.gameList.addResource') }}
            a-table(
                :columns="columns" :data-source="gameInfoList.resourceList" :pagination="paginationParams"
                @pagination-num-change="changeParams"
                @pagination-size-change="sizeChange"
            ).rounded-lg
                template(#emptyText) 
                    img.w-40.h-40.empty-img.mb-1(src="@/assets/images/new/clond.png")
                    .txt-third-color No resources assigned
                    //- {{ $t('v2.action.emptyData2') }}
                    .font-bold.txt-color Please add resources to run this game.
                    //- {{ $t('v2.action.startFirst') }}
                    a-button.mt-6.mb-16(class="!font-bold" @click="goPurchaseFn") Add Resource
                //- eslint-disable-next-line vue/no-unused-vars
                template(#bodyCell="{ column, text, record }")
                    template(v-if="column.key === 'startTime'")
                        span {{setRunTimeFn(record.startTime)}} - 
                        span {{setRunTimeFn(record.endTime)}}
                        span(v-if="record.timeZone") | {{record.timeZone}}
                    //- startTime
                    template(v-if="column.key === 'action'")
                        span.pri-color.cursor-pointer.mr-2( v-if="record.testResourceFlowStatus===3" hover="underline" @click="addQuantityOpen(record)") {{ $t('v2.resourceList.setQuantity') }}
                        span.pri-color.cursor-pointer.mr-2.text-red-500( v-if="record.testResourceFlowStatus===3" hover="underline" @click="onRelease(record.testResourceDetailId)") {{ $t('v2.action.release') }}
                        span.pri-color.cursor-pointer.mr-2( v-if="record.onlineResourceDetailId" hover="underline" @click="toViewFn(record)") {{ $t('common.look') }}
                        span.pri-color.cursor-pointer.mr-2( v-if="record.onlineResourceDetailId" hover="underline" @click="goOnlineFn2(record)") Schedule
                        span.pri-color.cursor-pointer.mr-2( v-if="record.testResourceFlowStatus===3 && gameInfo.status===7" hover="underline" @click="play(record)") {{ $t('v2.action.play') }}
//- delete
a-modal(
    v-model:open="deleteVisible" :width="432" :closable="false" wrapClassName="popup-delete-project" centered
)
    .flex.items-center.justify-center
        svg-icon(name="popup-trash" :size="80").block.mb-2
    div.text-lg.txt-color.text-center.mb-8 {{ $t('v2.projectList.deleteTitle') }}
    p.p-0.mb-8(class="text-[#86909C] text-sm px-10")
        | {{ $t('v2.projectList.deleteDesc') }}
        strong.txt-color {{ $t('v2.projectList.deleteDescPrefix') }}
    template(#footer)
        a-button.v2-btn(type="primary" @click="deleteVisible = false") {{ $t('v2.action.cancel') }}
        a-button( danger :loading="deleteLoading" @click="deleteHandleOk") {{ $t('v2.action.delete') }}
//- goOnline
a-modal(v-model:open="goOnlineVisible" :footer="null" width="580px" centered).text-center.pb-10
    div.modal-box-div
        h3.model-titlt.pt-5.text-center Make your game online
        a-tooltip( color="#124EF4" title="The current resource type will continue to be used in the online plan. If you need to make any changes, please end this process, and contact your account manager.")
            p.model-txt.text-center.pt-2.pb-3 The current resource type will continue to be used in the online plan. If you need to make any changes, please end this process, and contact your account manager.
        .mb-2 Select region first
        a-space.mb-8(direction="vertical" style="width:360px")
            a-select( v-model:value="addNodeList" width="100%" style="width: 200px" placeholder="please Select region"
:options="nodeOptions" @change="changeFn" )
            p(v-if="errorStr" style="color:red") please Select region
        a-button.v2-btn.model-btn( style="width:100%" type="primary" @click="goOnlineFn()") Continue
        p.pt-1
//- Scale
a-modal(
    v-model:open="addQuantityVisible" :width="432" centered wrapClassName="popup-edit-project" :title="$t('v2.resourceList.setQuantity')"
)
    .flex.items-center.justify-center.pt-2.pb-1
        | {{ $t('v2.resourceList.quantity') }}：
        a-input-number(
            v-model:value="addQuantityNum"
            :controls="false" :min="0" :precision="0" autofocus
            :status="disabledScale ? 'error' : ''"
            maxlength="5"
        )
            template(#addonBefore)
                span.action(:class="{ 'disabled' : addQuantityNum <= 1}" @click="addQuantityNum > 1 ? addQuantityNum-- : null") -
            template(#addonAfter)
                span.action(:class="{ 'disabled' : disabledScale }"  @click="disabledScale ? '' : addQuantityNum++") +
    .text-red-500.text-xs.pt-1.pl-27.mb-3(v-if="disabledScale")
        | {{ $t('v2.mainProcess.s3.resource.quantityTip') }}: {{ currentResource.maxQuantityNum }}.

    template(#footer)
        .flex.justify-end
            a-button(@click="addQuantityVisible = false").mr-2 {{ $t('v2.action.cancel') }}
            a-button.v2-btn(type="primary" :loading="addQuantityLoading" :disabled="disabledScale" @click="addQuantityHandle") {{ $t('v2.action.confirm') }}
//- release
a-modal(
    v-model:open="releaseVisible" :width="432" :closable="false" wrapClassName="popup-delete-project" centered
)
    .flex.items-center.justify-center
        svg-icon(name="popup-trash" :size="80").block.mb-2
    div.text-lg.txt-color.text-center.mb-8 {{ $t('v2.projectList.deleteTitle') }}
    p.p-0.mb-8(class="text-[#86909C] text-sm px-10")
        | {{ $t('v2.projectList.deleteDesc') }}
        strong.txt-color {{ $t('v2.projectList.deleteDescPrefix') }}
    template(#footer)
        a-button.v2-btn(type="primary" @click="releaseVisible = false") {{ $t('v2.action.cancel') }}
        a-button(:loading="releaseLoading" @click="releaseHandleOk") {{ $t('v2.action.release') }}
//- play
a-modal(
  v-model:open="gamePlayVisible" :footer="null" width="800" centered :title="$t('console.adapter.stepEdit.preview')"
)
    .flex.justify-center.m-auto.text-sm.py-5
        .filed.text-center
            .font-semibold.mb-2 {{ $t('console.adapter.stepEdit.phone') }}
            .text-gray-500.mb-7.px-4 {{ $t('console.adapter.stepEdit.qrcode') }}
            .border.rounded.p-2.inline-block
                .w-36.h-36.bg-slate-200autoFullPackage 
                    vue-qrcode(:value="qrCodeLink" tag="svg" :options="{ margin: 0, width: 144, height: 144 }").qrcode
        .border-r.mx-16
        .filed.text-center
            .font-semibold.mb-2 {{ $t('console.adapter.stepEdit.web') }}
            .text-gray-500.mb-7 {{ $t('console.adapter.stepEdit.webPreview') }}
            .block.mb-5
                svg-icon(name="web-demo" :size="100").ml-2
            a-button(type="primary" @click="goWebPreview") {{ $t('console.adapter.stepEdit.look') }}
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { getLocale, getToken, getUserUid } from '@/utils/sso';
import { isForeign } from "@/common/constants/env"
import API from '@/common/api/console/v2/home'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { bytesToSize } from '@/utils/bytesConvert'
import { resourceStatusMap } from '@/views/console/v2/constants/index'
import { Toast } from '@welink/design';
import dayjs from 'dayjs'
import { useGlobalStore } from '@/store/global'
// import { datasetModule } from 'snabbdom';
import GoOnline from '@/views/console/v2/goOnline/index.vue';
import { message } from '@welink/design-v2';

const addQuantityVisible = ref(false)
const store = useGlobalStore()
const { t } = useI18n()
const releaseVisible = ref(false)
const route = useRoute()
const router = useRouter()
const loading = ref(true)
const gamePlayVisible = ref(false)
const goOnlineVisible = ref(false)
const addNodeList = ref([])
const currentResource = ref({ id: 0, nodeId: '', originQuantity: 0, quantity: 0, maxQuantityNum: 1, testResourceDetailId: '' })
const addQuantityNum = ref(1)
const nodeOptions = ref<any[]>([])
const errorStr = ref(false)
const releaseId = ref('')
const releaseLoading = ref(false)
const isMinScale = ref(false) // 缩容时候 不判断限额
const addQuantityLoading = ref(false)
const disabledScale = computed(() => !isMinScale.value && (addQuantityNum.value > currentResource.value.maxQuantityNum))

const getStatusText = (status: number) => {
    const statusMapping: any = {
        0: t("v2.status.0"),
        1: t("v2.status.1"),
        2: t("v2.status.2"),
        3: t("v2.status.3"),
        5: t("v2.status.5"),
        6: t("v2.status.6"),
        7: t("v2.status.7"),
    }
    return statusMapping[status] || "--"
}
const changeFn = (e: any) => {
    console.log(e)
    console.log(addNodeList.value)
}
const paginationParams = ref({
    current: 1,
    size: 10,
    total: 0
})
const openProhibitCount = (row: any) => {
    window.open(row.trialUrl)
}
const toViewFn = (row: { onlineResourceDetailId: any; applicationId: any; packageId: any; nodeId: any; nodeName: any; }) => {
    router.push({ name: 'powerTime', params: {
        resourceDetailId: row.onlineResourceDetailId || '', 
        applicationId: row.applicationId,
        gameId: row.packageId,
        nodeId: row.nodeId,
        nodeName: row.nodeName
    } })
}
const goOnlineFn2 = (e: { packageId: any; applicationId: any; onlineResourceDetailId: any; }) => {
    router.push({ name: 'goOnline', params: {
        gameId: e.packageId,
        projectId: e.applicationId,
        resourceDetailId:e.onlineResourceDetailId,
    } })
}
const goOnlineVisibleFn = async () => {
    const nodeList2: any = await API.getResourceNodeConfig(route.params.gameId as string)
    const arrList: any[] = []; let obj = {}
    // eslint-disable-next-line array-callback-return
    nodeList2.map((item: { nodeName: any; nodeId: any; }) => {
        obj = {
            label:item.nodeName,
            value:item.nodeId
        }
        arrList.push(obj)
    })
    if(arrList.length > 0){
        nodeOptions.value = arrList
        goOnlineVisible.value = true
    }else{
        router.push({ name: 'goOnline', params: {
            gameId: gameInfo.value.packageId,
            projectId: gameInfo.value.applicationId,
            resourceDetailId: 0
        }})
    }
   
}
const gameInfo = ref<any>({
    applicationId: null,
    applicationName: null,
    packageName: null,
    packageVersion: null,
    packageSize: 0,
    skuName: null,
    specfication: null
})
const goPurchaseFn = () => {
    router.push({ 
        name: 'mainProcessStep03', 
        params: { projectId: gameInfo.value.applicationId, packageId: route.params.gameId }, 
        query: { fromGame: 1 }
    })
}

const gameInfoList = reactive<any>({
    resourceList: []
})

const getGameDetailListFn = () => {
    loading.value = true
    API.getGameDetailList({packageId:route.params.gameId , userId: getUserUid(), environmentId: store.envId, ...paginationParams.value }).then((data: any) => {
        gameInfoList.resourceList = data.records
        loading.value = false
        console.log('getGameDetailListFn--',data)
    }).catch((e: any) => {
        loading.value = false
        console.error('getGameDetailListFn--',e)
    })
}
const releaseHandleOk = () => {
    releaseLoading.value = true
    API.releaseResource(releaseId.value as string).then(res => {
        releaseLoading.value = false
        releaseVisible.value = false
        Toast.success(t('v2.common.actionSuccess'))
        getGameDetailListFn()
    }).catch(() => {
        releaseVisible.value = false
        releaseLoading.value = false
    })
}
const addQuantityOpen = (record: any) => {
    addQuantityNum.value = record.testQuantity
    addQuantityVisible.value = true
    API.getNodeQuota({ nodeId: record.nodeId }).then((data: any) => {
        record.maxQuantityNum = Math.floor(data.quantity + record.testQuantity)
        record.originQuantity = record.testQuantity
        record.quantity = record.testQuantity
        currentResource.value = record
    }).catch((e: any) => {
        console.error('addQuantityOpen--',e)
    })
}
const addQuantityHandle = () => {
    const { nodeId, quantity, testResourceDetailId } = currentResource.value

    if (quantity === addQuantityNum.value) {
        addQuantityVisible.value = false
        return
    }

    let operationType = '';
    // 大于设置值是扩容，小于是缩容
    operationType = addQuantityNum.value > quantity ? 'K' : 'S'

    const params = {
        id: testResourceDetailId,
        nodeId,
        operationType, // K,S
        extensionAmount: addQuantityNum.value
    }
    addQuantityLoading.value = true
    API.setResourceQuantity(params).then((data: any) => {
        addQuantityLoading.value = false
        addQuantityVisible.value = false
        message.success("Resource modification submitted successfully")
        getGameDetailListFn()
    }).catch(e => {
        addQuantityLoading.value = false
        addQuantityVisible.value = false
    })
}

const onRelease = (id: string) => {
    console.log(id)
    releaseVisible.value = true
    releaseId.value = id
}
getGameDetailListFn()
const changeParams = (params: any) => {
    paginationParams.value.current = params
    getGameDetailListFn()
}
const sizeChange = (size: any) => {
    paginationParams.value.current = 1
    paginationParams.value.size = size
    getGameDetailListFn()
}
const playTip = ref('')
const canPlay = computed(() => {
    // 资源状态为运行中/变更中，允许支持试玩；
    const list = gameInfoList.value.resourceList || []
    const canPlayItem = list.findIndex((item: any) => [3,4].includes(item.status))
    console.log('canPlayItem:', canPlayItem)
    if (canPlayItem !== -1) {
        return true
    }
    // 资源状态为已删除/无资源记录，点play提示请购买资源；
    const canBuy = list.every((item: any) => item.status === 1) || !list.length 
    console.log('canBuy:', canBuy)
    if (canBuy) {
        playTip.value = t('v2.resourceList.buyTip')
        return false
    }
    // 2、资源状态为开机中/部署中，点击play提示暂不支持试玩；
    const deployStatus = list.findIndex((item: any) => [0,2].includes(item.status))
    console.log('deployStatus:', deployStatus)
    if (deployStatus) {
        return false
    }
    playTip.value = t('v2.resourceList.notSupportTip')
    return false
})
const goBack = () => {
    router.go(-1)
}
const columns = reactive<any[]>([
    { title: t('console.appInfo.resourceList.nodeName'), dataIndex: 'nodeName', key: 'nodeName', ellipsis: true },
    { title: t('console.appInfo.resourceList.resourceQuantity'), dataIndex: 'reservedQuantity', key: 'reservedQuantity'},
    { title: t('console.appInfo.resourceList.onDemandQuantity'), dataIndex: 'onDemandQuantity', key: 'skuName' },
    { title: t('adapterManage.table.theader.runTime'), dataIndex: 'startTime', key: 'startTime' },
    { title: t('common.action'), dataIndex: 'action', width: 290, key: 'action',fixed: 'right' },
])

const deleteVisible = ref(false)
const deleteLoading = ref(false)
const currentListItem = ref<any>(null)

const deleteHandleOk = () => {
    deleteLoading.value = true
    API.deleteGame(route.params.gameId as string).then(() => {
        deleteLoading.value = false
        deleteVisible.value = false
        Toast.success(t('v2.action.deleteSuccess'))
        window.history.back()
    }).catch(() => {
        deleteVisible.value = false
        deleteLoading.value = false
    })
}

const getGameInfoFn = () => {
    API.getGameInfo(route.params.gameId as string).then((data: any) => {
        gameInfo.value = data
        loading.value = false
    }).catch(() => {
        loading.value = false
    })
}

getGameInfoFn()

const goOnlineFn = (nodeId?:string) => {
    if(addNodeList.value.length === 0) return 
    errorStr.value = true
    const indexOfStr = JSON.stringify(addNodeList.value)
    const regionList: any[] = []
    let objStr = {}
    // eslint-disable-next-line array-callback-return
    nodeOptions.value.map((item) => {
        if(indexOfStr.indexOf(item.value) > -1){
            objStr = {
                nodeId: item.value,
                nodeName: item.label,
                resourceDetailId:''
            }
            regionList.push(objStr)
        }
    })
    console.log(regionList)
    window.localStorage.setItem('REGION_LIST', JSON.stringify(regionList))
    router.push({ name: 'goOnline', params: {
        gameId: gameInfo.value.packageId,
        projectId: gameInfo.value.applicationId,
        resourceDetailId: 0
    } })
}

const goWebPreview = () => {
    const canPlayItem = gameInfoList.value?.resourceList?.find((item: any) => [3,4].includes(item.status))
    const nodeId = currentListItem.value?.nodeId || canPlayItem?.nodeId
    const applicationId = currentListItem.value?.applicationId || ''
    const packageId = currentListItem.value?.packageId || ''
    if (applicationId && packageId && nodeId) { 
        // router.push({ name: 'projectGamePreview', params: { 
        //     projectId: gameInfo.value.applicationId, 
        //     gameId: packageId,
        //     nodeId
        // } })
        const target = router.resolve({
            name: 'projectGamePreview',
            params: { 
                projectId: gameInfo.value.applicationId, 
                gameId: packageId,
                nodeId
            },
        })
        window.open(target.href, '_blank')
    }
}

const qrCodeLink = computed(() => {
    const canPlayItem = gameInfoList.value?.resourceList?.find((item: any) => [3,4].includes(item.status))
    const nodeId = currentListItem.value?.nodeId || canPlayItem?.nodeId
    const applicationId = currentListItem.value?.applicationId || ''
    const packageId = currentListItem.value?.packageId || ''
    if (applicationId && packageId && nodeId) { 
        const host = `${window.location.origin}/#/console/${gameInfo.value.applicationId}/${packageId}/${nodeId}/preview`
        return `${host}?lang=${isForeign ? getLocale() || 'en' : 'zh'}&loginToken=${getToken()}&envId=${store.envId}&isH5=1`
    }
})
const setRunTimeFn = (time: string | number) => {
    const obj = Number(time) < 10 ? '0' + time : time
    return `${obj}:00`
}
// 适配
const cloudRequestClick = () => {
    if(gameInfo.value?.status === 6) {
        // 适配失败, 修改适配表单
        if (gameInfo.value.adapterId) {
            // 修改
            router.push({ name: 'mainProcessStep04', params: { projectId: gameInfo.value.applicationId, packageId: gameInfo.value.packageId }, query: { fromAdapter: 1, adapterId: gameInfo.value.adapterId } })
        } else {
            Toast.error(t('v2.action.cloudRequestClickError'))
        }
    } else {
        // 待适配 - 未提交过适配表单
        router.push({ name: 'mainProcessStep04', params: { projectId: gameInfo.value.applicationId, packageId: gameInfo.value.packageId } })
    }
}
const play = (item:any) => {
    gamePlayVisible.value = true
    currentListItem.value = item
}
</script>

<style lang="sass" scoped>
.cover
    border: 1px solid #E5E6EB
    width: 270px
    height: 164px
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.74) 0%, rgba(255, 255, 255, 0.74) 100%), linear-gradient(285deg, #2053F3 -55.98%, #FEDFFF 105.41%)
    &:hover
        img
            width: 100px
            height: 100px
.info-flex-2
        flex: 2
.model-titlt
    font-family: Roboto
    font-size: 24px
    font-weight: 500
    line-height: 33.6px
    text-align: center
    color: #1D2129
.model-txt
    font-family: Roboto
    font-size: 14px
    font-weight: 400
    line-height: 16.8px
    text-align: center
    color: #86909C
.model-img
    display: block
    margin: 0 auto
    width:265px
    height:221px
.model-btn
    display: block
    margin: 0 auto
    padding-left:20px
    padding-right:20px
    font-size:16px
    height: 42px
    line-height: 28px
.modal-box-div
    width:370px
    margin:0 auto
    .model-txt
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
    :deep(.ant-select)
        width:100%!important
.empty-img
    display: inline-block
</style>