import { i18n } from '@/i18n'
// import packageList from './package/list.vue'
// import packagedDetail from "./package/detail.vue"
// import applicationList from './appInfo/list.vue'
// import Info from "./appInfo/index.vue"
// import resourceList from "./resource/list.vue"
// import Adapter from '@/views/console/adapter/index.vue'
// import adapterList from '@/views/console/adapter/list.vue'
// import commodity from "@/views/console/commodity/index.vue"
// import commodityList from "@/views/console/commodity/list.vue"
// import purchase from "@/views/console/commodity/purchase.vue"

// v2
import console from "./index.vue"
import home from "@/views/console/v2/home/index.vue"
import LandingSDKpage from "@/views/console/v2/landingSDK/index.vue"

import ProjectList from '@/views/console/v2/project/list.vue'
import ProjectGames from '@/views/console/v2/project/games.vue'
import ProjectCreate from '@/views/console/v2/project/create.vue'

import AdapterList from '@/views/console/v2/adapter/list.vue'
import ResourceList from '@/views/console/v2/resource/list.vue'

import GameList from '@/views/console/v2/game/list.vue'
import GameDetail from '@/views/console/v2/game/detailNew.vue'
import GameCreate from '@/views/console/v2/game/create.vue'
import GamePreview from '@/views/console/v2/game/preview/index.vue'

import MainStep01 from '@/views/console/v2/mainProcess/step01.vue'
import MainStep02 from '@/views/console/v2/mainProcess/step02.vue'
import MainStep03 from '@/views/console/v2/mainProcess/step03.vue'
import MainStep04 from '@/views/console/v2/mainProcess/step04.vue'

import BillsList from '@/views/console/v2/bills/list.vue'

import powerTime from '@/views/console/v2/powerTimes/index.vue'
import GoOnline from '@/views/console/v2/goOnline/index.vue'

const routes = [
    {
        name: 'console',
        path: '/console',
        redirect: '/console/home',
        meta: { title: '' },
        component: console,
        children: [
            {
                name: 'home',
                path: 'home',
                meta: { title: i18n.global.t('console.router.home'), showTitle: false, pageClass: 'page-full-content' },
                component: home
            },
            {
                name: 'landingSDKpage',
                path: 'downloadSDK',
                meta: { title: i18n.global.t('console.router.home'), showTitle: false, pageClass: 'page-full-content' },
                component: LandingSDKpage
            },
            {
                name: 'projectList',
                path: 'projects',
                meta: { title: i18n.global.t('console.router.home'), showTitle: false, pageClass: 'page-console-home' },
                component: ProjectList
            },
            {
                name: 'gameList',
                path: 'games',
                meta: { title: i18n.global.t('console.router.gamelist'), showTitle: false },
                component: GameList
            },
            {
                name: 'projectCreate',
                path: 'project/create',
                meta: { title: i18n.global.t('console.router.gamelist'), showTitle: false },
                component: ProjectCreate
            },
            {
                name: 'gameCreate',
                path: 'game/create/:projectId?',
                meta: { title: i18n.global.t('console.router.gamelist'), showTitle: false },
                component: GameCreate
            },
            {
                name: 'projectGameList',
                path: ':projectId/games',
                meta: { title: i18n.global.t('console.router.home'), showTitle: false, pageClass: 'page-console-home' },
                component: ProjectGames
            },
            {
                name: 'projectGameDetail',
                path: ':gameId',
                meta: { title: i18n.global.t('console.router.home'), showTitle: false, pageClass: 'page-console-home' },
                component: GameDetail
            },
            {
                name: 'projectGamePreview',
                path: ':projectId/:gameId/:nodeId/preview',
                meta: { title: i18n.global.t('console.router.home'), showTitle: false, pageClass: 'page-console-home', noAuth: true },
                component: GamePreview
            },
            {
                name: 'projectAdapterList',
                path: 'adapter/list',
                meta: { title: i18n.global.t('console.router.applicationAdapterList'), showTitle: false },
                component: AdapterList
            },
            {
                name: 'projectResourceList',
                path: 'resource/list',
                meta: { title: i18n.global.t('console.router.resourceList'), showTitle: false },
                component: ResourceList
            },
            {
                name: 'mainProcessStep01',
                path: 'create/1',
                meta: { title: i18n.global.t('console.router.home'), showTitle: false, pageClass: 'page-console-home' },
                component: MainStep01
            },
            {
                name: 'mainProcessStep02',
                path: 'create/2/:projectId?',
                meta: { title: i18n.global.t('console.router.home'), showTitle: false, pageClass: 'page-console-home' },
                component: MainStep02
            },
            {
                name: 'mainProcessStep03',
                path: 'create/3/:projectId/:packageId',
                meta: { title: i18n.global.t('console.router.home'), showTitle: false, pageClass: 'page-console-home' },
                component: MainStep03
            },
            {
                name: 'mainProcessStep04',
                path: 'create/4/:projectId/:packageId',
                meta: { title: i18n.global.t('console.router.home'), showTitle: false, pageClass: 'page-console-home' },
                component: MainStep04
            },
            {
                name: 'consoleBills',
                path: 'bills',
                meta: { title: i18n.global.t('console.router.home'), showTitle: false },
                component: () => import('@/views/error/404.vue')
            },
            {
                name: 'powerTime',
                path: 'powertime/:nodeId/:gameId/:resourceDetailId/:applicationId/:nodeName',
                meta: { title: i18n.global.t('console.router.home'), showTitle: false },
                component: powerTime
            },
            {
                name: 'goOnline',
                path: 'online/:gameId/:projectId/:resourceDetailId',
                meta: { title: i18n.global.t('console.router.home'), showTitle: false },
                component: GoOnline
            },
        ]
    },
]

export default routes